<template>
  <v-footer padless>
    <v-responsive class="mx-auto">
      <div style="background-color: #323232" class="py-3">
        <div class="text-center">
          <template>
            <router-link
              v-for="(item, i) in menuItems"
              :key="i"
              :to="item.path"
              class="router-link"
              :target="routerLinkTarget(i)"
            >
              <v-btn
                depressed
                class="x-small white--text px-2"
                style="font-size: 12px"
                text
              >
                {{ item.text }}
              </v-btn>
            </router-link>
            <v-btn
              depressed
              class="x-small white--text px-2"
              style="font-size: 12px"
              text
            >
              <a
                href="https://www.i-nations.tokyo"
                target="_blank"
                class="router-link white--text"
              >
                運営会社
              </a>
            </v-btn>
          </template>
        </div>
        <div class="pt-8 pb-2 white--text text-center" style="font-size: 10px">
          © 2022 i Nations Co.
        </div>
      </div>
    </v-responsive>
  </v-footer>
</template>

<style scoped>
.router-link {
  text-decoration: none;
}
</style>

<script>
import dayjs from 'dayjs';

export default {
  name: 'MainFooter',
  data() {
    return {
      menuItems: [
        { path: '/help', text: 'ヘルプ', icon: 'mdi-book-open' },
        { path: '/terms', text: '利用規約', icon: 'mdi-book-open-outline' },
        {
          path: '/privacy',
          text: 'プライバシーポリシー',
          icon: 'mdi-book-open',
        },
      ],
    };
  },
  computed: {
    currentYear() {
      return dayjs().year();
    },
  },
  methods: {
    visitHomepage() {
      const url = 'https://www.i-nations.tokyo';
      window.open(url, '_blank');
    },

    routerLinkTarget(i) {
      // 別タブ遷移させたい場合は下記で対応
      switch (i) {
        // case 3:
        //   return '_blank';
        default:
          return '';
      }
    },
  },
};
</script>
