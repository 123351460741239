export default {
  data() {
    return {
      municipalities: [
        // 東京23区
        { id: 0, value: '23区/千代田区' },
        { id: 1, value: '23区/中央区' },
        { id: 2, value: '23区/港区' },
        { id: 3, value: '23区/新宿区' },
        { id: 4, value: '23区/文京区' },
        { id: 5, value: '23区/台東区' },
        { id: 6, value: '23区/墨田区' },
        { id: 7, value: '23区/江東区' },
        { id: 8, value: '23区/品川区' },
        { id: 9, value: '23区/目黒区' },
        { id: 10, value: '23区/大田区' },
        { id: 11, value: '23区/世田谷区' },
        { id: 12, value: '23区/渋谷区' },
        { id: 13, value: '23区/中野区' },
        { id: 14, value: '23区/杉並区' },
        { id: 15, value: '23区/豊島区' },
        { id: 16, value: '23区/北区' },
        { id: 17, value: '23区/荒川区' },
        { id: 18, value: '23区/板橋区' },
        { id: 19, value: '23区/練馬区' },
        { id: 20, value: '23区/足立区' },
        { id: 21, value: '23区/葛飾区' },
        { id: 22, value: '23区/江戸川区' },
        // 東京都のその他の市町村
        { id: 23, value: '八王子市' },
        { id: 24, value: '立川市' },
        { id: 25, value: '武蔵野市' },
        { id: 26, value: '三鷹市' },
        { id: 27, value: '青梅市' },
        { id: 28, value: '府中市' },
        { id: 29, value: '昭島市' },
        { id: 30, value: '調布市' },
        { id: 31, value: '横浜市' },
        { id: 34, value: '川崎市' },
        // 埼玉県
        { id: 32, value: 'さいたま市' },
        { id: 35, value: '和光市' },
        // 千葉県
        { id: 33, value: '千葉市' },
        { id: 36, value: '印西市' },
      ],
    };
  },
  methods: {
    getCitiesByArea(area) {
      let result = null;
      switch (area) {
        case 'tokyo':
          result = this.municipalities.filter(element =>
            this.isTokyo(element.id)
          );
          break;
        case 'tokyoCity':
          result = this.municipalities.filter(element =>
            this.isTokyoCity(element.id)
          );
          break;
        case 'kanagawa':
          result = this.municipalities.filter(element =>
            this.isKanagawa(element.id)
          );
          break;
        case 'saitama':
          result = this.municipalities.filter(element =>
            this.isSaitama(element.id)
          );
          break;
        case 'chiba':
          result = this.municipalities.filter(element =>
            this.isChiba(element.id)
          );
          break;
        default:
          break;
      }
      return result;
    },

    isTokyo(id) {
      return id >= 0 && id < 23;
    },

    isTokyoCity(id) {
      return id >= 23 && id <= 30;
    },

    isKanagawa(id) {
      return id === 31 || id === 34;
    },

    isSaitama(id) {
      return id === 32 || id === 35;
    },

    isChiba(id) {
      return id === 33 || id === 36;
    },
  },
};
