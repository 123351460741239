<template>
  <v-dialog
    v-model="skillInfoDialog"
    max-width="1000"
    scrollable
    persistent
    @click:outside="setSkillInfoDialog(false)"
  >
    <v-form v-model="btnDisable">
      <v-card class="pa-2">
        <v-card-title class="pt-2">
          <span
            class="text-h5 font-weight-bold"
            v-text="'スキル・編集'"
            data-cy="entry-step1-dialog-title"
          />
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-5" style="height: 800px">
          <v-row>
            <v-col cols="2" class="pr-0" align="left">
              <h5>【スキルシート】<span style="color: red">※</span></h5>
            </v-col>
            <v-col cols="10" class="py-0 pl-0">
              <template v-if="!skillSheet">
                <h4 class="pl-2 pt-3 font-weight-regular black--text">
                  アップロードされていません
                </h4>
              </template>
              <template v-else>
                <v-row class="ma-0">
                  <v-col cols="10" class="pb-4">
                    <h4>アップロード済のスキルシート</h4>
                    <h3
                      class="black--text font-weight-regular pl-4 pt-2"
                      v-text="skillSheetFileNameInitialValue"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-row class="ma-0">
                      <v-col cols="6" class="pa-0" align="center">
                        <v-btn class="mt-5" icon>
                          <v-icon
                            color="#1976D2"
                            @click="download"
                            v-text="'mdi-download'"
                          />
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="pa-0" align="center">
                        <v-btn class="mt-5" icon>
                          <v-icon
                            color="error"
                            @click="deleteSkillSheet"
                            v-text="'mdi-delete'"
                          />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="2" />
            <v-col cols="8" class="py-0">
              <h4>新規スキルシートアップロード</h4>
              <h4 class="font-weight-thin pl-4">
                テンプレートのダウンロードは
                <span
                  class="pa-0 pr-7 text-decoration-underline primary--text"
                  style="cursor: pointer"
                  v-text="'こちら'"
                  @click="templateDownload"
                />
              </h4>
              <v-file-input
                v-model="fileInput"
                class="pt-0"
                :accept="supportedExtension"
                placeholder="スキルシートをアップロード"
                @change="fileChange"
              />
            </v-col>
            <v-col cols="2" class="pt-16 pb-0" align="center">
              <v-btn
                color="primary"
                class="font-weight-bold mt-1"
                small
                outlined
                elevation="0"
                @click="upload"
                :loading="loadingByUpload"
                :disabled="loadingByUpload"
              >
                アップロード
                <template v-slot:loader>
                  <v-progress-circular
                    :rotate="-90"
                    color="primary"
                    size="22"
                    width="3"
                    :value="uploadProgress"
                  />
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="auto" class="pb-0">
              <h5>【新規登録】</h5>
            </v-col>
            <v-col cols="auto" class="pb-0 pr-5" align="right">
              <h5>
                候補にないスキルを登録する場合は
                <span
                  class="text-decoration-underline"
                  style="cursor: pointer"
                  @click="setOtherSkillModal(true, 'create')"
                >
                  こちら
                </span>
              </h5>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="12" class="px-5">
              <div class="d-flex">
                <v-autocomplete
                  dense
                  chips
                  outlined
                  multiple
                  clearable
                  hide-details
                  deletable-chips
                  persistent-placeholder
                  id="skill-autocomplete"
                  class="pr-2"
                  item-color="primary"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="searchInputValue"
                  prepend-inner-icon="mdi-database-search"
                  placeholder="例: Ruby"
                  no-data-text="該当するスキルが見つかりません。"
                  :items="selectableSkills"
                  :value="createSkillValue"
                  @change="changeSelectSkill($event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      label
                      outlined
                      @click="data.select"
                      @click:close="removeSelectSkill(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <v-btn
                  width="80"
                  height="38"
                  color="primary"
                  elevation="0"
                  class="font-weight-bold"
                  :loading="loadingByCreateSkill"
                  @click="createSkills"
                >
                  登録
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <h5 v-if="!skillNotExists" class="pb-6">【登録スキル】</h5>
          <v-row v-if="!skillNotExists" class="px-5">
            <v-col cols="12">
              <v-row>
                <v-col
                  v-for="(item, index) in skillTypes"
                  :key="index"
                  cols="12"
                  :class="userSkills[item.type].length !== 0 ? 'py-1' : 'pa-0'"
                >
                  <div v-if="userSkills[item.type].length !== 0">
                    <h5 class="pb-2">{{ item.label }}</h5>
                    <v-tooltip
                      v-for="(skill, index) in userSkills[item.type]"
                      :key="index"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          label
                          close
                          color="primary"
                          class="mr-2 mb-2 font-weight-bold"
                          :outlined="true"
                          @click="setSkillModal(true, skill)"
                          @click:close="
                            updateSkillDetail('delete', {
                              id: skill.id,
                              experience_year: skill.experience_year,
                            })
                          "
                        >
                          <div v-bind="attrs" v-on="on">
                            {{ skillChipLabel(skill) }}
                          </div>
                        </v-chip>
                      </template>
                      <span class="font-weight-bold tooltip-label">
                        {{ skill.name }} の詳細を開く
                      </span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="py-1"
                  v-if="currentUserData.other_skills"
                >
                  <h5 class="pb-2">その他(自由入力)</h5>
                  <v-tooltip
                    top
                    v-for="(skill, index) in otherSkills"
                    :key="index"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        :outlined="true"
                        label
                        close
                        class="mr-2 mb-2 font-weight-bold"
                        color="primary"
                        @click="setOtherSkillModal(true, 'edit', skill)"
                        @click:close="deleteOtherSkill(skill)"
                      >
                        <div v-bind="attrs" v-on="on" v-text="skill" />
                      </v-chip>
                    </template>
                    <span class="font-weight-bold tooltip-label">
                      {{ skill }} の詳細を開く
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="mt-3 px-5 pb-3">
          <v-spacer />
          <v-btn
            color="primary"
            elevation="0"
            height="45"
            width="280"
            outlined
            class="font-weight-bold"
            @click="setSkillInfoDialog(false)"
            v-text="'閉じる'"
          />
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-form>
    <SkillDetailModal
      :dialog="skillModal.show"
      :skill="skillModal.skill"
      :loadingByUpdateSkill="loadingByUpdateSkill"
      :loadingByDeleteSkill="loadingByDeleteSkill"
      @updateSkillDetail="updateSkillDetail"
      @close="setSkillModal(false, null)"
    />
    <OtherSkillFormModal
      :dialog="otherSkillModal.show"
      :skill="otherSkillModal.skill"
      :type="otherSkillModalType"
      @deleteOtherSkill="deleteOtherSkill"
      @updateOtherSkill="updateOtherSkill"
      @close="setOtherSkillModal(false, otherSkillModalType)"
    />
  </v-dialog>
</template>

<script>
import SkillDetailModal from '../../common/SkillDetailModal.vue';
import OtherSkillFormModal from '../../common/OtherSkillFormModal.vue';
import mixinDate from '../../../const/date';
import mixinSkillExperienceYears from '../../../const/user/skillExperienceYears';
import mixinSkillSheetExtension from '../../../const/file/skillSheet';
export default {
  name: 'SkillDialogPc',
  props: [
    'currentUserData',
    'userSkills',
    'skills',
    'skillInfoDialog',
    'skillSheet',
    'loadingByUpload',
    'uploadProgress',
    'loadingByDeleteSkill',
    'loadingByUpdateSkill',
    'loadingByCreateSkill',
  ],
  components: {
    SkillDetailModal,
    OtherSkillFormModal,
  },
  mixins: [mixinDate, mixinSkillExperienceYears, mixinSkillSheetExtension],
  data() {
    return {
      btnDisable: false,
      fileData: null,
      fileInput: null,
      fileBtnDisable: false,
      searchInputValue: '',
      skillTypes: [
        {
          label: '言語',
          type: 'languages',
        },
        {
          label: 'フレームワーク',
          type: 'frameworks',
        },
        {
          label: 'その他',
          type: 'others',
        },
      ],
      otherSkillModalType: 'create',
      createSkillValue: [],
      skillModal: {
        show: false,
        skill: null,
      },
      otherSkillModal: {
        show: false,
        skill: '',
      },
    };
  },
  computed: {
    skillSheetFileNameInitialValue() {
      if (this.skillSheet) {
        return (
          this.skillSheet.filename +
          ` ( ${this.formatDatetimeWithSec(this.skillSheet.created_at)} )`
        );
      } else {
        return null;
      }
    },

    skillNotExists() {
      return (
        this.userSkills?.languages.length === 0 &&
        this.userSkills?.frameworks.length === 0 &&
        this.userSkills?.others.length === 0 &&
        !this.currentUserData?.other_skills
      );
    },

    otherSkills() {
      return this.currentUserData?.other_skills
        ? this.currentUserData.other_skills
        : [];
    },

    selectableSkills() {
      const currentSkillIds = this.currentUserData?.user_skills.map(skill => {
        return skill.required_skill_id;
      });
      const selectableSkillList = this.skills.filter(skill => {
        if (!currentSkillIds?.includes(skill.id)) return true;
      });
      return selectableSkillList;
    },
  },
  methods: {
    setSkillModal(value, skill) {
      this.skillModal.show = value;
      this.skillModal.skill = skill;
    },

    setOtherSkillModal(value, type, skill) {
      this.otherSkillModalType = type;
      this.otherSkillModal.show = value;
      if (skill) {
        this.otherSkillModal.skill = skill;
      } else {
        this.otherSkillModal.skill = '';
      }
    },

    fileChange(selectedFile) {
      // ファイルを一度選択してから再度選択を試みてキャンセルした際にundefinedになるため。
      if (selectedFile !== null && selectedFile !== undefined) {
        this.fileData = {
          type: selectedFile.type,
          fileName: selectedFile.name,
          data: selectedFile,
        };
        this.fileBtnDisable = false;
        return;
      } else {
        this.fileData = null;
        this.fileBtnDisable = true;
        return;
      }
    },

    upload() {
      let formData = new FormData();
      if (this.fileData !== null) {
        formData.append(
          'sheet',
          new Blob([this.fileData.data], { type: this.fileData.type }),
          this.fileData.fileName
        );
      } else {
        formData = null;
      }
      if (formData == null) this.fileBtnDisable = true;
      this.$emit('upload', formData, this.fileBtnDisable);
      this.fileData = null;
      this.fileInput = null;
    },

    experienceYear(value) {
      const val = this.skillExperienceYears.find(target => {
        return target.id == value && value !== 0;
      });
      return val ? val.value : '';
    },

    skillChipLabel(skill) {
      return skill.name + ' ' + this.experienceYear(skill.experience_year);
    },

    createSkills() {
      const createSkillValues = this.createSkillValue.map(skillId => {
        return { required_skill_id: skillId, experience_year: 0 };
      });
      this.$emit('createUserSkills', createSkillValues);
    },

    resetCreateSkillValue() {
      this.createSkillValue = [];
    },

    updateSkillDetail(type, updateSkill) {
      const withoutChangeSkills = this.currentUserData?.user_skills.filter(
        skill => {
          return skill.required_skill_id !== updateSkill.id;
        }
      );
      if (type === 'update') {
        // 更新
        const updateSkillValue = {
          required_skill_id: updateSkill.id,
          experience_year: updateSkill.experience_year,
        };
        this.$emit('updateUserSkills', type, [
          ...withoutChangeSkills,
          updateSkillValue,
        ]);
      } else {
        // 削除
        this.$emit('updateUserSkills', type, withoutChangeSkills);
      }
    },

    changeSelectSkill(event) {
      this.searchInputValue = '';
      this.createSkillValue = event;
    },

    removeSelectSkill(removeSkill) {
      const newValue = this.createSkillValue.filter(skillId => {
        return removeSkill.id !== skillId;
      });
      this.createSkillValue = newValue;
    },

    updateOtherSkill(type, otherSkillValue) {
      this.$emit('updateOtherSkill', type, otherSkillValue);
    },

    deleteOtherSkill(otherSkill) {
      this.$emit('deleteOtherSkill', otherSkill);
    },

    download() {
      this.$emit('download');
    },

    deleteSkillSheet() {
      this.$emit('deleteSkillSheet');
    },

    setSkillInfoDialog(value) {
      this.$emit('setSkillInfoDialog', value);
    },

    updateUser() {
      this.$emit('updateUser', !this.btnDisable);
    },

    templateDownload() {
      this.$emit('templateDownload');
    },
  },
};
</script>
