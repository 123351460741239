export default {
  data() {
    return {
      genders: [
        { id: 0, value: '無回答' },
        { id: 1, value: '男性' },
        { id: 2, value: '女性' },
      ],
    };
  },
};
