<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-container>
        <v-form ref="form" v-model="btnDisable">
          <v-row class="pt-2">
            <v-col cols="12" class="py-0">
              <h5>現在のパスワード</h5>
              <v-text-field
                data-cy="update-password-form-current-password"
                placeholder="現在のパスワード"
                dense
                outlined
                hideDetails="auto"
                class="pt-2"
                :type="currentPasswordTypeText"
                :append-icon="currentPasswordTypeIcon"
                @click:append="currentPasswordType = !currentPasswordType"
                color="primary"
                :value="currentPasswordInitialValue"
                @input="updateCurrentPassword"
                :rules="rules.currentPassword"
              />
            </v-col>
          </v-row>
          <v-row class="pt-2">
            <v-col sm="6" md="6" cols="12" class="py-0">
              <h5>新しいパスワード</h5>
              <v-text-field
                data-cy="update-password-form-new-password"
                placeholder="新しいパスワード"
                dense
                outlined
                hideDetails="auto"
                class="pt-2"
                :type="newPasswordTypeText"
                color="primary"
                :append-icon="newPasswordTypeIcon"
                @click:append="newPasswordType = !newPasswordType"
                :value="passwordInitialValue"
                @input="updatePassword"
                :rules="rules.password"
              />
            </v-col>
            <v-col sm="6" md="6" cols="12" :class="vColClassText">
              <h5>新しいパスワード確認</h5>
              <v-text-field
                data-cy="update-password-form-password-confirm"
                placeholder="新しいパスワード確認"
                dense
                outlined
                hideDetails="auto"
                class="pt-2"
                :type="newPasswordConfirmationTypeText"
                color="primary"
                :append-icon="newPasswordConfirmationTypeIcon"
                @click:append="
                  newPasswordConfirmationType = !newPasswordConfirmationType
                "
                :value="passwordConfirmationInitialValue"
                @input="updatePasswordConfirmation"
                :rules="rules.passwordConfirmation"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="left" class="pt-2">
              <h5
                class="pt-1 pl-1 password-hint font-weight-bold"
                style="font-size: 11px; color: rgba(0, 0, 0, 0.6)"
              >
                ※ 半角英数記号のみで、8文字以上入力してください。
              </h5>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <div class="d-flex mt-3">
      <v-spacer />
      <v-btn
        data-cy="update-password-form-btn"
        width="100"
        class="mt-2 font-weight-bold"
        elevation="0"
        color="primary white--text"
        @click="update"
        :loading="loadingByPassword"
      >
        更新
      </v-btn>
    </div>
  </div>
</template>

<script>
import validate from '../../../plugins/validate';

export default {
  name: 'PasswordMypageSp',
  props: ['value', 'loadingByPassword'],
  data() {
    return {
      btnDisable: false,
      currentPasswordType: false,
      newPasswordType: false,
      newPasswordConfirmationType: false,
      rules: {
        currentPassword: [value => validate.userPassword(value)],
        password: [value => validate.userPassword(value)],
        passwordConfirmation: [value => validate.userPassword(value)],
      },
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Password');
  },
  computed: {
    vColClassText() {
      return this.$vuetify.breakpoint.xsOnly ? 'pb-0' : 'py-0';
    },

    currentPasswordTypeText() {
      return this.currentPasswordType ? 'text' : 'password';
    },

    newPasswordTypeText() {
      return this.newPasswordType ? 'text' : 'password';
    },

    newPasswordConfirmationTypeText() {
      return this.newPasswordConfirmationType ? 'text' : 'password';
    },

    currentPasswordTypeIcon() {
      return this.currentPasswordType ? 'mdi-eye' : 'mdi-eye-off';
    },

    newPasswordTypeIcon() {
      return this.newPasswordType ? 'mdi-eye' : 'mdi-eye-off';
    },

    newPasswordConfirmationTypeIcon() {
      return this.newPasswordConfirmationType ? 'mdi-eye' : 'mdi-eye-off';
    },

    currentPasswordInitialValue() {
      return this.value?.current_password == null
        ? null
        : this.value.current_password;
    },

    passwordInitialValue() {
      return this.value?.password == null ? null : this.value.password;
    },

    passwordConfirmationInitialValue() {
      return this.value?.password_confirmation == null
        ? null
        : this.value.password_confirmation;
    },
  },
  methods: {
    resetValidate() {
      this.$refs.form.resetValidation();
    },

    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateCurrentPassword(e) {
      const newValue = {
        ...this.value,
        current_password: e,
      };
      this.$emit('input', newValue);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
