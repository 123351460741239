<template>
  <v-dialog :value="dialog" width="400" persistent @click:outside="close">
    <v-card>
      <v-card-title data-cy="confirm-modal-title" class="font-weight-bold">
        {{ title }}
      </v-card-title>
      <v-card-text data-cy="confirm-modal-text">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          data-cy="confirm-modal-close"
          class="font-weight-bold"
          elevation="0"
          text
          :color="noColor"
          :disabled="loading"
          @click="close"
        >
          キャンセル
        </v-btn>
        <v-btn
          data-cy="confirm-modal-commit"
          class="font-weight-bold"
          elevation="0"
          text
          :color="yesColor"
          :loading="loading"
          :disabled="loading"
          @click="commit"
        >
          {{ yesBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    type: String,
    dialog: Boolean,
    title: String,
    text: String,
    yesColor: String,
    noColor: String,
    yesBtn: String,
  },
  data() {
    return {
      loader: null,
      loading: false,
    };
  },
  watch: {
    loader() {
      let l = this.loader;
      this[l] = !this[l];
    },
  },
  methods: {
    commit() {
      this.beginLoading();
      this.$emit('commit', this.type);
    },

    close() {
      this.$emit('close');
    },

    beginLoading() {
      this.loader = 'loading';
    },

    finishLoading() {
      this.loader = null;
      this.loading = null;
    },
  },
};
</script>
