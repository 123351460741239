<template>
  <v-stepper v-model="stepperPage" elevation="0">
    <v-stepper-items>
      <v-stepper-content step="1" class="pa-0">
        <v-responsive
          max-width="450"
          style="height: 75vh"
          class="mx-auto d-flex justify-center align-center"
        >
          <template v-if="$vuetify.breakpoint.xsOnly">
            <ResetPasswordFormSp
              v-model="user"
              class="px-1"
              :loading="loading"
              @resetPassword="resetPassword"
            />
          </template>
          <template v-else>
            <ResetPasswordFormPc
              v-model="user"
              :loading="loading"
              @resetPassword="resetPassword"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
      <v-stepper-content step="2" class="pa-0">
        <v-responsive
          max-width="650"
          style="height: 75vh"
          class="mx-auto d-flex justify-center align-center"
        >
          <template v-if="!$vuetify.breakpoint.mobile">
            <ResetPasswordCompletePc :user="user" @back="back" />
          </template>
          <template v-else>
            <ResetPasswordCompleteSp :user="user" @back="back" />
          </template>
        </v-responsive>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ResetPasswordFormPc from '../components/pc/ResetPasswordForm';
import ResetPasswordFormSp from '../components/sp/ResetPasswordForm';
import ResetPasswordCompletePc from '../components/pc/ResetPasswordComplete.vue';
import ResetPasswordCompleteSp from '../components/sp/ResetPasswordComplete.vue';

export default {
  name: 'Login',
  components: {
    ResetPasswordFormPc,
    ResetPasswordFormSp,
    ResetPasswordCompletePc,
    ResetPasswordCompleteSp,
  },
  data() {
    return {
      stepperPage: 1,
      user: {
        email: null,
      },
      loading: false,
    };
  },
  methods: {
    async resetPassword(isFormValid) {
      if (isFormValid) {
        this.$emit('logout');
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }

      try {
        this.loading = true;
        const result = await this.$axios.post(
          '/api/v1/users/send_pw_reset_mail',
          this.user,
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (result.status == 200) this.stepperPage = 2;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          this.$emit(
            'showSnackbar',
            'error',
            'パスワード再設定メール送信に失敗しました。'
          );
          this.$emit('logout');
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    back() {
      this.stepperPage = 1;
    },
  },
};
</script>
