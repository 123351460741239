<template>
  <v-card elevation="0" outlined class="pa-2">
    <v-card-title class="pl-2 font-weight-bold">
      <v-row>
        <v-col cols="12" class="pt-1 pb-0" style="height: 36px">
          <span
            class="font-weight-bold"
            style="font-size: 14px"
            v-text="'案件番号: ' + projectNumber(project.id)"
          />
        </v-col>
        <v-col cols="12" class="pt-1">
          <div>
            <v-chip
              dense
              label
              outlined
              color="black"
              class="mr-3 mb-1"
              v-if="userProjectInfo.entered"
              v-text="'応募済み'"
            />
            <v-chip
              dense
              label
              outlined
              :color="vChipColor"
              class="mr-2 mb-1"
              v-if="vChipVisibility"
              v-text="vChipText"
            />
            <span class="pt-1" style="font-size: 19px" v-text="project.name" />
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="px-0">
      <v-row class="mb-2">
        <v-col cols="12" class="py-0 pl-5">
          <div class="d-flex">
            <div class="pt-1">
              <v-icon v-text="'mdi-cash'" />
            </div>
            <div class="pl-2 pt-1">
              <h3 class="accent--text font-weight-bold" v-text="costPrice" />
            </div>
            <div class="pt-1">
              <h4 class="font-weight-bold" v-text="'／月'" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="py-0 pr-2">
          <h5 v-text="paymentSpan" />
        </v-col>
        <v-col cols="12" md="4" sm="4" class="py-0 pl-5">
          <h4 class="pt-1">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-search-outline'" />
            </span>
            {{ municipalityName }}
          </h4>
        </v-col>
        <v-col cols="12" md="4" sm="4" class="py-0 pl-5">
          <h4 class="pt-1">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-marker-radius-outline'" />
            </span>
            {{ project.station }}
          </h4>
        </v-col>
        <v-col cols="12" md="4" sm="4" class="py-0 pl-5">
          <h4 class="pt-1">
            <span>
              <v-icon class="pb-1" v-text="'mdi-account-outline'" />
            </span>
            {{ workLocation }}
          </h4>
        </v-col>
      </v-row>
      <v-divider class="my-1" />
      <v-row class="mt-1">
        <v-col cols="12" class="pl-5 pb-1 pt-2">
          <div class="font-weight-bold" v-text="'【業務内容】'" />
        </v-col>
        <v-col cols="12" class="pl-8 pr-5 pb-1 pt-0">
          <div
            style="white-space: pre-line"
            v-text="project.description"
            class="font-weight-bold"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-col cols="12" class="pl-5 pb-1">
          <div class="font-weight-bold" v-text="'【開発環境】'" />
        </v-col>
        <v-col cols="12" class="pl-8 pr-5 pb-1 pt-0">
          <div class="pt-1">
            <div
              v-if="
                projectSkills.language.length === 0 &&
                projectSkills.frameworks.length === 0 &&
                projectSkills.other.length === 0
              "
              class="font-weight-bold"
              v-text="'スキルが登録されていません。'"
            />
            <v-row v-else class="pb-2">
              <v-col
                cols="12"
                class="pb-0 pt-1"
                v-if="projectSkills.language.length !== 0"
              >
                <h5 class="pb-2">言語</h5>
                <v-chip
                  v-for="(skill, index) in projectSkills.language"
                  :key="index"
                  label
                  small
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pt-1"
                v-if="projectSkills.frameworks.length !== 0"
              >
                <h5 class="pb-2">フレームワーク</h5>
                <v-chip
                  v-for="(skill, index) in projectSkills.frameworks"
                  :key="index"
                  label
                  small
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pt-1"
                v-if="projectSkills.other.length !== 0"
              >
                <h5 class="pb-2">その他</h5>
                <v-chip
                  v-for="(skill, index) in projectSkills.other"
                  :key="index"
                  label
                  small
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-col cols="12" class="pl-5 pb-1">
          <div class="font-weight-bold" v-text="'【求められるスキル】'" />
        </v-col>
        <v-col cols="12" class="pl-8 pr-5 pb-1 pt-0">
          <div
            style="white-space: pre-line"
            v-text="project.require_skill"
            class="font-weight-bold"
          />
        </v-col>
      </v-row>
      <div v-if="project.note !== '' && project.note">
        <v-divider class="mt-5 mb-3" />
        <v-row>
          <v-col cols="12" class="pl-5 pb-1">
            <div class="font-weight-bold" v-text="'【備考】'" />
          </v-col>
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <div
              style="white-space: pre-line"
              v-text="project.note"
              class="font-weight-bold"
            />
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-col cols="12" class="pl-5 pb-1">
          <div class="font-weight-bold" v-text="'【その他募集要項】'" />
        </v-col>
        <template v-if="$vuetify.breakpoint.xsOnly">
          <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
          <!-- <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div class="font-weight-bold" v-text="'作業開始日'" />
              </v-col>
              <v-col cols="8">
                <div
                  style="white-space: pre-line"
                  v-text="startWorkAt"
                  class="font-weight-bold"
                />
              </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div class="font-weight-bold" v-text="'募集人数'" />
              </v-col>
              <v-col cols="8">
                <div
                  style="white-space: pre-line"
                  v-text="`${project.people}人`"
                  class="font-weight-bold"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div class="font-weight-bold" v-text="'面談回数'" />
              </v-col>
              <v-col cols="8">
                <div
                  style="white-space: pre-line"
                  v-text="project.interview_second_time_format ? '2回' : '1回'"
                  class="font-weight-bold"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div class="font-weight-bold" v-text="'面談形式'" />
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <div
                      v-text="interviewFirstTimeFormat"
                      class="font-weight-bold"
                    />
                  </v-col>
                  <v-col cols="12" class="pt-1 pb-0">
                    <div
                      v-text="interviewSecondTimeFormat"
                      class="font-weight-bold"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-row class="pb-4">
            <v-col cos="4">
              <v-row>
                <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
                <!-- <v-col cols="12" class="pl-12 pb-0">
                  <div class="font-weight-bold">作業開始日</div>
                </v-col> -->
                <v-col cols="12" class="pl-12 pb-0 pt-2">
                  <div class="font-weight-bold">募集人数</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row>
                <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
                <!-- <v-col cols="12" class="pb-0">
                  <div class="font-weight-bold" v-text="startWorkAt" />
                </v-col> -->
                <v-col cols="12" class="pb-0 pt-2">
                  <div
                    class="font-weight-bold"
                    v-text="
                      project.interview_second_time_format ? '2回' : '1回'
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cos="1">
              <v-row>
                <v-col cols="12" class="pb-0 px-0" align="center">
                  <div class="font-weight-bold">面談回数</div>
                </v-col>
                <v-col cols="12" class="pb-0 px-0 pt-2" align="center">
                  <div class="font-weight-bold">面談形式</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="12" class="pb-0 pl-0">
                  <div
                    class="font-weight-bold"
                    v-text="
                      project.interview_second_time_format ? '2回' : '1回'
                    "
                  />
                </v-col>
                <v-col cols="12" class="pb-0 pl-0 pt-2">
                  <div>
                    <span
                      class="font-weight-bold"
                      v-text="interviewFirstTimeFormat"
                    />
                    <span class="px-3" />
                    <span
                      class="font-weight-bold"
                      v-text="interviewSecondTimeFormat"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-row>
    </v-card-text>
    <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
    <!-- <v-card-actions class="mt-2 mb-0" v-if="vChipColor != 'black'"> -->
    <v-card-actions class="mt-2 mb-0">
      <v-row>
        <v-col :cols="changeActionBtn" class="d-flex justify-center">
          <v-btn
            outlined
            width="100%"
            height="40"
            style="font-size: 13px"
            elevation="0"
            color="primary"
            @click="changeBookmark"
          >
            <v-icon color="primary" v-text="bookmarkIcon" />
            <div v-text="bookmarkText" class="pl-1 font-weight-bold" />
          </v-btn>
        </v-col>
        <v-col :cols="changeActionBtn" class="d-flex justify-center">
          <v-btn
            elevation="0"
            class="font-weight-bold"
            style="font-size: 13px"
            height="40"
            width="100%"
            color="primary white--text"
            @click="routerPush('Entry')"
            v-text="userProjectInfo.entered ? '再応募する' : '応募する'"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
    <!-- <div class="d-flex justify-center" v-if="vChipColor != 'black'"> -->
    <div class="d-flex justify-center">
      <h6
        class="grey--text pt-3 mb-2"
        v-text="'※ 応募が集中する場合があります。お早めにお申し込みください。'"
      />
    </div>
  </v-card>
</template>

<style scoped>
/* 各スキルのホバー表示を消す */
.skills.v-chip {
  pointer-events: none;
}
</style>

<script>
import mixinMunicipalities from '../../../const/project/municipality';
import mixinWorkLocation from '../../../const/project/workLocation';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import common from '../../../plugins/common';

export default {
  name: 'ProjectDetailSp',
  props: ['isLogin', 'project', 'userProjectInfo', 'projectSkills'],
  mixins: [mixinMunicipalities, mixinWorkLocation],
  computed: {
    projectNumber() {
      return projectId => {
        return common.formatProjectNum(projectId);
      };
    },

    changeActionBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '12';
        case 'sm':
          return '6';
        case 'md':
          return '6';
        default:
          return '12';
      }
    },

    bookmarkIcon() {
      return this.userProjectInfo.bookmarkId
        ? 'mdi-bookmark'
        : 'mdi-bookmark-outline';
    },

    bookmarkText() {
      return this.userProjectInfo.bookmarkId
        ? 'ブックマーク済み'
        : 'ブックマークする';
    },

    costPrice() {
      return this.project.cost != null ? `${this.project.cost}万円` : null;
    },

    workLocation() {
      const result = this.workLocations.find(
        element => element.id === this.project.work_location
      );
      return result != null ? result.value : null;
    },

    interviewFirstTimeFormat() {
      const prefix = this.project.interview_second_time_format ? '1回目：' : '';
      return this.project != null
        ? prefix + this.project.interview_first_time_format
        : '';
    },

    interviewSecondTimeFormat() {
      if (this.project == null) return '';
      return this.project.interview_second_time_format != null
        ? `2回目：${this.project.interview_second_time_format}`
        : '';
    },

    startWorkAt() {
      const setDate = dayjs(this.project.start_work_at);
      if (!setDate.isValid()) return null;

      return setDate.format('YYYY/MM/DD');
    },

    municipalityName() {
      return this.municipalities.find(
        element => element.id === this.project.municipality
      ).value;
    },

    paymentSpan() {
      return this.project != null
        ? `（ 精算幅: ${this.project.work_time_start}-${this.project.work_time_finish}h、 支払いサイト: 30日 ）`
        : null;
    },

    vChipVisibility() {
      const now = dayjs();
      const newProjectFinish = dayjs(this.project.new_project_finish_at);
      if (!newProjectFinish.isValid()) return false;

      dayjs.extend(isSameOrAfter);
      // 新着終了日を過ぎていない場合はtrue
      if (
        newProjectFinish.format('YYYY-MM-DD') == now.format('YYYY-MM-DD') ||
        newProjectFinish.isSameOrAfter(now)
      ) {
        return true;
      } else {
        // 募集終了日が過ぎている場合はtrue、それ以外はfalse
        const recruitmentFinish = dayjs(this.project.recruitment_finish_at);
        if (!recruitmentFinish.isValid()) return false;

        // TODO: QA-480 募集終了を復活させる場合はコメントアウトを復活させる
        // return now.isSameOrAfter(recruitmentFinish);
        return false;
      }
    },

    vChipText() {
      const now = dayjs();
      const recruitmentFinish = dayjs(this.project.recruitment_finish_at);
      if (!recruitmentFinish.isValid()) return false;

      dayjs.extend(isSameOrAfter);
      return now.isSameOrAfter(recruitmentFinish) ? '募集終了' : 'new';
    },

    vChipColor() {
      const now = dayjs();
      const recruitmentFinish = dayjs(this.project.recruitment_finish_at);
      if (!recruitmentFinish.isValid()) return false;

      dayjs.extend(isSameOrAfter);
      return now.isSameOrAfter(recruitmentFinish) ? 'black' : 'accent';
    },
  },
  methods: {
    changeBookmark() {
      if (!this.isLogin()) {
        this.$emit('setModal', true);
        return;
      }
      this.$emit('changeBookmark');
    },

    routerPush(pageName) {
      if (!this.isLogin()) {
        this.$emit('setModal', true);
        return;
      }
      this.$router.push({ name: pageName, params: { id: this.project.id } });
    },
  },
};
</script>
