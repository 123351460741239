<template>
  <div>
    <MarkdownViewer :markDownSource="markDownSource" />
  </div>
</template>

<script>
import MarkdownViewer from '../components/common/MarkdownViewer.vue';
import Privacy from '../assets/md/privacy_policy.md';

export default {
  name: 'Privacy',
  components: {
    MarkdownViewer,
  },
  data() {
    return {
      markDownSource: {
        body: Privacy,
        title: 'プライバシーポリシー',
      },
    };
  },
};
</script>
