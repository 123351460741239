<template>
  <v-card>
    <v-card-title class="text-h5 font-weight-bold">
      <v-row>
        <v-col cols="10"> 勤務地を選択 </v-col>
        <v-col cols="2" align="right">
          <v-icon v-text="'mdi-close'" @click="close" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container class="px-3">
        <v-row class="mb-4">
          <h5 class="pt-1">東京都23区</h5>
          <v-checkbox
            v-model="tokyoAllFlg"
            hide-details
            dense
            class="ma-0 ml-2 ml-auto"
            label="すべて選択する"
            @click="selectAll('tokyo')"
          />
          <v-card
            outlined
            elevation="0"
            height="210"
            class="py-1 px-3 d-flex align-content-start flex-wrap"
          >
            <v-checkbox
              v-for="(item, index) in adjustMunicipalities('tokyo')"
              :key="index"
              dense
              v-model="tokyoValue"
              :value="item.id"
              :label="item.value"
              hide-details
              class="mr-10"
              color="primary"
              style="width: 160px"
            />
          </v-card>
        </v-row>
        <v-row class="mb-4">
          <h5 class="pt-1">東京都のその他の市町村</h5>
          <v-checkbox
            v-model="tokyoCityAllFlg"
            hide-details
            dense
            class="ma-0 ml-2 ml-auto"
            label="すべて選択する"
            @click="selectAll('tokyoCity')"
          />
          <v-card
            outlined
            elevation="0"
            height="110"
            class="py-1 px-3 d-flex align-content-start flex-wrap"
          >
            <v-checkbox
              v-for="(item, index) in adjustMunicipalities('tokyoCity')"
              :key="index"
              dense
              v-model="tokyoCityValue"
              :value="item.id"
              :label="item.value"
              hide-details
              class="mr-15"
              color="primary"
              style="width: 160px"
            />
          </v-card>
        </v-row>
        <v-row class="pt-2">
          <v-col class="pl-0">
            <v-row class="mb-1 pl-3">
              <h5 class="pt-1">神奈川県</h5>
              <v-checkbox
                v-model="kanagawaAllFlg"
                hide-details
                dense
                class="ma-0 ml-2 ml-auto"
                label="すべて選択する"
                @click="selectAll('kanagawa')"
              />
            </v-row>
            <v-card
              outlined
              elevation="0"
              height="80"
              class="py-1 px-3 d-flex align-content-start flex-wrap"
            >
              <v-checkbox
                v-for="(item, index) in adjustMunicipalities('kanagawa')"
                :key="index"
                dense
                v-model="kanagawaValue"
                :value="item.id"
                :label="item.value"
                hide-details
                color="primary"
                style="width: 160px"
              />
            </v-card>
          </v-col>
          <v-col>
            <v-row class="mb-1 pl-3">
              <h5 class="pt-1">埼玉県</h5>
              <v-checkbox
                v-model="saitamaAllFlg"
                hide-details
                dense
                class="ma-0 ml-2 ml-auto"
                label="すべて選択する"
                @click="selectAll('saitama')"
              />
            </v-row>
            <v-card
              outlined
              elevation="0"
              height="80"
              class="py-1 px-3 d-flex align-content-start flex-wrap"
            >
              <v-checkbox
                v-for="(item, index) in adjustMunicipalities('saitama')"
                :key="index"
                dense
                v-model="saitamaValue"
                :value="item.id"
                :label="item.value"
                hide-details
                color="primary"
                style="width: 160px"
              />
            </v-card>
          </v-col>
          <v-col class="pr-0">
            <v-row class="mb-1 pl-3">
              <h5 class="pt-1">千葉県</h5>
              <v-checkbox
                v-model="chibaAllFlg"
                hide-details
                dense
                class="ma-0 ml-2 ml-auto"
                label="すべて選択する"
                @click="selectAll('chiba')"
              />
            </v-row>
            <v-card
              outlined
              elevation="0"
              height="80"
              class="py-1 px-3 d-flex align-content-start flex-wrap"
            >
              <v-checkbox
                v-for="(item, index) in adjustMunicipalities('chiba')"
                :key="index"
                dense
                v-model="chibaValue"
                :value="item.id"
                :label="item.value"
                hide-details
                color="primary"
                style="width: 160px"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-space-between pb-5 px-6">
      <v-btn
        elevation="0"
        outlined
        color="primary"
        @click="reset"
        width="40%"
        class="font-weight-bold"
        v-text="'クリア'"
      />
      <v-btn
        elevation="0"
        color="primary white--text"
        @click="changeCheckBox"
        width="40%"
        class="font-weight-bold"
        v-text="'選択を適用する'"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import mixinMunicipalities from '../../../const/project/municipality';

export default {
  name: 'MunicipalitiesDialog',
  props: ['selectedMunicipalities'],
  mixins: [mixinMunicipalities],
  data() {
    return {
      tokyoValue: [],
      tokyoCityValue: [],
      kanagawaValue: [],
      saitamaValue: [],
      chibaValue: [],

      tokyoAllFlg: false,
      tokyoCityAllFlg: false,
      kanagawaAllFlg: false,
      saitamaAllFlg: false,
      chibaAllFlg: false,
    };
  },
  created() {
    this.setSelectValue();
  },
  watch: {
    // selectedMunicipalities(親のdata)を監視
    selectedMunicipalities() {
      if (this.selectedMunicipalities.length === 0) {
        this.reset();
        return;
      }
      this.selectedMunicipalities.forEach(element => {
        if (this.isTokyo(element)) {
          this.tokyoValue.push(element);
          return;
        }
        if (this.isTokyoCity(element)) {
          this.tokyoCityValue.push(element);
          return;
        }
        if (this.isKanagawa(element)) {
          this.kanagawaValue.push(element);
          return;
        }
        if (this.isSaitama(element)) {
          this.saitamaValue.push(element);
          return;
        }
        if (this.isChiba(element)) {
          this.chibaValue.push(element);
          return;
        }
      });
    },
  },
  methods: {
    setSelectValue() {
      this.selectedMunicipalities.forEach(element => {
        if (this.isTokyo(element)) {
          this.tokyoValue.push(element);
          return;
        }
        if (this.isTokyoCity(element)) {
          this.tokyoCityValue.push(element);
          return;
        }
        if (this.isKanagawa(element)) {
          this.kanagawaValue.push(element);
          return;
        }
        if (this.isSaitama(element)) {
          this.saitamaValue.push(element);
          return;
        }
        if (this.isChiba(element)) {
          this.chibaValue.push(element);
          return;
        }
      });
    },

    adjustMunicipalities(area) {
      return this.getCitiesByArea(area);
    },

    close() {
      this.$emit('close');
    },

    reset() {
      this.tokyoValue = [];
      this.tokyoCityValue = [];
      this.kanagawaValue = [];
      this.saitamaValue = [];
      this.chibaValue = [];
      this.tokyoAllFlg = false;
      this.tokyoCityAllFlg = false;
      this.kanagawaAllFlg = false;
      this.saitamaAllFlg = false;
      this.chibaAllFlg = false;
    },

    selectAll(area) {
      switch (area) {
        case 'tokyo':
          if (this.tokyoAllFlg) {
            for (let i = 0; i < 23; i++) {
              this.tokyoValue.push(i);
            }
          } else {
            this.tokyoValue = [];
          }
          break;
        case 'tokyoCity':
          if (this.tokyoCityAllFlg) {
            for (let i = 23; i < 31; i++) {
              this.tokyoCityValue.push(i);
            }
          } else {
            this.tokyoCityValue = [];
          }
          break;
        case 'kanagawa':
          if (this.kanagawaAllFlg) {
            this.kanagawaValue.push(31);
            this.kanagawaValue.push(34);
          } else {
            this.kanagawaValue = [];
          }
          break;
        case 'saitama':
          if (this.saitamaAllFlg) {
            this.saitamaValue.push(32);
            this.saitamaValue.push(35);
          } else {
            this.saitamaValue = [];
          }
          break;
        case 'chiba':
          if (this.chibaAllFlg) {
            this.chibaValue.push(33);
            this.chibaValue.push(36);
          } else {
            this.chibaValue = [];
          }
          break;
        default:
          break;
      }
    },

    changeCheckBox() {
      const allSelected = [
        ...this.tokyoValue,
        ...this.tokyoCityValue,
        ...this.kanagawaValue,
        ...this.saitamaValue,
        ...this.chibaValue,
      ];
      // 重複削除して、親コンポーネントに渡す
      this.$emit('changeMunicipalities', [...new Set(allSelected)]);
      this.$emit('close');
    },
  },
};
</script>
