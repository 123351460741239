import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import UserMypage from '../views/UserMypage.vue';
import ResetPassword from '../views/ResetPassword.vue';
import UpdatePassword from '../views/UpdatePassword.vue';
import RegisterUser from '../views/RegisterUser.vue';
import ConfirmationEmail from '../views/ConfirmationEmail.vue';
import TermUse from '../views/TermUse.vue';
import Privacy from '../views/Privacy.vue';
import Help from '../views/Help.vue';
import Contact from '../views/Contact.vue';
import AccountMypagePc from '../components/pc/mypage/AccountMypage.vue';
import AccountMypageSp from '../components/sp/mypage/AccountMypage.vue';
import SkillMypagePc from '../components/pc/mypage/SkillMypage.vue';
import SkillMypageSp from '../components/sp/mypage/SkillMypage.vue';
import EmailMypagePc from '../components/pc/mypage/EmailMypage.vue';
import EmailMypageSp from '../components/sp/mypage/EmailMypage.vue';
import PasswordMypagePc from '../components/pc/mypage/PasswordMypage.vue';
import PasswordMypageSp from '../components/sp/mypage/PasswordMypage.vue';
import SkillSheetMypagePc from '../components/pc/mypage/SkillSheetMypage.vue';
import SkillSheetMypageSp from '../components/sp/mypage/SkillSheetMypage.vue';
import BookmarksMypage from '../components/pc/mypage/BookmarksMypage.vue';
import EntryMypage from '../components/pc/mypage/EntryMypage.vue';
import Projects from '../views/Projects.vue';
import Project from '../views/Project.vue';
import Entry from '../views/Entry.vue';
import Withdrawal from '../views/Withdrawal.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'NotFound',
    redirect: '/',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false, title: 'ログイン' },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
      title: 'フリーランスエンジニアのためのIT案件紹介サイト',
      description:
        'qualiteA はフリーランスのための案件紹介サイトです。掲載されている案件をユーザー登録することなくご覧いただくことが可能です。ご希望の条件から気になる案件をお探しください。',
    },
  },
  {
    path: '/mypage',
    component: UserMypage,
    children: [
      {
        path: '',
        redirect: () => 'account',
      },
      {
        path: 'account',
        name: 'AccountMypage',
        components: {
          sp: AccountMypageSp,
          pc: AccountMypagePc,
        },
        meta: { requiresAuth: true, title: '基本情報' },
      },
      {
        path: 'skill',
        name: 'SkillMypage',
        components: {
          sp: SkillMypageSp,
          pc: SkillMypagePc,
        },
        meta: { requiresAuth: true, title: 'スキル' },
      },
      {
        path: 'email',
        name: 'EmailMypage',
        components: {
          sp: EmailMypageSp,
          pc: EmailMypagePc,
        },
        meta: { requiresAuth: true, title: 'メールアドレス変更' },
      },
      {
        path: 'password',
        name: 'PasswordMypage',
        components: {
          sp: PasswordMypageSp,
          pc: PasswordMypagePc,
        },
        meta: { requiresAuth: true, title: 'パスワード変更' },
      },
      {
        path: 'skillsheet',
        name: 'SkillSheetMypage',
        components: {
          sp: SkillSheetMypageSp,
          pc: SkillSheetMypagePc,
        },
        meta: { requiresAuth: true, title: 'スキルシート' },
      },
      {
        path: 'bookmark',
        name: 'BookmarksMypage',
        components: {
          sp: BookmarksMypage,
          pc: BookmarksMypage,
        },
        meta: { requiresAuth: true, title: 'ブックマーク一覧' },
      },
      {
        path: 'entry',
        name: 'EntryMypage',
        components: {
          sp: EntryMypage,
          pc: EntryMypage,
        },
        meta: { requiresAuth: true, title: '応募履歴' },
      },
    ],
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false, title: 'パスワードの再設定' },
  },
  {
    path: '/updatepassword',
    name: 'UpdatePassword',
    component: UpdatePassword,
    meta: { requiresAuth: false, title: 'パスワード更新' },
  },
  {
    path: '/registeruser',
    name: 'RegisterUser',
    component: RegisterUser,
    meta: {
      requiresAuth: false,
      title: '新規登録',
      description: '新規登録はこちら',
    },
  },
  {
    path: '/confirmation-email',
    name: 'ConfirmationEmail',
    component: ConfirmationEmail,
    meta: { requiresAuth: false, title: 'メールアドレス確認' },
  },
  {
    path: '/terms',
    name: 'TermUse',
    component: TermUse,
    meta: { requiresAuth: false, title: '利用規約' },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { requiresAuth: false, title: 'プライバシーポリシー' },
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: { requiresAuth: false, title: 'ヘルプ' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { requiresAuth: false, title: 'お問い合わせ' },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: '案件検索',
      description:
        'フリーランスエンジニアのための案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/projects/javascript',
    name: 'JavaScriptProjects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: 'JavaScriptのフリーランス案件',
      description:
        'フリーランスエンジニアのためのJavaScript案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/projects/java',
    name: 'JavaProjects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: 'Javaのフリーランス案件',
      description:
        'フリーランスエンジニアのためのJava案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/projects/php',
    name: 'PhpProjects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: 'PHPのフリーランス案件',
      description:
        'フリーランスエンジニアのためのPHP案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/projects/python',
    name: 'PythonProjects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: 'Pythonのフリーランス案件',
      description:
        'フリーランスエンジニアのためのPython案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/projects/ruby',
    name: 'RubyProjects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: 'Rubyのフリーランス案件',
      description:
        'フリーランスエンジニアのためのRuby案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/projects/rails',
    name: 'RailsProjects',
    component: Projects,
    meta: {
      requiresAuth: false,
      title: 'Railsのフリーランス案件',
      description:
        'フリーランスエンジニアのためのRails案件情報を掲載しております。ご希望の条件で案件を検索いただけますので、気になる案件をお探しください。',
    },
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    meta: { requiresAuth: false, title: '案件詳細' },
  },
  {
    path: '/project/:id/entry',
    name: 'Entry',
    component: Entry,
    meta: { requiresAuth: true, title: '案件応募' },
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
    meta: { requiresAuth: true, title: '退会' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
