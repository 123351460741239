<template>
  <div>
    <v-row class="pt-1">
      <v-col cols="9" class="py-0">
        <h2>案件検索</h2>
      </v-col>
      <v-col cols="3" class="py-0" align="right">
        <v-select
          outlined
          dense
          hide-details="auto"
          class="select"
          :items="sortSelectItems"
          :value="sortInitialValue"
          item-text="value"
          item-value="id"
          color="primary"
          item-color="primary"
          @change="changeSort"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="pr-0 mb-3 pt-2">
        <h5 class="pt-2">検索条件 :</h5>
      </v-col>
      <v-col cols="8" class="pb-0 pt-2">
        <div v-if="searchWordsArray.length != 0">
          <v-chip
            label
            outlined
            class="mr-2 mb-1 words"
            v-for="(item, index) in searchWordsArray"
            :key="index"
            v-text="item"
          />
        </div>
        <div v-else>
          <h5 class="pt-2">すべて</h5>
        </div>
      </v-col>
      <v-col cols="3" align="right" class="pt-2 pb-0">
        <v-btn
          elevation="0"
          outlined
          color="primary"
          height="30"
          width="160"
          class="font-weight-bold"
          @click="resetSearch"
        >
          検索をクリア
          <v-icon color="primary" v-text="'mdi-close'" small />
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-3">
      <v-col cols="12" class="py-0" align="right">
        <h5
          class="pl-3 mb-2 pt-2"
          style="white-space: pre-line"
          v-text="displayCount"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.select {
  width: 160px;
  font-size: 14px;
}

/* 各スキルのホバー表示を消す */
.words.v-chip {
  pointer-events: none;
}
</style>

<script>
export default {
  name: 'ProjectsHeadersPc',
  props: [
    'displayCount',
    'searchWordsArray',
    'sortSelectItems',
    'sortInitialValue',
  ],
  methods: {
    resetSearch() {
      this.$emit('resetSearch');
    },

    changeSort(e) {
      this.$emit('changeSort', e);
    },
  },
};
</script>
