<template>
  <v-card-text class="pt-6">
    <v-row>
      <v-col cols="8" class="pl-1 pt-1 pb-0">
        <div class="title-text font-weight-bold" v-text="'スキル情報'" />
      </v-col>
      <v-col cols="4" class="pa-0" align="right">
        <v-btn
          depressed
          text
          class="pl-0 pr-2"
          @click="setSkillInfoDialog(true)"
        >
          <span>
            <v-icon small color="primary" v-text="'mdi-pencil'" />
          </span>
          <div class="primary--text" v-text="'編集'" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="px-0 py-1" align="left">
        <div class="font-weight-bold label-text">
          【スキルシート】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="12" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="skillSheetFileNameInitialValue"
          v-if="skillSheet != null && skillSheet.filename != undefined"
        />
        <h4 v-else v-text="'アップロードされていません。'" />
      </v-col>
    </v-row>
    <Loading v-if="loadingByUserSkill" />
    <div v-else>
      <v-row v-if="!skillNotExists">
        <v-col cols="12" class="px-0 py-1" align="left">
          <div class="font-weight-bold pt-3 pb-2 label-text">
            【登録スキル】
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!skillNotExists" class="px-5">
        <v-row class="pb-3">
          <v-col
            v-for="(item, index) in skillTypes"
            :key="index"
            cols="12"
            :class="
              userSkills[item.type].length !== 0 ? 'pt-1 pb-0 px-0' : 'pa-0'
            "
          >
            <div v-if="userSkills[item.type].length !== 0">
              <h5 class="pb-2">{{ item.label }}</h5>
              <v-chip
                v-for="(skill, index) in userSkills[item.type]"
                :key="index"
                label
                small
                color="rgba(0, 0, 0, 0.6)"
                class="mr-2 mb-2 font-weight-bold"
                outlined
              >
                {{ skillChipLabel(skill) }}
              </v-chip>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-0"
            v-if="currentUserData.other_skills"
          >
            <h5 class="pb-2">その他(自由入力)</h5>
            <v-chip
              v-for="(skill, index) in otherSkills"
              :key="index"
              outlined
              label
              small
              class="mr-2 mb-2 font-weight-bold"
              color="rgba(0, 0, 0, 0.6)"
            >
              <div v-text="skill" />
            </v-chip>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-card-text>
</template>
<style scoped lang="scss">
.text {
  font-size: 13px;
}
.label-text {
  font-size: 12px;
}
.title-text {
  font-size: 15px;
  display: flex;
  align-items: center;
}
</style>

<script>
import mixinDate from '../../../const/date';
import mixinSkillExperienceYears from '../../../const/user/skillExperienceYears';
import Loading from '../../common/Loading.vue';
export default {
  name: 'EntryUserSkillsSp',
  props: ['currentUserData', 'skillSheet', 'userSkills', 'loadingByUserSkill'],
  mixins: [mixinDate, mixinSkillExperienceYears],
  components: {
    Loading,
  },
  data() {
    return {
      skillTypes: [
        {
          label: '言語',
          type: 'languages',
        },
        {
          label: 'フレームワーク',
          type: 'frameworks',
        },
        {
          label: 'その他',
          type: 'others',
        },
      ],
    };
  },
  computed: {
    skillSheetFileNameInitialValue() {
      if (this.skillSheet) {
        return (
          this.skillSheet.filename +
          ` ( ${this.formatDatetimeWithSec(this.skillSheet.created_at)} )`
        );
      } else {
        return null;
      }
    },

    skillNotExists() {
      return (
        this.userSkills?.languages.length === 0 &&
        this.userSkills?.frameworks.length === 0 &&
        this.userSkills?.others.length === 0 &&
        !this.currentUserData?.other_skills
      );
    },

    otherSkills() {
      return this.currentUserData.other_skills
        ? this.currentUserData.other_skills
        : [];
    },
  },
  methods: {
    setSkillInfoDialog(value) {
      this.$emit('setSkillInfoDialog', value);
    },

    experienceYear(value) {
      const val = this.skillExperienceYears.find(target => {
        return target.id == value && value !== 0;
      });
      return val ? val.value : '';
    },

    skillChipLabel(skill) {
      return skill.name + ' ' + this.experienceYear(skill.experience_year);
    },
  },
};
</script>
