export default {
  data() {
    return {
      entryPriority: [
        { id: 0, value: 'mdi-arrow-down', color: 'gray', text: '低' },
        { id: 1, value: 'mdi-arrow-right', color: 'primary', text: '中' },
        { id: 2, value: 'mdi-arrow-up', color: 'red', text: '高' },
      ],
    };
  },
};
