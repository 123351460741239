<template>
  <div class="pb-9">
    <v-card outlined elevation="0" class="px-0 pt-0 pb-0">
      <v-data-table
        :headers="headers"
        :items="bookmarks"
        :items-per-page="10"
        :page.sync="page"
        loading-text="Loading..."
        no-data-text="ブックマークが登録されていません。"
        @page-count="pageCount = $event"
        hide-default-footer
        :loading="loadingByBookmark"
      >
        <template v-slot:[`item.project.name`]="{ item }">
          <router-link
            :to="{ name: 'Project', params: { id: item.project.id } }"
            target="_blank"
            v-if="item.project.public_project"
            v-text="item.project.name"
          />
          <div v-else v-text="item.project.name" />
        </template>

        <template v-slot:[`item.project.cost`]="{ item }">
          <div class="pr-4" v-text="projectCost(item.project)" />
        </template>

        <template v-slot:[`item.project.municipality`]="{ item }">
          <div class="pr-4" v-text="projectMunicipality(item.project)" />
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            color="error"
            elevation="1"
            @click="deleteBookmark(item.id)"
            v-text="'mdi-delete'"
          />
        </template>
      </v-data-table>
      <v-divider />
      <div class="text-center py-2" v-if="bookmarks.length > 10">
        <v-pagination v-model="page" :length="pageCount" color="primary" />
      </div>
    </v-card>
  </div>
</template>

<script>
import mixinMunicipalities from '../../../const/project/municipality';

export default {
  name: 'BookmarksMypage',
  props: ['bookmarks', 'loadingByBookmark'],
  mixins: [mixinMunicipalities],
  data() {
    return {
      page: 1,
      pageCount: 0,
      headers: [
        {
          text: '案件名',
          align: 'center',
          value: 'project.name',
          sortable: false,
          width: '45%',
        },
        { text: '単価', align: 'center', value: 'project.cost', width: '20%' },
        {
          text: '勤務地',
          align: 'center',
          value: 'project.municipality',
          width: '20%',
        },
        {
          text: '削除',
          align: 'center',
          value: 'action',
          sortable: false,
          width: '15%',
        },
      ],
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Bookmark');
  },
  computed: {
    projectCost() {
      return project => {
        return project.public_project ? `${project.cost}万円` : '-';
      };
    },

    projectMunicipality() {
      return project => {
        return project.public_project
          ? this.municipalityName(project.municipality).value
          : '-';
      };
    },
  },
  methods: {
    deleteBookmark(bookmarkId) {
      this.$emit('setModal', 'deleteBookmark', bookmarkId);
    },

    municipalityName(municipalityEachNum) {
      return this.municipalities.find(
        element => element.id === municipalityEachNum
      );
    },
  },
};
</script>
