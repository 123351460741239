<template>
  <v-responsive max-width="1024" class="mx-auto">
    <v-container v-if="project" class="mt-3 mb-10">
      <div class="d-flex pb-4">
        <v-icon small class="px-1">mdi-arrow-left</v-icon>
        <router-link :to="backToProjects"> 案件検索に戻る </router-link>
      </div>
      <ProjectDetailsPc
        v-if="$vuetify.breakpoint.mdAndUp"
        :project="project"
        :isLogin="isLogin"
        :userProjectInfo="userProjectInfo"
        :projectSkills="projectSkills"
        @changeBookmark="changeBookmark"
        @setModal="setModal"
      />
      <ProjectDetailsSp
        v-else
        :project="project"
        :isLogin="isLogin"
        :userProjectInfo="userProjectInfo"
        :projectSkills="projectSkills"
        @changeBookmark="changeBookmark"
        @setModal="setModal"
      />
      <EncourageLogin :dialog="modal.show" @close="modal.show = !modal.show" />
    </v-container>
  </v-responsive>
</template>

<script>
import session from '../plugins/session';
import EncourageLogin from '../components/common/EncourageLoginModal.vue';
import ProjectDetailsSp from '../components/sp/project/ProjectDetails.vue';
import ProjectDetailsPc from '../components/pc/project/ProjectDetails.vue';

export default {
  name: 'Project',
  props: ['isLogin'],
  components: {
    EncourageLogin,
    ProjectDetailsSp,
    ProjectDetailsPc,
  },
  data() {
    return {
      project: null,
      userProjectInfo: {
        bookmarkId: null,
        entered: false,
      },
      skills: [],
      modal: {
        show: false,
      },
      beforePageQuery: null,
      projectSkills: {
        language: [],
        frameworks: [],
        other: [],
      },
    };
  },
  created() {
    this.getProject().then(() => {
      this.getUserProjectInfo();
      this.getSkills().then(() => {
        this.setSkills();
      });
    });
  },

  beforeRouteEnter(to, from, next) {
    if (from.name === 'Projects' && Object.keys(from.query).length !== 0) {
      next(vm => {
        vm.initIfHasQuery(from.query);
      });
    } else {
      next();
    }
  },

  computed: {
    backToProjects() {
      if (this.beforePageQuery) {
        return { name: 'Projects', query: this.beforePageQuery };
      } else {
        return { name: 'Projects' };
      }
    },
  },
  methods: {
    initIfHasQuery(query) {
      this.beforePageQuery = query;
    },

    setSkills() {
      this.projectSkills = {
        language: [],
        frameworks: [],
        other: [],
      };
      this.project.project_skills.forEach(skill => {
        const targetValue = this.skills.find(targetSkill => {
          return skill.required_skill_id === targetSkill.id;
        });
        if (targetValue) {
          switch (targetValue.category) {
            case 0:
              this.projectSkills.language.push(targetValue);
              break;
            case 1:
              this.projectSkills.frameworks.push(targetValue);
              break;
            case 2:
              this.projectSkills.other.push(targetValue);
              break;
            default:
              break;
          }
        }
      });
    },

    async getProject() {
      try {
        const result = await this.$axios.get(
          '/api/v1/projects/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        this.project = result.data;
      } catch (err) {
        switch (err.response.status) {
          case 401:
            this.$emit('logout');
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定した案件が見つかりません。'
            );
            this.$router.push({ name: 'Projects' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            this.$router.push({ name: 'Projects' });
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            this.$router.push({ name: 'Projects' });
            break;
        }
      }
    },

    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/required_skills', {
          headers: { 'Content-Type': 'application/json' },
        });
        if (result.status == 200) this.skills = result.data;
      } catch (err) {
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async getUserProjectInfo() {
      if (!this.isLogin()) return;
      try {
        const result = await this.$axios.get(
          '/api/v1/users/project_info/' + this.project.id,
          {
            headers: session.apiAuthHeaders(),
          }
        );
        this.userProjectInfo = result.data;
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    changeBookmark() {
      if (!this.isLogin()) {
        this.modal.show = true;
        return;
      }
      if (this.userProjectInfo.bookmarkId !== null) {
        this.deleteBookmark();
      } else {
        this.createBookmark();
      }
    },

    async deleteBookmark() {
      try {
        await this.$axios.delete(
          '/api/v1/bookmarks/' + this.userProjectInfo.bookmarkId,
          {
            headers: session.apiAuthHeaders(),
          }
        );
        this.userProjectInfo.bookmarkId = null;
        this.$emit('showSnackbar', 'success', 'ブックマークが削除されました。');
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit(
            'showSnackbar',
            'error',
            '指定したブックマークは既に削除されています。'
          );
          this.getUserProjectInfo(); // 最新の状態にするために取得し直し
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async createBookmark() {
      try {
        await this.$axios.post(
          '/api/v1/bookmarks/',
          { bookmark: { project_id: this.$route.params.id } },
          { headers: session.apiAuthHeaders() }
        );
        this.getUserProjectInfo(); // 最新の状態にするために取得し直し
        this.$emit('showSnackbar', 'success', '登録に成功しました。');
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          // 下記の処理でブックマークしている際は最新の状態にするためにブックマークを取得し直す
          if (error_message === '既にブックマークをしています。')
            this.getUserProjectInfo();
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    setModal(type) {
      this.modal.show = type;
    },
  },
};
</script>
