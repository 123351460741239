export default {
  data() {
    return {
      skillExperienceYears: [
        { id: 0, value: '未選択' },
        { id: 1, value: '1年' },
        { id: 2, value: '2年' },
        { id: 3, value: '3年' },
        { id: 4, value: '4年' },
        { id: 5, value: '5年以上' },
      ],
    };
  },
};
