<template>
  <v-card class="pr-13 pl-0" flat>
    <v-card-title class="py-5 justify-center">
      <h3 class="text-center">
        退会が完了しました
        <v-icon
          color="primary"
          class="pb-1"
          v-text="'mdi-check-circle-outline'"
        />
      </h3>
    </v-card-title>
    <v-card-text>
      <v-row justify="center" class="mt-2">
        <div class="text-center font-weight-bold">
          退会処理に数営業日いただいております。<br />
          退会のタイミングによっては、<br />
          数日はメール等のご連絡が届くことがありますので、予めご了承ください。<br />
          ご利用いただき、誠にありがとうございました。
        </div>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center pt-8">
      <v-btn
        style="text-transform: none"
        outlined
        color="primary"
        class="font-weight-bold"
        height="40"
        width="70%"
        max-width="400px"
        @click="finish"
        v-text="'qualitéA TOPページへ'"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'WithdrawalCompletePc',
  methods: {
    finish() {
      this.$emit('finish');
    },
  },
};
</script>
