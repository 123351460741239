<template>
  <div class="px-2">
    <v-card
      outlined
      elevation="0"
      max-width="550"
      class="px-5 pt-5 mx-auto mt-2 mb-10"
    >
      <div class="d-flex justify-center mx-10">
        <h2>お問い合せフォーム</h2>
      </div>
      <v-container>
        <v-form v-model="btnDisable">
          <template v-if="skipInfo">
            <v-row>
              <v-col class="py-0" cols="6">
                <h5>氏名(姓)</h5>
                <v-text-field
                  dense
                  color="primary"
                  outlined
                  hideDetails="auto"
                  @input="updateLastName"
                  :rules="rules.lastName"
                  data-cy="contact-form-last-name-form"
                />
              </v-col>
              <v-col class="py-0" cols="6">
                <h5>氏名(名)</h5>
                <v-text-field
                  dense
                  color="primary"
                  outlined
                  hideDetails="auto"
                  @input="updateFirstName"
                  :rules="rules.firstName"
                  data-cy="contact-form-first-name-form"
                />
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col class="py-0" cols="12">
                <h5>E-Mail</h5>
                <v-text-field
                  dense
                  color="primary"
                  outlined
                  hideDetails="auto"
                  @input="updateEmail"
                  :rules="rules.email"
                  data-cy="contact-form-email-form"
                />
              </v-col>
            </v-row>
          </template>
          <v-row class="pt-3">
            <v-col class="py-0" cols="12">
              <h5>件名</h5>
              <v-text-field
                dense
                color="primary"
                outlined
                hideDetails="auto"
                @input="updateOtherTitle"
                :rules="rules.otherTitle"
                data-cy="contact-form-other-title-form"
                :counter="50"
              />
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col class="py-0" cols="12">
              <h5>内容</h5>
              <v-textarea
                outlined
                dense
                color="primary"
                hideDetails="auto"
                class="mt-2"
                rows="10"
                @input="updateContent"
                :rules="rules.content"
                data-cy="contact-form-content-form"
                :counter="500"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row class="pt-4 px-1">
          <v-col cols="12">
            <div
              class="font-weight-bold text-center"
              style="font-size: 9px; color: rgba(0, 0, 0, 0.6)"
            >
              本送信をもって個人情報の取り扱いについて同意されたとみなします。<br />
              個人情報については、
              <span>
                <a
                  href="/privacy"
                  target="_blank"
                  v-text="'プライバシーポリシー'"
                />
              </span>
              に従って取り扱いさせていただきます。
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="pb-6 justify-center">
        <div style="width: 90%">
          <v-btn
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            block
            height="35px"
            @click="create"
            data-cy="contact-form-submit-btn"
            :loading="loading"
          >
            <v-icon
              class="mail-icon-position"
              dark
              size="25"
              v-text="'mdi-email-outline'"
            />
            送信する
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'ContactFormSp',
  props: ['value', 'isLogin', 'loading'],
  data() {
    return {
      skipInfo: false,
      btnDisable: false,
      rules: {
        firstName: [value => validate.userName(value, '名')],
        lastName: [value => validate.userName(value, '姓')],
        email: [value => validate.userEmail(value)],
        content: [value => validate.inquiryContent(value)],
        otherTitle: [value => validate.inquiryOtherTitle(value)],
      },
    };
  },
  created() {
    this.skipInfo = !this.isLogin();
  },
  methods: {
    change() {
      this.changeCheckBox = !this.changeCheckBox;
    },

    create() {
      this.$emit('showModal', !this.btnDisable, !this.skipInfo);
    },

    updateFirstName(event) {
      const newValue = {
        ...this.value,
        firstName: event,
      };
      this.$emit('input', newValue);
    },

    updateLastName(event) {
      const newValue = {
        ...this.value,
        lastName: event,
      };
      this.$emit('input', newValue);
    },

    updateEmail(event) {
      const newValue = {
        ...this.value,
        email: event,
      };
      this.$emit('input', newValue);
    },

    updateContent(event) {
      const newValue = {
        ...this.value,
        content: event,
      };
      this.$emit('input', newValue);
    },

    updateOtherTitle(event) {
      const newValue = {
        ...this.value,
        other_title: event,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
