<template>
  <v-card
    outlined
    link
    hover
    :to="{ name: 'Project', params: { id: project.id } }"
    width="240"
    class="rounded-lg"
    height="auto"
    data-cy="home-project-overview-card"
  >
    <v-container class="pa-6" style="box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px">
      <div class="pb-3">
        <h3
          class=""
          v-text="project.name"
          style="
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          "
        />
      </div>

      <v-divider color="#0277BD" />

      <div class="mt-5">
        <div class="mb-4">
          <h5>
            <v-icon small class="pb-1 pr-1" v-text="'mdi-cash'" />
            単価
          </h5>
          <div
            class="pl-1 text-subtitle-1 font-weight-bold accent--text"
            v-text="getCosts"
          />
        </div>
        <div class="mb-4">
          <h5>
            <v-icon
              small
              class="pb-1 pr-1"
              v-text="'mdi-map-marker-radius-outline'"
            />
            エリア
          </h5>
          <div
            class="pt-2 pl-1 text-body-2 font-weight-bold"
            v-text="project.station"
          />
        </div>
        <div class="mb-4">
          <h5>
            <v-icon small class="pb-1 pr-1" v-text="'mdi-account-outline'" />
            勤務形態
          </h5>
          <div
            class="pt-2 pl-1 text-body-2 font-weight-bold"
            v-text="workLocation"
          />
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import mixinWorkLocation from '../../../const/project/workLocation';

export default {
  name: 'ProjectOverviewSp',
  props: ['project'],
  mixins: [mixinWorkLocation],
  computed: {
    getCosts() {
      return this.project?.cost ? `${this.project.cost}万円` : null;
    },

    workLocation() {
      return this.workLocations.find(
        element => element.id === this.project.work_location
      ).value;
    },
  },
};
</script>
