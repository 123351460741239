const session = {};

session.returnItemKeys = function () {
  return {
    'access-token': localStorage.getItem('token'),
    client: localStorage.getItem('client'),
    uid: localStorage.getItem('uid'),
  };
};

session.removeItemKeys = function () {
  localStorage.removeItem('token');
  localStorage.removeItem('client');
  localStorage.removeItem('uid');
};

session.setItemKeys = function (headers) {
  if ('localStorage' in window && window.localStorage !== null) {
    localStorage.setItem('uid', headers['uid']);
    localStorage.setItem('token', headers['access-token']);
    localStorage.setItem('client', headers['client']);
  }
};

session.setGoogleItemKeys = function (headers) {
  if ('localStorage' in window && window.localStorage !== null) {
    localStorage.setItem('uid', headers['uid']);
    localStorage.setItem('token', headers['auth_token']);
    localStorage.setItem('client', headers['client_id']);
  }
};

session.apiAuthHeaders = function () {
  const type = { 'Content-Type': 'application/json' };
  const auth_info = session.returnItemKeys();
  return Object.assign(type, auth_info);
};

session.fileApiAuthHeaders = function () {
  const type = { 'Content-Type': 'multipart/form-data' };
  const auth_info = session.returnItemKeys();
  return Object.assign(type, auth_info);
};

session.isAuthenticated = function () {
  const keys = this.returnItemKeys();
  return !(!keys['access-token'] && !keys['client'] && !keys['uid']);
};

session.setUidToItemKeys = function (uid) {
  localStorage.setItem('uid', uid);
};

export default session;
