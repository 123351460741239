import Vue from 'vue';
import VueGtm from 'vue-gtm';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API_URL;

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');

if (process.env.VUE_APP_GA_ID) {
  Vue.use(
    VueGtm,
    {
      id: process.env.VUE_APP_GA_ID,
    },
    router
  );
}
