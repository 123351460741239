<template>
  <v-card elevation="0" outlined class="px-10 pb-10 pt-5">
    <v-card-title class="font-weight-bold pt-1">
      <v-row>
        <v-col cols="12" class="pt-1 pb-0" style="height: 36px">
          <span
            class="font-weight-bold"
            style="font-size: 14px"
            v-text="'案件番号: ' + projectNumber(project.id)"
          />
        </v-col>
        <v-col cols="12">
          <div>
            <v-chip
              dense
              label
              outlined
              color="black"
              class="mr-3 mb-1"
              v-if="userProjectInfo.entered"
              v-text="'応募済み'"
            />
            <v-chip
              dense
              label
              outlined
              :color="vChipColor"
              class="mr-3 mb-1"
              v-if="vChipVisibility"
              v-text="vChipText"
            />
            <span class="pt-1" style="font-size: 24px" v-text="project.name" />
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-row>
        <v-col cols="12" class="pb-0 pt-2">
          <div class="d-flex">
            <div class="pt-1">
              <v-icon v-text="'mdi-cash'" />
            </div>
            <div class="pl-2 pt-1">
              <h2 class="accent--text" v-text="costPrice" />
            </div>
            <div class="pt-1">
              <h3 v-text="'／月'" />
            </div>
            <div class="pt-1 pl-1">
              <h3 v-text="paymentSpan" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-2">
        <v-col cols="4" class="pb-0 pt-2">
          <h3 class="pt-1">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-search-outline'" />
            </span>
            {{ municipalityName }}
          </h3>
        </v-col>
        <v-col cols="4" class="pb-0 pt-2">
          <h3 class="pt-1">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-marker-radius-outline'" />
            </span>
            {{ project.station }}
          </h3>
        </v-col>
        <v-col cols="4" class="pb-0 pt-2">
          <h3 class="pt-1">
            <span>
              <v-icon class="pb-1" v-text="'mdi-account-outline'" />
            </span>
            {{ workLocation }}
          </h3>
        </v-col>
      </v-row>
      <v-divider class="mt-9 mb-1" />
      <v-row>
        <v-col cols="3" class="mt-3 pb-2">
          <h2 v-text="'業務内容'" />
        </v-col>
        <v-col cols="9" class="mt-3 pb-2">
          <div style="white-space: pre-line" v-text="project.description" />
        </v-col>
      </v-row>
      <v-divider class="mt-6 mb-5" />
      <v-row>
        <v-col cols="3" class="pb-1">
          <h2 v-text="'開発環境'" />
        </v-col>
        <v-col cols="9">
          <div
            v-if="
              projectSkills.language.length === 0 &&
              projectSkills.frameworks.length === 0 &&
              projectSkills.other.length === 0
            "
            v-text="'スキルが登録されていません。'"
          />
          <v-row v-else>
            <v-col
              cols="12"
              class="py-1"
              v-if="projectSkills.language.length !== 0"
            >
              <h5 class="pb-2">言語</h5>
              <v-chip
                v-for="(skill, index) in projectSkills.language"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill.name"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-1"
              v-if="projectSkills.frameworks.length !== 0"
            >
              <h5 class="pb-2">フレームワーク</h5>
              <v-chip
                v-for="(skill, index) in projectSkills.frameworks"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill.name"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-1"
              v-if="projectSkills.other.length !== 0"
            >
              <h5 class="pb-2">その他</h5>
              <v-chip
                v-for="(skill, index) in projectSkills.other"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill.name"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-6 mb-5" />
      <v-row>
        <v-col cols="3" class="pb-1">
          <h2 v-text="'求められるスキル'" />
        </v-col>
        <v-col cols="9">
          <div style="white-space: pre-line" v-text="project.require_skill" />
        </v-col>
      </v-row>
      <div v-if="project.note !== '' && project.note">
        <v-divider class="mt-6 mb-5" />
        <v-row>
          <v-col cols="3" class="pb-0">
            <h2 v-text="'備考'" />
          </v-col>
          <v-col cols="9">
            <div style="white-space: pre-line" v-text="project.note" />
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-6 mb-5" />
      <v-row>
        <v-col cols="3" class="pb-0">
          <h2 v-text="'その他募集要項'" />
        </v-col>
        <v-col cols="9">
          <v-row>
            <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
            <!-- <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div class="other-title-text">【作業開始日】</div>
                <div v-text="startWorkAt" />
              </div>
            </v-col> -->
            <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div class="other-title-text">【募集人数 】</div>
                <div v-text="`${project.people}人`" />
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div class="other-title-text">【面談回数】</div>
                <div
                  v-text="project.interview_second_time_format ? '2回' : '1回'"
                />
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div class="other-title-text">【面談形式】</div>
                <div v-text="interviewFirstTimeFormat" />
                <div class="px-3" />
                <div v-text="interviewSecondTimeFormat" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>


    <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
    <!-- <v-card-actions
      class="mt-10 justify-space-between"
      v-if="vChipColor != 'black'"
    > -->
    <v-card-actions
      class="mt-10 justify-space-between"
    >
      <v-btn
        height="50"
        width="40%"
        outlined
        color="primary"
        elevation="0"
        style="font-size: 16px"
        @click="changeBookmark"
      >
        <v-icon color="primary" v-text="bookmarkIcon" />
        <div v-text="bookmarkText" class="pl-2 font-weight-bold" />
      </v-btn>
      <v-btn
        elevation="0"
        class="font-weight-bold"
        height="50"
        width="40%"
        style="font-size: 16px"
        color="primary white--text"
        @click="routerPush('Entry')"
        v-text="userProjectInfo.entered ? '再応募する' : '応募する'"
      />
    </v-card-actions>
    <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
    <!-- <div class="d-flex justify-center" v-if="vChipColor != 'black'"> -->
    <div class="d-flex justify-center">
      <h5
        class="grey--text pt-3"
        v-text="'※ 応募が集中する場合があります。お早めにお申し込みください。'"
      />
    </div>
  </v-card>
</template>

<style scoped>
.other-title-text {
  /* 下記のwidthでその他募集要項のインデントを統一させる */
  width: 110px;
}

/* 各スキルのホバー表示を消す */
.skills.v-chip {
  pointer-events: none;
}
</style>

<script>
import mixinMunicipalities from '../../../const/project/municipality';
import mixinWorkLocation from '../../../const/project/workLocation';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import common from '../../../plugins/common';

export default {
  name: 'ProjectDetailPc',
  props: ['isLogin', 'project', 'userProjectInfo', 'projectSkills'],
  mixins: [mixinMunicipalities, mixinWorkLocation],
  computed: {
    projectNumber() {
      return projectId => {
        return common.formatProjectNum(projectId);
      };
    },

    bookmarkIcon() {
      return this.userProjectInfo.bookmarkId
        ? 'mdi-bookmark'
        : 'mdi-bookmark-outline';
    },

    bookmarkText() {
      return this.userProjectInfo.bookmarkId
        ? 'ブックマーク済み'
        : 'ブックマークする';
    },

    costPrice() {
      return this.project.cost != null ? `${this.project.cost}万円` : null;
    },

    workLocation() {
      const result = this.workLocations.find(
        element => element.id === this.project.work_location
      );
      return result != null ? result.value : null;
    },

    interviewFirstTimeFormat() {
      const prefix = this.project.interview_second_time_format ? '1回目：' : '';
      return this.project != null
        ? prefix + this.project.interview_first_time_format
        : '';
    },

    interviewSecondTimeFormat() {
      if (this.project == null) return '';
      return this.project.interview_second_time_format != null
        ? `2回目：${this.project.interview_second_time_format}`
        : '';
    },

    startWorkAt() {
      const setDate = dayjs(this.project.start_work_at);
      if (!setDate.isValid()) return null;

      return setDate.format('YYYY/MM/DD');
    },

    municipalityName() {
      return this.municipalities.find(
        element => element.id === this.project.municipality
      ).value;
    },

    paymentSpan() {
      return this.project != null
        ? `（ 精算幅: ${this.project.work_time_start}-${this.project.work_time_finish}h、 支払いサイト: 30日 ）`
        : null;
    },

    vChipVisibility() {
      const now = dayjs();
      const newProjectFinish = dayjs(this.project.new_project_finish_at);
      if (!newProjectFinish.isValid()) return false;

      dayjs.extend(isSameOrAfter);
      // 新着終了日を過ぎていない場合はtrue
      if (
        newProjectFinish.format('YYYY-MM-DD') == now.format('YYYY-MM-DD') ||
        newProjectFinish.isSameOrAfter(now)
      ) {
        return true;
      } else {
        // 募集終了日が過ぎている場合はtrue、それ以外はfalse
        const recruitmentFinish = dayjs(this.project.recruitment_finish_at);
        if (!recruitmentFinish.isValid()) return false;

        // TODO: QA-480 募集終了を復活させる場合はコメントアウトを復活させる
        // return now.isSameOrAfter(recruitmentFinish);
        return false;
      }
    },

    vChipText() {
      const now = dayjs();
      const recruitmentFinish = dayjs(this.project.recruitment_finish_at);
      if (!recruitmentFinish.isValid()) return false;

      dayjs.extend(isSameOrAfter);
      return now.isSameOrAfter(recruitmentFinish) ? '募集終了' : 'new';
    },

    vChipColor() {
      const now = dayjs();
      const recruitmentFinish = dayjs(this.project.recruitment_finish_at);
      if (!recruitmentFinish.isValid()) return false;

      dayjs.extend(isSameOrAfter);
      return now.isSameOrAfter(recruitmentFinish) ? 'black' : 'accent';
    },
  },
  methods: {
    changeBookmark() {
      if (!this.isLogin()) {
        this.$emit('setModal', true);
        return;
      }
      this.$emit('changeBookmark');
    },

    routerPush(pageName) {
      if (!this.isLogin()) {
        this.$emit('setModal', true);
        return;
      }
      this.$router.push({ name: pageName, params: { id: this.project.id } });
    },
  },
};
</script>
