<template>
  <v-stepper v-model="stepperPage" elevation="0">
    <v-stepper-items>
      <v-stepper-content step="1" class="pa-0">
        <v-responsive
          max-width="450"
          style="height: 75vh"
          class="mx-auto d-flex justify-center align-center"
        >
          <template v-if="$vuetify.breakpoint.xsOnly">
            <UpdatePasswordFormSp
              v-model="user"
              class="px-1"
              :loading="loading"
              @updatePassword="updatePassword"
            />
          </template>
          <template v-else>
            <UpdatePasswordFormPc
              v-model="user"
              :loading="loading"
              @updatePassword="updatePassword"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
      <v-stepper-content step="2" class="pa-0">
        <v-responsive
          max-width="450"
          style="height: 75vh"
          class="mx-auto d-flex justify-center align-center"
        >
          <v-card
            :outlined="!$vuetify.breakpoint.xsOnly"
            elevation="0"
            :width="$vuetify.breakpoint.xsOnly ? '350' : '400'"
            class="pt-5"
          >
            <v-container>
              <div class="d-flex justify-center">
                <div>
                  <v-img width="140" src="@/assets/img/logo.png" />
                </div>
              </div>
              <v-card-title class="justify-center font-weight-bold mt-3">
                パスワードの再設定
              </v-card-title>
              <v-card-text
                class="mt-2 mb-2"
                data-cy="update-password-form-complete-text"
              >
                パスワード再設定に成功しました。<br />
                ログインフォームからログインを完了してください。
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  class="font-weight-bold"
                  color="primary"
                  elevation="0"
                  text
                  @click="$router.push({ path: '/login' })"
                  data-cy="update-password-form-second-to-login-btn"
                >
                  ログインへ
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-responsive>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import UpdatePasswordFormPc from '../components/pc/UpdatePasswordForm';
import UpdatePasswordFormSp from '../components/sp/UpdatePasswordForm';

export default {
  name: 'UpdatePassword',
  components: {
    UpdatePasswordFormPc,
    UpdatePasswordFormSp,
  },
  data() {
    return {
      stepperPage: 1,
      user: {
        password: null,
        password_confirmation: null,
      },
      loading: false,
    };
  },
  created() {
    this.routerGuard();
  },
  methods: {
    routerGuard() {
      if (this.$route.query.reset_password_token != undefined) return;
      this.$router.push({ name: 'Home' });
    },

    async updatePassword(isInvalid) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      const hasNoMatchedPassword = !(
        this.user.password === this.user.password_confirmation
      );
      if (hasNoMatchedPassword) {
        this.$emit(
          'showSnackbar',
          'error',
          'パスワードとパスワード確認が一致していません。'
        );
        return;
      }
      try {
        this.loading = true;
        await this.$axios.patch('/api/v1/auth/password', {
          user: {
            password: this.user.password,
            password_confirmation: this.user.password_confirmation,
            reset_password_token: this.$route.query.reset_password_token,
          },
          change_password: false,
        });
        this.stepperPage = 2;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status === 401) {
          this.$router.push({ name: 'Login' });
          this.$emit('logout');
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
