<template>
  <v-card class="pr-14 pl-1" flat>
    <v-card-title class="py-3">
      <h3 data-cy="entry-step2-data-name">{{ project.name }}</h3>
    </v-card-title>
    <v-divider class="mb-1" />
    <v-card-text class="px-0">
      <v-row class="mb-2">
        <v-col cols="12" class="py-0 pl-5">
          <div class="d-flex" data-cy="entry-step2-data-payment">
            <div>
              <v-icon v-text="'mdi-cash-multiple'" />
            </div>
            <div class="pl-2 pt-1">
              <h3 class="accent--text font-weight-bold" v-text="costPrice" />
            </div>
            <div class="pt-1">
              <h4 class="font-weight-bold">／月</h4>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="py-0 pr-2">
          <h5 v-text="paymentSpan" data-cy="entry-step2-data-paymentspan" />
        </v-col>
        <v-col cols="12" md="4" sm="4" class="py-0 pl-5">
          <h4 class="pt-1 text" data-cy="entry-step2-data-municipality">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-search-outline'" />
            </span>
            {{ municipalityName }}
          </h4>
        </v-col>
        <v-col cols="12" md="4" sm="4" class="py-0 pl-5">
          <h4 class="pt-1 text" data-cy="entry-step2-data-station">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-marker-radius-outline'" />
            </span>
            {{ project.station }}
          </h4>
        </v-col>
        <v-col cols="12" md="4" sm="4" class="py-0 pl-5">
          <h4 class="pt-1 text" data-cy="entry-step2-data-work-location">
            <span>
              <v-icon class="pb-1" v-text="'mdi-account-outline'" />
            </span>
            {{ workLocation }}
          </h4>
        </v-col>
      </v-row>
      <v-divider class="my-1" />
      <v-row class="mt-1">
        <v-col cols="12" class="pl-5 pb-1 pt-2">
          <div class="font-weight-bold" data-cy="entry-step2-col-description">
            【業務内容】
          </div>
        </v-col>
        <v-col cols="12" class="pl-8 pr-5 pb-1 pt-0">
          <div
            style="white-space: pre-line"
            v-text="project.description"
            class="font-weight-bold text"
            data-cy="entry-step2-data-description"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-col cols="12" class="pl-5 pb-1">
          <div class="font-weight-bold" data-cy="entry-step2-col-require-skill">
            【開発環境】
          </div>
        </v-col>
        <v-col cols="12" class="pl-8 pr-5 pb-1 pt-0">
          <div class="pt-1">
            <div
              v-if="
                projectSkills.languages.length === 0 &&
                projectSkills.frameworks.length === 0 &&
                projectSkills.others.length === 0
              "
              class="font-weight-bold"
              v-text="'スキルが登録されていません。'"
            />
            <v-row v-else class="pb-2">
              <v-col
                cols="12"
                class="pb-0 pt-1"
                v-if="projectSkills.languages.length !== 0"
              >
                <h5 class="pb-2">言語</h5>
                <v-chip
                  v-for="(skill, index) in projectSkills.languages"
                  :key="index"
                  label
                  small
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pt-1"
                v-if="projectSkills.frameworks.length !== 0"
              >
                <h5 class="pb-2">フレームワーク</h5>
                <v-chip
                  v-for="(skill, index) in projectSkills.frameworks"
                  :key="index"
                  label
                  small
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pt-1"
                v-if="projectSkills.others.length !== 0"
              >
                <h5 class="pb-2">その他</h5>
                <v-chip
                  v-for="(skill, index) in projectSkills.others"
                  :key="index"
                  label
                  small
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-col cols="12" class="pl-5 pb-1">
          <div class="font-weight-bold" data-cy="entry-step2-col-require-skill">
            【求められるスキル】
          </div>
        </v-col>
        <v-col cols="12" class="pl-8 pr-5 pb-1 pt-0">
          <div
            style="white-space: pre-line"
            v-text="project.require_skill"
            class="font-weight-bold text"
            data-cy="entry-step2-data-require-skill"
          />
        </v-col>
      </v-row>
      <div v-if="project.note !== '' && project.note">
        <v-divider class="mt-5 mb-3" />
        <v-row>
          <v-col cols="12" class="pl-5 pb-1">
            <div class="font-weight-bold" data-cy="entry-step2-col-note">
              【備考】
            </div>
          </v-col>
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <div
              style="white-space: pre-line"
              v-text="project.note"
              class="font-weight-bold text"
              data-cy="entry-step2-data-note"
            />
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-col cols="12" class="pl-5 pb-1">
          <div class="font-weight-bold" data-cy="entry-step2-col-other">
            【その他募集要項】
          </div>
        </v-col>
        <template v-if="$vuetify.breakpoint.xsOnly">
          <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
          <!-- <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div
                  class="font-weight-bold"
                  v-text="'作業開始日'"
                  data-cy="entry-step2-col-other-start"
                />
              </v-col>
              <v-col cols="8">
                <div
                  style="white-space: pre-line"
                  v-text="startWorkAt"
                  class="font-weight-bold"
                  data-cy="entry-step2-data-other-start"
                />
              </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div
                  class="font-weight-bold"
                  v-text="'募集人数'"
                  data-cy="entry-step2-col-other-people"
                />
              </v-col>
              <v-col cols="8">
                <div
                  style="white-space: pre-line"
                  v-text="`${project.people}人`"
                  class="font-weight-bold"
                  data-cy="entry-step2-data-other-people"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div
                  class="font-weight-bold"
                  data-cy="entry-step2-col-interview-times"
                  v-text="'面談回数'"
                />
              </v-col>
              <v-col cols="8">
                <div
                  data-cy="entry-step2-interview-times"
                  v-text="project.interview_second_time_format ? '2回' : '1回'"
                  class="font-weight-bold"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-8 pb-1 pt-0">
            <v-row>
              <v-col cols="4">
                <div
                  class="font-weight-bold"
                  data-cy="entry-step2-col-interview-format"
                  v-text="'面談形式'"
                />
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <div
                      v-text="interviewFirstTimeFormat"
                      class="font-weight-bold"
                      data-cy="entry-step2-interview-first-times-format"
                    />
                  </v-col>
                  <v-col cols="12" class="pt-1 pb-0">
                    <div
                      v-text="interviewSecondTimeFormat"
                      class="font-weight-bold"
                      data-cy="entry-step2-interview-second-times-format"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-row class="pb-4">
            <v-col cos="4">
              <v-row>
                <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
                <!-- <v-col cols="12" class="pl-12 pb-0">
                  <div
                    class="font-weight-bold"
                    data-cy="entry-step2-col-other-start"
                    v-text="'作業開始日'"
                  />
                </v-col> -->
                <v-col cols="12" class="pl-12 pb-0 pt-2">
                  <div
                    class="font-weight-bold"
                    data-cy="entry-step2-col-other-people"
                    v-text="'募集人数'"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row>
                <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
                <!-- <v-col cols="12" class="pb-0">
                  <div
                    class="font-weight-bold"
                    data-cy="entry-step2-data-other-start"
                    v-text="startWorkAt"
                  />
                </v-col> -->
                <v-col cols="12" class="pb-0 pt-2">
                  <div
                    class="font-weight-bold"
                    v-text="`${project.people}人`"
                    data-cy="entry-step2-data-other-people"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cos="1">
              <v-row>
                <v-col cols="12" class="pb-0 px-0" align="center">
                  <div
                    class="font-weight-bold"
                    data-cy="entry-step2-col-interview-times"
                    v-text="'面談回数'"
                  />
                </v-col>
                <v-col cols="12" class="pb-0 px-0 pt-2" align="center">
                  <div
                    class="font-weight-bold"
                    data-cy="entry-step2-col-interview-format"
                    v-text="'面談形式'"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="12" class="pb-0 pl-0">
                  <div
                    class="font-weight-bold"
                    v-text="
                      project.interview_second_time_format ? '2回' : '1回'
                    "
                    data-cy="entry-step2-interview-times"
                  />
                </v-col>
                <v-col cols="12" class="pb-0 pl-0 pt-2">
                  <div>
                    <span
                      class="font-weight-bold"
                      v-text="interviewFirstTimeFormat"
                      data-cy="entry-step2-interview-first-times-format"
                    />
                    <span class="px-3" />
                    <span
                      class="font-weight-bold"
                      v-text="interviewSecondTimeFormat"
                      data-cy="entry-step2-interview-second-times-format"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-space-between mt-3 mb-5">
      <v-btn
        outlined
        width="45%"
        height="40px"
        class="font-weight-bold"
        color="primary"
        @click="backPage"
        data-cy="entry-step2-back-btn"
      >
        戻る
      </v-btn>
      <v-btn
        elevation="0"
        width="45%"
        height="40px"
        color="primary"
        class="white--text font-weight-bold"
        :loading="entryLoading"
        @click="createEntry"
        data-cy="entry-step2-entry-btn"
      >
        {{ userProjectInfo.entered ? '再応募する' : '応募する' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import mixinMunicipalities from '../../../const/project/municipality';
import mixinWorkLocation from '../../../const/project/workLocation';
import dayjs from 'dayjs';

export default {
  name: 'EntryFormSp',
  props: [
    'project',
    'user',
    'skillSheet',
    'entryLoading',
    'projectSkills',
    'userProjectInfo',
  ],
  mixins: [mixinMunicipalities, mixinWorkLocation],
  computed: {
    costPrice() {
      return this.project.cost != null ? `${this.project.cost}万円` : null;
    },

    startWorkAt() {
      if (this.project.start_work_at == null) return null;
      const setDate = dayjs(this.project.start_work_at);
      return setDate.format('YYYY/MM/DD');
    },

    paymentSpan() {
      return this.project != null
        ? `（ 精算幅: ${this.project.work_time_start}-${this.project.work_time_finish}h、 支払いサイト: 30日 ）`
        : null;
    },

    municipalityName() {
      return this.municipalities.find(
        element => element.id === this.project.municipality
      ).value;
    },

    workLocation() {
      return this.workLocations.find(
        element => element.id === this.project.work_location
      ).value;
    },

    interviewFirstTimeFormat() {
      const prefix = this.project.interview_second_time_format ? '1回目：' : '';
      return this.project != null
        ? prefix + this.project.interview_first_time_format
        : '';
    },

    interviewSecondTimeFormat() {
      if (this.project == null) return '';
      return this.project.interview_second_time_format != null
        ? `2回目：${this.project.interview_second_time_format}`
        : '';
    },
  },
  methods: {
    createEntry() {
      if (this.userProjectInfo.entered) {
        this.$emit('reEntry');
      } else {
        this.$emit('createEntry');
      }
    },

    backPage() {
      this.$emit('backPage');
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  font-size: 12px;
}

/* 各スキルのホバー表示を消す */
.skills.v-chip {
  pointer-events: none;
}
</style>
