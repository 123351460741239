<template>
  <v-responsive
    max-width="423"
    style="height: 75vh"
    class="mx-auto d-flex justify-center align-center"
  >
    <template v-if="!$vuetify.breakpoint.mobile">
      <LoginFormPc
        v-model="user"
        :isConfirmationEmail="isConfirmationEmail"
        :loading="loading"
        @login="login"
      />
    </template>
    <template v-else>
      <LoginFormSp
        v-model="user"
        :isConfirmationEmail="isConfirmationEmail"
        :loading="loading"
        @login="login"
      />
    </template>
  </v-responsive>
</template>

<script>
import LoginFormPc from '../components/pc/LoginForm.vue';
import LoginFormSp from '../components/sp/LoginForm.vue';
import utils from '../plugins/utils';
import session from '../plugins/session';

export default {
  name: 'Login',
  components: {
    LoginFormPc,
    LoginFormSp,
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      isConfirmationEmail: false,
      loading: false,
    };
  },
  created() {
    // this.onGoogleLogin();
  },
  beforeRouteEnter(_, form, next) {
    // ブラウザバック時に認証メールの判定処理を発火させないように制御
    if (form.name === null) {
      next(vm => vm.switchDecision());
    } else {
      next();
    }
  },
  methods: {
    authenticationEmailDecision() {
      if (this.$route.query.change_email) {
        // メール変更の認証成功時はセッション情報を削除する
        session.removeItemKeys();
        return;
      }
      if (this.$route.query.account_confirmation_success === 'true') return;
      switch (this.$route.query.type) {
        case 'invalid':
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレスの確認のリクエストに失敗しました。'
          );
          break;
        case 'confirmation_period_expired':
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレスの確認のリクエストの期限が切れています'
          );
          break;
        case 'already_confirmed':
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレス確認のURLが既に使用されています'
          );
          break;
        default:
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレスの確認のリクエストに失敗しました。'
          );
          break;
      }
    },

    userUnlockDecision() {
      switch (this.$route.query.user_unlock_success) {
        case 'true':
          this.$emit(
            'showSnackbar',
            'success',
            'アカウントのロックが解除されました。'
          );
          break;
        case 'false':
          this.$emit(
            'showSnackbar',
            'error',
            'アカウントロック解除のリクエストに失敗しました。'
          );
          break;
        default:
          this.$emit(
            'showSnackbar',
            'error',
            'アカウントロック解除のリクエストに失敗しました。'
          );
          break;
      }
    },

    switchDecision() {
      if (Object.keys(this.$route.query).length < 1) return;
      if (this.$route.query.user_unlock_success) {
        this.userUnlockDecision();
      } else {
        this.authenticationEmailDecision();
      }
    },

    async login(isformValid) {
      if (isformValid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }

      try {
        this.loading = true;
        const result = await this.$axios.post(
          '/api/v1/auth/sign_in',
          this.user,
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (
          result.status == 200 &&
          utils.hasOwnPropertyCall(result.headers, 'access-token')
        ) {
          this.$emit('showSnackbar', 'success', 'ログインに成功しました。');
          this.$emit('login', result.headers);
          this.$emit('getMyProfile');
          this.$router.push({ path: '/' });
          return;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        // TODO: メールアドレスとパスワードはあってる＆＆メールアドレス未認証のケースで出すように改修
        this.isConfirmationEmail = true;
        if (err.response.status == 401) {
          this.$emit('logout');
          this.$emit('showSnackbar', 'error', 'ログインに失敗しました。');
          return;
        }
        this.$emit('logout');
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    // onGoogleLogin() {
    // TODO: 期限切れURLでもqueryを使用しているので判定の修正が必要。
    //   if (Object.keys(this.$route.query).length < 1) return;

    //   if (this.$route.query.user_id) {
    //     this.$emit(
    //       'showSnackbar',
    //       'success',
    //       'Googleのログインに成功しました。'
    //     );
    //     this.$emit('googleLogin', this.$route.query);
    //     this.$router.replace('/');
    //     return;
    //   }
    //   if (this.$route.query.existEmail) {
    //     this.$emit('showSnackbar', 'error', 'すでにEmailが登録されています。');
    //   } else if (this.$route.query.failure) {
    //     this.$emit('showSnackbar', 'error', 'Googleのログインに失敗しました。');
    //   }

    //   this.$emit('logout');
    //   this.$router.replace('/login');
    // },
  },
};
</script>
