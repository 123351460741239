<template>
  <div class="d-flex justify-center">
    <ContactForm
      v-if="isPC"
      v-model="inquiry"
      :is-login="isLogin"
      :loading="loading"
      @showModal="showModal"
    />
    <ContactFormSp
      v-else
      v-model="inquiry"
      :is-login="isLogin"
      :loading="loading"
      @showModal="showModal"
    />
    <ConfirmModal
      ref="child"
      :type="modal.type"
      :dialog="modal.show"
      :title="modal.title"
      :text="modal.text"
      :yesColor="modal.yesColor"
      :noColor="modal.noColor"
      :yesBtn="modal.yesBtn"
      @commit="create"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ContactForm from '../components/pc/ContactForm.vue';
import ContactFormSp from '../components/sp/ContactForm.vue';
import ConfirmModal from '../components/common/ConfirmModal.vue';
import session from '../plugins/session';

export default {
  name: 'Contact',
  components: {
    ContactForm,
    ContactFormSp,
    ConfirmModal,
  },
  props: ['isLogin'],
  data() {
    return {
      inquiry: {
        firstName: null,
        lastName: null,
        name: null,
        email: null,
        other_title: null,
        content: null,
        title: 3,
        status: 0,
        priority: 1,
      },
      loading: false,
      isInvalid: false,
      skipInfo: false,
      modal: {
        type: null,
        show: false,
        title: null,
        text: null,
        yesColor: null,
        noColor: null,
        yesBtn: null,
        functionArg: null,
      },
    };
  },
  computed: {
    isPC() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false;
        case 'sm':
          return false;
        case 'md':
          return true;
        case 'lg':
          return true;
        case 'xl':
          return true;
        default:
          return true;
      }
    },
  },
  methods: {
    resetModel() {
      this.modal.type = null;
      this.modal.title = null;
      this.modal.text = null;
      this.modal.show = false;
      this.modal.functionArg = null;
      this.$refs.child.finishLoading();
    },

    showModal(isInvalid, skipInfo) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      this.isInvalid = isInvalid;
      this.skipInfo = skipInfo;
      this.modal.yesColor = 'primary';
      this.modal.yesBtn = '送信';
      this.modal.title = 'お問い合わせを送信しますか?';
      this.modal.text = '送信後は内容を変更することができません。';
      this.modal.show = true;
    },

    closeModal() {
      this.resetModel();
    },

    async create() {
      this.skipInfo
        ? (this.inquiry.email = null)
        : (this.inquiry.name =
            this.inquiry.lastName + ' ' + this.inquiry.firstName);
      try {
        this.loading = true;
        const result = await this.$axios.post(
          '/api/v1/inquiries',
          {
            inquiry: {
              name: this.inquiry.name,
              email: this.inquiry.email,
              other_title: this.inquiry.other_title,
              content: this.inquiry.content,
              title: this.inquiry.title,
              status: this.inquiry.status,
              priority: this.inquiry.priority,
            },
          },
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 204) {
          this.$emit('showSnackbar', 'success', '送信しました。');
          this.resetModel();
          this.$router.push({ path: '/' });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status === 401) {
          this.$emit('logout');
          this.resetModel();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.resetModel();
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.resetModel();
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
