<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-container>
        <v-form ref="form" v-model="btnDisable">
          <v-row>
            <v-col cols="12">
              <h5>現在のメールアドレス</h5>
              <v-text-field
                data-cy="update-email-form-current-email"
                outlined
                dense
                disabled
                hideDetails="auto"
                class="pt-2"
                color="primary"
                :value="currentEmailInitialValue"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h5>新しいメールアドレス</h5>
              <v-text-field
                data-cy="update-email-form-new-email"
                placeholder="新しいメールアドレス"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                color="primary"
                :value="emailInitialValue"
                @input="updateUnconfirmedEmail"
                :rules="rules.email"
              />
            </v-col>
          </v-row>
          <v-row style="color: rgba(0, 0, 0, 0.6)">
            <v-col cols="12" class="pt-0">
              <div class="d-flex">
                <div>
                  <h6>※</h6>
                </div>
                <div>
                  <h6 class="email-hint">
                    「更新」ボタンを押下すると新しいメールアドレス宛に確認メールが送信されます。
                  </h6>
                  <h6>
                    メール本文中のURLにアクセスしていただくことでメールアドレスが変更されます。
                  </h6>
                  <h6 class="pt-4">
                    変更後は元のメールアドレスでログインが出来なくなりますのでご注意ください。
                  </h6>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <div class="d-flex mt-3">
      <v-spacer />
      <v-btn
        data-cy="update-email-form-btn"
        width="100"
        class="mt-2 font-weight-bold"
        elevation="0"
        height="35px"
        color="primary white--text"
        @click="update"
        :loading="loadingByEmail"
      >
        更新
      </v-btn>
    </div>
  </div>
</template>

<script>
import validate from '../../../plugins/validate';

export default {
  name: 'EmailMypageSp',
  props: ['value', 'loadingByEmail'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.userEmail(value)],
      },
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Email');
  },
  computed: {
    currentEmailInitialValue() {
      return this.value == null ? null : this.value.email;
    },

    emailInitialValue() {
      return this.value?.email == null ? null : this.value.unconfirmed_email;
    },
  },
  methods: {
    resetValidate() {
      this.$refs.form.resetValidation();
    },

    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateUnconfirmedEmail(e) {
      const newValue = {
        ...this.value,
        unconfirmed_email: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
