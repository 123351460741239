<template>
  <v-card flat class="px-3 pb-0">
    <div class="d-flex justify-center">
      <div>
        <v-img width="160" src="@/assets/img/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center pb-1 font-weight-bold">
      ログイン
    </v-card-title>
    <v-form v-model="btnDisable">
      <v-row class="mb-3 mt-1 px-5">
        <v-col cols="12" class="py-0">
          <h6>メールアドレス</h6>
        </v-col>
        <v-col cols="12" class="pt-0" align="center">
          <v-text-field
            color="primary"
            dense
            outlined
            placeholder="メールアドレス"
            hide-details="auto"
            :value="emailInitialValue"
            @input="updateEmail"
            :rules="rules.email"
            data-cy="login-form-email-form"
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <h6>パスワード</h6>
        </v-col>
        <v-col cols="12" class="pt-0 pb-1" align="center">
          <v-text-field
            color="primary"
            placeholder="パスワード"
            :type="passwordTypeText"
            hide-details="auto"
            outlined
            dense
            :append-icon="passwordTypeIcon"
            @click:append="passwordType = !passwordType"
            :value="passwordInitialValue"
            @input="updatePassword"
            :rules="rules.password"
            data-cy="login-form-password-form"
          />
        </v-col>
        <v-col
          cols="12"
          align="right"
          class="pr-0 pb-0 pt-1"
          v-if="isConfirmationEmail"
        >
          <v-btn
            data-cy="login-form-confirmation-email-link"
            text
            color="primary"
            class="font-weight-bold"
            v-text="'メール認証がお済みでない方はこちら'"
            @click="$router.push({ path: '/confirmation-email' })"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="mt-2 px-5 pa-0 justify-space-between">
      <v-btn
        elevation="0"
        color="primary"
        class="white--text font-weight-bold"
        block
        @click="login"
        data-cy="login-form-login-btn"
        :loading="loading"
      >
        <v-icon
          class="mail-icon-position"
          dark
          size="25"
          v-text="'mdi-email-outline'"
        />
        ログイン
      </v-btn>
    </v-card-actions>
    <!-- TODO: googleのSSO実装 -->
    <!-- <v-card-actions class="mt-3 px-5 pa-0 justify-space-between">
      <v-btn :href="googleAuthUrl()" elevation="0" block outlined color="primary">
        <v-img src="@/assets/google.svg" class="google-icon-position" />
        Googleでログイン
      </v-btn>
    </v-card-actions> -->
    <v-card-actions class="mt-3 px-5 pa-0 justify-space-between">
      <v-btn
        text
        color="primary"
        class="font-weight-bold px-0 v-btn-text"
        @click="$router.push({ path: '/resetpassword' })"
        v-text="'パスワードをお忘れですか？'"
        data-cy="login-form-resetpassword-btn"
      />
      <v-btn
        text
        color="primary"
        class="font-weight-bold px-0 v-btn-text"
        @click="$router.push({ path: '/registeruser' })"
        v-text="'新規登録へ'"
        data-cy="login-form-registeruser-btn"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'LoginFormSp',
  props: ['value', 'isConfirmationEmail', 'loading'],
  data() {
    return {
      btnDisable: false,
      passwordType: false,
      rules: {
        email: [value => validate.userEmail(value)],
        password: [value => validate.userPassword(value)],
      },
    };
  },
  computed: {
    passwordTypeText() {
      return this.passwordType ? 'text' : 'password';
    },

    passwordTypeIcon() {
      return this.passwordType ? 'mdi-eye' : 'mdi-eye-off';
    },

    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },

    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },
  },
  methods: {
    // googleAuthUrl() {
    //   return process.env.VUE_APP_GOOGLE_AUTH_URL;
    // },

    login() {
      this.$emit('login', !this.btnDisable);
    },

    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },

    updatePassword(event_password) {
      const newValue = {
        ...this.value,
        password: event_password,
      };
      this.$emit('input', newValue);
    },

    routerPush(path) {
      const url = path;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}

/* .google-icon-position {
  position: absolute;
  left: -12px;
  bottom: -12px;
} */

.v-btn-text {
  font-size: 12px;
}
</style>
