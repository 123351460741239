<template>
  <v-card
    flat
    min-width="400"
    min-height="515"
    class="px-10 pb-2 text-center d-flex align-center"
  >
    <v-responsive max-width="340" style="height: 100%" class="mx-auto d-flex">
      <div class="d-flex justify-center">
        <div>
          <v-img width="180" src="@/assets/img/logo.png" />
        </div>
      </div>
      <v-card-title class="justify-center">
        <h3>パスワードの再設定</h3>
      </v-card-title>
      <v-card-subtitle class="pt-3 pb-1" style="color: rgba(0, 0, 0, 0.6)">
        <h4>新しいパスワードを設定してください</h4>
      </v-card-subtitle>
      <v-card-text class="px-0">
        <v-form v-model="btnDisable">
          <v-row class="pt-3">
            <v-col cols="12" align="left" class="py-0">
              <h5 class="black--text">パスワード</h5>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                color="primary"
                placeholder="パスワード"
                :type="passwordType"
                hide-details="auto"
                dense
                :value="passwordInitialValue"
                @input="updatePassword"
                :rules="rules.password"
                data-cy="update-password-form-password-form"
              />
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="12" align="left" class="py-0">
              <h5 class="black--text">パスワード確認</h5>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                color="primary"
                placeholder="パスワード確認"
                :type="passwordType"
                hide-details="auto"
                dense
                :value="passwordConfirmationInitialValue"
                @input="updatePasswordConfirmation"
                :rules="rules.passwordConfirmation"
                data-cy="update-password-form-password-confirmation-form"
              />
            </v-col>
          </v-row>
          <v-row class="pt-1">
            <v-col cols="12" class="py-0">
              <div class="d-flex justify-end">
                <v-checkbox
                  v-model="passwordCheckbox"
                  label="パスワードを表示する"
                  dense
                  class="font-weight-regular"
                  color="primary"
                  hide-details="auto"
                  data-cy="update-password-form-show-password-checkbox"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-4 pa-0">
        <v-row class="px-1">
          <v-col cols="12" align="center">
            <v-btn
              elevation="0"
              color="primary"
              class="white--text font-weight-bold"
              block
              height="40px"
              @click="update"
              data-cy="update-password-form-first-update-btn"
              :loading="loading"
            >
              <v-icon
                class="cached-icon-position"
                dark
                size="25"
                v-text="'mdi-cached'"
              />
              更新する
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions class="pt-6 pa-0 justify-center">
        <v-btn
          class="font-weight-bold"
          text
          color="primary"
          @click="$router.push({ path: '/login' })"
          v-text="'ログインへ戻る'"
          data-cy="update-password-form-first-to-login-btn"
        />
      </v-card-actions>
    </v-responsive>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'UpdatePasswordFormPc',
  props: ['value', 'loading'],
  data() {
    return {
      btnDisable: false,
      passwordCheckbox: false,
      rules: {
        password: [value => validate.userPassword(value)],
        passwordConfirmation: [value => validate.userPassword(value)],
      },
    };
  },
  computed: {
    passwordType() {
      return this.passwordCheckbox ? 'text' : 'password';
    },

    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },
    passwordConfirmationInitialValue() {
      return this.value == null ? null : this.value.password_confirmation;
    },
  },
  methods: {
    update() {
      this.$emit('updatePassword', !this.btnDisable);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
.cached-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
