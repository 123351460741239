<template>
  <v-responsive max-width="1161" class="mx-auto not-overflow-hidden">
    <v-container>
      <v-row justify="center">
        <!-- PC検索レイアウト -->
        <v-col
          v-if="!this.$vuetify.breakpoint.mobile"
          cols="3"
          class="pl-0"
          align="left"
        >
          <ProjectSearchPc
            :outputFreeWord="outputFreeWord"
            :minCostQuery="minCostQuery"
            :maxCostQuery="maxCostQuery"
            :selectedMunicipalities="selectedMunicipalities"
            :btnDisable="btnDisable"
            :selectedWorkLocations="selectedWorkLocations"
            :skills="skills"
            :selectedSkills="selectedSkills"
            @inputFreeWord="inputFreeWord"
            @updateMaxCostQuery="updateMaxCostQuery"
            @updateMinCostQuery="updateMinCostQuery"
            @changeWorkLocations="changeWorkLocations"
            @changeSkills="changeSkills"
            @changeMunicipalities="changeMunicipalities"
            @search="search"
            @removeSelectChip="removeSelectChip"
            @changeBtnDisable="changeBtnDisable"
            class="mb-5 sticky-content"
          />
        </v-col>
        <v-col :cols="displayAsPc" class="pt-4">
          <!-- PC 表示レイアウト -->
          <template v-if="!this.$vuetify.breakpoint.mobile">
            <ProjectsHeaderPc
              :displayCount="displayCount"
              :searchWordsArray="searchWordsArray"
              :sortSelectItems="sortSelectItems"
              :sortInitialValue="sortInitialValue"
              @changeSort="changeSort"
              @resetSearch="resetSearch"
            />
          </template>
          <!-- SP 表示レイアウト -->
          <template v-else>
            <ProjectsHeaderSp
              :displayCount="displayCount"
              :searchWordsArray="searchWordsArray"
              :sortSelectItems="sortSelectItems"
              :sortInitialValue="sortInitialValue"
              :outputFreeWord="outputFreeWord"
              :minCostQuery="minCostQuery"
              :maxCostQuery="maxCostQuery"
              :selectedMunicipalities="selectedMunicipalities"
              :selectedWorkLocations="selectedWorkLocations"
              :skills="skills"
              :selectedSkills="selectedSkills"
              :btnDisable="btnDisable"
              @changeBtnDisable="changeBtnDisable"
              @changeSort="changeSort"
              @resetSearch="resetSearch"
              @inputFreeWord="inputFreeWord"
              @updateMaxCostQuery="updateMaxCostQuery"
              @updateMinCostQuery="updateMinCostQuery"
              @changeWorkLocations="changeWorkLocations"
              @changeSkills="changeSkills"
              @changeMunicipalities="changeMunicipalities"
              @search="search"
            />
          </template>
          <Loading v-if="loading" />
          <v-row v-else>
            <!-- PC 表示レイアウト -->
            <v-col
              cols="12"
              class="pt-0"
              v-if="!this.$vuetify.breakpoint.mobile"
            >
              <ProjectCardPc
                v-for="(project, index) in projects"
                :key="index"
                :project="project"
                :bookmarks="bookmarks"
                :entries="entries"
                :skills="skills"
                @changeBookmark="changeBookmark"
              />
            </v-col>
            <!-- SP 表示レイアウト -->
            <v-col cols="12" class="pt-0" v-else>
              <ProjectCardSp
                v-for="(project, index) in projects"
                :key="index"
                :project="project"
                :bookmarks="bookmarks"
                :entries="entries"
                :skills="skills"
                @changeBookmark="changeBookmark"
              />
            </v-col>
            <v-col cols="12" class="pt-0" v-if="isProjects">
              <h5 v-text="notExistText" style="white-space: pre-line" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-pagination
            v-if="page.totalCount !== 0"
            v-model="page.currentPage"
            color="primary"
            :length="page.totalPages"
            :total-visible="6"
            @input="changePage"
          ></v-pagination>
        </v-col>
      </v-row>
      <EncourageLoginModal
        :dialog="modal.show"
        @close="modal.show = !modal.show"
      />
    </v-container>
  </v-responsive>
</template>

<style scoped>
.not-overflow-hidden {
  overflow: visible !important;
}

.sticky-content {
  position: sticky;
  top: 76px;
}
</style>

<script>
import session from '../plugins/session';
import ProjectCardPc from '../components/pc/project/ProjectCard.vue';
import ProjectSearchPc from '../components/pc/project/ProjectSearch.vue';
import ProjectsHeaderPc from '../components/pc/project/ProjectsHeader.vue';
import ProjectsHeaderSp from '../components/sp/project/ProjectsHeader.vue';
import ProjectCardSp from '../components/sp/project/ProjectCard.vue';
import Loading from '../components/common/Loading.vue';
import EncourageLoginModal from '../components/common/EncourageLoginModal.vue';
import mixinWorkLocation from '../const/project/workLocation';
import mixinMunicipalities from '../const/project/municipality';
import mixinSkillInitData from '../const/skill/initData';

export default {
  name: 'Projects',
  props: ['isLogin'],
  components: {
    ProjectCardPc,
    ProjectSearchPc,
    ProjectsHeaderPc,
    ProjectsHeaderSp,
    ProjectCardSp,
    EncourageLoginModal,
    Loading,
  },
  mixins: [mixinWorkLocation, mixinMunicipalities, mixinSkillInitData],
  data() {
    return {
      loading: true,
      modal: {
        show: false,
      },
      selectedWorkLocations: [],
      selectedMunicipalities: [],
      selectedMaxCost: null,
      selectedMinCost: null,
      bookmarks: [],
      entries: [],
      skills: [],
      selectedSkills: [],
      freeWordList: [],
      page: {
        currentPage: null,
        totalPages: null,
        limitValue: null,
        value: null,
        totalCount: null,
      },
      sortSelectItems: [
        { id: 1, value: '新着順' },
        { id: 2, value: '投稿順' },
        { id: 3, value: '単価が高い順' },
        { id: 4, value: '単価が低い順' },
      ],
      notExistText:
        '該当する案件は、Web上の公開案件からは見つかりませんでした。\n検索条件を変えて再度案件検索してください。',
      projects: null,
      prefectureDialog: false,
      maxCostQuery: null,
      minCostQuery: null,
      btnDisable: true,
      searchWordsArray: [],
    };
  },
  created() {
    this.setSkillQuery();
    this.checkQueryParams('created');
    this.getProjects();
    this.getBookmarks();
    this.getEntries();
    this.getSkills().then(() => {
      // スキル情報の取得が完了してから検索ワードのセットをする必要があるため。
      this.setSearchedWord();
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path.match(/^\/projects\//)) {
        vm.setSearchedWord();
        vm.getProjects();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.checkQueryParams('beforeRouteUpdate');
    this.getProjects(to);
    this.setSearchedWord(to);
    window.scrollTo(0, 0);
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.match(/^\/projects\//)) {
      this.resetAllSearch();
      this.removeSearchQuery();
      this.setSkillQuery(to);
      this.checkQueryParams('created');
      this.getProjects();
      this.setSearchedWord();
    }
    next();
  },
  computed: {
    sortInitialValue() {
      switch (this.$route.query['order']) {
        case '1':
          return this.sortSelectItems[0].id;
        case '2':
          return this.sortSelectItems[1].id;
        case '3':
          return this.sortSelectItems[2].id;
        case '4':
          return this.sortSelectItems[3].id;
        default:
          return this.sortSelectItems[0].id;
      }
    },

    displayAsPc() {
      return !this.$vuetify.breakpoint.mobile ? '9' : '12';
    },

    displayCount() {
      // pagyで制限している表示上限数を変更した際は修正が必要（現在20件）
      if (this.projects == null) {
        return '表示件数 件中 〜 件を表示';
      }
      if (this.page.totalCount == 0) {
        return '該当する案件: 0件';
      }
      const startNum =
        this.page.currentPage === 1 ? 1 : 1 + 20 * (this.page.currentPage - 1);
      const endNum = startNum + this.page.value - 1;
      return (
        '表示案件数' +
        this.page.totalCount.toString() +
        '件中' +
        startNum.toString() +
        '～' +
        endNum.toString() +
        '件を表示'
      );
    },

    outputFreeWord() {
      if (this.$route.query['free_words[]'] == null) return null;
      // HACK: リファクタ必要なため一旦skip
      /* eslint vue/no-side-effects-in-computed-properties: 0 */
      if (
        this.$route.query['free_words[]'] &&
        Array.isArray(this.$route.query['free_words[]'])
      ) {
        this.freeWordList = this.$route.query['free_words[]'];
        return this.$route.query['free_words[]'].join(' ');
      } else {
        this.freeWordList = [this.$route.query['free_words[]']];
      }
      return this.$route.query['free_words[]'];
    },

    isProjects() {
      return !(this.projects && this.projects.length !== 0);
    },
  },
  methods: {
    setSearchedWord(nextRoute) {
      const setRoute = nextRoute ? nextRoute : this.$route;
      this.searchWordsArray = this.otherAsQuery(
        setRoute,
        this.freeWordsAsQuery(setRoute)
      );
    },

    // queryを元にフリーワードを配列に変換させる関数
    freeWordsAsQuery(route) {
      let freeWordArray = [];
      if (route.query['free_words[]'] != null) {
        freeWordArray = freeWordArray.concat(route.query['free_words[]']);
      }
      return freeWordArray;
    },

    multipleQueries(route) {
      const targetRoute = route ? route : this.$route;
      const multipleQueryStatus = {
        work_locations: targetRoute.query['work_locations[]'] != null,
        municipalities: targetRoute.query['municipalities[]'] != null,
        skills: targetRoute.query['skills[]'] != null,
      };
      const selectedArray = {
        work_locations: [],
        municipalities: [],
        skills: [],
      };
      // this.$route.query['query_name'] で取得できる値に注意
      // → 1つのqueryが2つ以上の場合はarrayで取得できるが、
      //   1つの場合はstringで取得できるのでバグの原因となる。
      Object.keys(multipleQueryStatus).forEach(key => {
        if (multipleQueryStatus[key]) {
          selectedArray[key] = []
            .concat(targetRoute.query[`${key}[]`])
            .map(i => Number(i));
        }
      });
      return selectedArray;
    },

    // queryを元にフリーワード以外のを配列に変換させる関数
    otherAsQuery(route, freeWordArray) {
      let minCost = null;
      let maxCost = null;
      // 単価上限
      if (route.query['max_cost']) {
        maxCost = route.query['max_cost'];
        this.maxCostQuery = route.query['max_cost']; // リロード＆ブラウザバック時に反映させる為
      }
      // 単価下限
      if (route.query['min_cost']) {
        minCost = route.query['min_cost'];
        this.minCostQuery = route.query['min_cost']; // リロード＆ブラウザバック時に反映させる為
      }
      const selectedArray = this.multipleQueries(route);
      const searchedWorkLocations = selectedArray.work_locations.map(
        EachNum => {
          return this.workLocations.find(element => element.id === EachNum)
            ?.value;
        }
      );
      const searchedMunicipalities = selectedArray.municipalities.map(
        EachNum => {
          return this.municipalities.find(element => element.id === EachNum)
            ?.value;
        }
      );
      const searchedSkills = selectedArray.skills.map(skillNumber => {
        return this.skills.find(skill => skill.number === skillNumber)?.name;
      });
      const setTargetWords = [
        ...freeWordArray,
        ...searchedWorkLocations,
        ...searchedMunicipalities,
        ...searchedSkills,
      ];
      if (maxCost && minCost) {
        setTargetWords.push(`${minCost}万円~${maxCost}万円`);
      }
      // 重複を削除
      const targetWords = setTargetWords
        .filter(element => element != null)
        .filter((elem, index, self) => self.indexOf(elem) === index);

      return targetWords;
    },

    // queryから代入する、リロード時にstringが代入されるのでintegerに変換
    checkQueryParams(type) {
      if (type === 'created') {
        const selectedArray = this.multipleQueries();
        this.selectedWorkLocations = selectedArray.work_locations;
        this.selectedMunicipalities = selectedArray.municipalities;
        this.selectedSkills = selectedArray.skills;

        // 単価上限
        if (this.$route.query['max_cost']) {
          this.selectedMaxCost = this.$route.query['max_cost'];
        }
        // 単価下限
        if (this.$route.query['min_cost']) {
          this.selectedMinCost = this.$route.query['min_cost'];
        }
      } else {
        this.selectedMaxCost = this.maxCostQuery;
        this.selectedMinCost = this.minCostQuery;
      }
    },

    async getProjects(nextRoute) {
      this.loading = true;
      try {
        const result = await this.$axios.get(
          '/api/v1/projects' + this.AdjustSearchAPI(nextRoute),
          { headers: session.apiAuthHeaders() }
        );
        const resultData = {
          ...this.page,
          currentPage: result.data.pagination.current_page,
          totalPages: result.data.pagination.total_pages,
          value: result.data.pagination.value,
          limitValue: result.data.pagination.limit_value,
          totalCount: result.data.pagination.total_count,
        };
        this.page = resultData;
        this.projects = result.data.projects;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    resetAllSearch() {
      this.selectedWorkLocations = [];
      this.selectedMunicipalities = [];
      this.selectedSkills = [];
      this.selectedMaxCost = null;
      this.selectedMinCost = null;
      this.freeWordList = [];
      this.maxCostQuery = null;
      this.minCostQuery = null;
    },

    AdjustSearchAPI(nextRoute) {
      const setRoute = nextRoute ? nextRoute : this.$route;
      let setSearchAPI = [];
      let setPageAPI = '';
      let setOrderAPI = '';

      // setRoute.queryが存在しない場合はガード節
      // チェックボックスに値が代入されている可能性があるため全てリセットさせる
      if (!Object.keys(setRoute.query).length) {
        this.resetAllSearch();
        return '';
      }

      let searchItems = [
        'free_words[]',
        'work_locations[]',
        'municipalities[]',
        'skills[]',
      ];
      searchItems.forEach(element => {
        if (setRoute.query[element]) {
          setSearchAPI.push(this.encodePase(element, setRoute));
        }
      });

      // 単価のqueryをセット
      if (this.selectedMaxCost != null && this.selectedMinCost != null) {
        setSearchAPI.push(
          `max_cost=${Number(this.selectedMaxCost).toString()}`,
          `min_cost=${Number(this.selectedMinCost).toString()}`
        );
      }

      // 重複しているqueryを削除
      const filteredSearchAPI = setSearchAPI.filter(
        (elem, index, self) => self.indexOf(elem) === index
      );

      // page指定されている場合
      if (setRoute.query.page) {
        setPageAPI += `&page=${setRoute.query.page}`;
      }
      // searchするqueryが存在しない場合
      if (filteredSearchAPI.length === 0) {
        if (setRoute.query.page) {
          setPageAPI = `?page=${setRoute.query.page}`;
        } else {
          setPageAPI = '';
        }
      }
      let formalAdjustment = '';
      filteredSearchAPI.forEach((element, index) => {
        if (index === 0) {
          formalAdjustment += '/search?' + element;
        } else {
          formalAdjustment += '&' + element;
        }
      });
      // orderを指定
      if (setRoute.query['order']) {
        setOrderAPI += `order=${setRoute.query.order}`;
      }
      if (formalAdjustment === '' && setPageAPI === '') {
        return `/search?${setOrderAPI}`;
      }

      if (formalAdjustment === '' && setOrderAPI !== '') {
        return '/search' + setPageAPI + '&' + setOrderAPI;
      } else {
        return formalAdjustment + setPageAPI + '&' + setOrderAPI;
      }
    },

    encodePase(searchName, setRoute) {
      let result = '';
      // queryが複数の場合はarray
      if (Array.isArray(setRoute.query[searchName])) {
        setRoute.query[searchName].forEach((element, index) => {
          if (index === 0)
            return (result += searchName + '=' + encodeURI(element));
          return (result += '&' + searchName + '=' + encodeURI(element));
        });
      } else {
        // queryが1つの場合は要素本体
        return (result +=
          searchName + '=' + encodeURI(setRoute.query[searchName]));
      }
      return result;
    },

    async getBookmarks() {
      if (!this.isLogin()) return;
      try {
        const result = await this.$axios.get('/api/v1/bookmarks', {
          headers: session.apiAuthHeaders(),
        });
        const pushBookmarks = result.data.map(element => {
          return { bookmarkId: element.id, projectId: element.project.id };
        });
        this.bookmarks = pushBookmarks;
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/required_skills', {
          headers: { 'Content-Type': 'application/json' },
        });
        if (result.status == 200) this.skills = result.data;
      } catch (err) {
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    inputFreeWord(event) {
      const REGEX = /\s+/;
      const setFreeWords = event.split(REGEX).filter(element => {
        return element != '';
      });
      this.freeWordList = setFreeWords;
    },

    changeSort(event) {
      let setRequest = this.setSearchRequest();
      if (!setRequest) return;
      setRequest['order'] = event;
      this.$router
        .push({ name: 'Projects', query: setRequest })
        .catch(() => {});
      window.scrollTo(0, 0);
    },

    setSearchRequest() {
      let targetQuery = {};
      if (this.freeWordList.length != 0) {
        targetQuery['free_words[]'] = this.freeWordList;
      }
      if (this.selectedWorkLocations.length != 0) {
        targetQuery['work_locations[]'] = this.selectedWorkLocations;
      }
      if (this.selectedMunicipalities.length != 0) {
        targetQuery['municipalities[]'] = this.selectedMunicipalities;
      }
      if (this.selectedSkills.length != 0) {
        targetQuery['skills[]'] = this.selectedSkills;
      }
      if (this.$route.query.order != undefined) {
        targetQuery['order'] = this.$route.query.order;
      }
      if (this.btnDisable && !!this.maxCostQuery && !!this.minCostQuery) {
        if (Number(this.minCostQuery) <= Number(this.maxCostQuery)) {
          // 先頭に0が入っている場合は削除させるために一度数値に変換させる
          targetQuery['min_cost'] = Number(this.minCostQuery).toString();
          targetQuery['max_cost'] = Number(this.maxCostQuery).toString();
        } else {
          this.$emit('showSnackbar', 'error', '最小値が最大値を超えています。');
          return false;
        }
      } else if (!this.btnDisable) {
        this.$emit(
          'showSnackbar',
          'error',
          '単価は半角数値で入力してください。'
        );
        return false;
      }
      return targetQuery;
    },

    removeSelectChip(removeValue) {
      switch (removeValue.type) {
        case 'skills':
          this.selectedSkills = this.selectedSkills.filter(skillId => {
            return removeValue.value !== skillId;
          });
          break;
        case 'municipalities':
          this.selectedMunicipalities = this.selectedMunicipalities.filter(
            skillId => {
              return removeValue.value !== skillId;
            }
          );
          break;
        default:
          break;
      }
    },

    search() {
      const setRequest = this.setSearchRequest();
      if (!setRequest) return;
      if (!Object.keys(setRequest).length) {
        this.resetSearch();
      } else {
        this.$router
          .push({ name: 'Projects', query: setRequest })
          .catch(() => {});
      }
      window.scrollTo(0, 0);
    },

    resetSearch() {
      this.$router.push({ path: '/projects' }).catch(() => {});
    },

    changePage(val) {
      let setRequest = this.setSearchRequest();
      if (!setRequest) return;
      setRequest['page'] = val;
      this.$router.push({ name: 'Projects', query: setRequest });
      window.scrollTo(0, 0);
    },

    changeBookmark(projectId, isBookmark) {
      if (!this.isLogin()) {
        this.modal.show = true;
        return;
      }
      if (isBookmark) {
        this.deleteBookmark(projectId);
      } else {
        this.createBookmark(projectId);
      }
    },

    async deleteBookmark(projectId) {
      const bookmarkId = this.bookmarks
        .find(element => element.projectId === projectId)
        .bookmarkId.toString();
      try {
        await this.$axios.delete('/api/v1/bookmarks/' + bookmarkId, {
          headers: session.apiAuthHeaders(),
        });
        const result = this.bookmarks.filter(
          element => element.projectId !== projectId
        );
        this.bookmarks = result;
        this.$emit('showSnackbar', 'success', 'ブックマークが削除されました。');
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit(
            'showSnackbar',
            'error',
            '指定したブックマークは既に削除されています。'
          );
          this.getBookmarks(); // 最新の状態にするために取得し直し
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async createBookmark(projectId) {
      try {
        await this.$axios.post(
          '/api/v1/bookmarks/',
          { bookmark: { project_id: projectId } },
          { headers: session.apiAuthHeaders() }
        );
        this.getBookmarks(); // bookmarkのidが取得できないためgetBookmarkを叩いて対応
        this.$emit('showSnackbar', 'success', '登録に成功しました。');
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          // 下記の処理でブックマークしている際は最新の状態にするためにブックマークを取得し直す
          if (error_message === '既にブックマークをしています。')
            this.getBookmarks();
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async getEntries() {
      if (!this.isLogin()) return;
      try {
        const result = await this.$axios.get('/api/v1/entries', {
          headers: session.apiAuthHeaders(),
        });
        const pushEntries = result.data.map(element => {
          return { entryId: element.id, projectId: element.project.id };
        });
        this.entries = pushEntries;
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    updateMinCostQuery(e) {
      this.minCostQuery = e;
    },

    updateMaxCostQuery(e) {
      this.maxCostQuery = e;
    },

    changeSkills(value) {
      this.selectedSkills = value;
    },

    changeMunicipalities(value) {
      this.selectedMunicipalities = value;
    },

    changeWorkLocations(value) {
      this.selectedWorkLocations = value;
    },

    changeBtnDisable(boolean) {
      this.btnDisable = boolean;
    },

    setSkillQuery(nextRoute) {
      const route = nextRoute ? nextRoute : this.$route;
      switch (route.name) {
        case 'JavaScriptProjects':
          this.$route.query['skills[]'] = this.skillNumbers.javascript;
          break;
        case 'JavaProjects':
          this.$route.query['skills[]'] = this.skillNumbers.java;
          break;
        case 'PhpProjects':
          this.$route.query['skills[]'] = this.skillNumbers.php;
          break;
        case 'PythonProjects':
          this.$route.query['skills[]'] = this.skillNumbers.python;
          break;
        case 'RubyProjects':
          this.$route.query['skills[]'] = this.skillNumbers.ruby;
          break;
        case 'RailsProjects':
          this.$route.query['skills[]'] = this.skillNumbers.rails;
          break;
      }
    },

    removeSearchQuery() {
      const searchQuery = [
        'order',
        'free_words[]',
        'work_locations[]',
        'municipalities[]',
        'skills[]',
        'max_cost',
        'min_cost',
      ];
      Object.keys(this.$route.query).map(key => {
        if (searchQuery.includes(key)) {
          delete this.$route.query[key];
        }
      });
    },
  },
};
</script>
