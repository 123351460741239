<template>
  <v-row>
    <v-col cols="12" align="center" :style="heightValue">
      <div class="d-flex justify-center align-center" style="height: 100%">
        <v-progress-circular
          :width="widthValue"
          :size="sizeValue"
          :color="colorValue"
          indeterminate
        />
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    width: Number,
    size: String,
    color: String,
    height: Number,
  },
  computed: {
    widthValue() {
      return this.width ? this.width : 3;
    },

    sizeValue() {
      return this.size ? this.size : '32';
    },

    colorValue() {
      return this.color ? this.color : 'primary';
    },

    heightValue() {
      const h = this.height ? this.height : 50;
      return `height: ${h}vh; `;
    },
  },
};
</script>
