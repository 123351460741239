import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.lightBlue.darken3, // #0277BD
        accent: colors.red, // #F44336
        secondary: '#ef6c00',
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: 'sm', // This is equivalent to a value of 960
  },
  icons: {
    iconfont: 'mdi',
  },
});
