<template>
  <v-card flat class="px-5 pb-3 text-center">
    <div class="d-flex justify-center">
      <div>
        <v-img width="190" src="@/assets/img/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center">
      <h3>ログイン</h3>
    </v-card-title>
    <v-form v-model="btnDisable">
      <v-row class="my-1 px-2">
        <v-col cols="12" class="py-0" align="left">
          <h5>メールアドレス</h5>
        </v-col>
        <v-col cols="12" class="pt-0" align="center">
          <v-text-field
            color="primary"
            placeholder="メールアドレス"
            hide-details="auto"
            outlined
            dense
            :value="emailInitialValue"
            @input="updateEmail"
            :rules="rules.email"
            data-cy="login-form-email-form"
          />
        </v-col>
        <v-col cols="12" class="py-0" align="left">
          <h5>パスワード</h5>
        </v-col>
        <v-col cols="12" class="py-0" align="center">
          <v-text-field
            color="primary"
            placeholder="パスワード"
            :type="passwordType"
            hide-details="auto"
            outlined
            dense
            :value="passwordInitialValue"
            @input="updatePassword"
            :rules="rules.password"
            data-cy="login-form-password-form"
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="passwordCheckbox"
              label="パスワードを表示する"
              dense
              class="font-weight-regular"
              color="primary"
              hide-details="auto"
              data-cy="login-form-show-password-checkbox"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          align="right"
          class="px-0 pb-0 pt-1"
          v-if="isConfirmationEmail"
        >
          <v-btn
            data-cy="login-form-confirmation-email-link"
            text
            color="primary"
            class="font-weight-bold"
            v-text="'メール認証がお済みでない方はこちら'"
            @click="$router.push({ path: '/confirmation-email' })"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-row>
        <v-col cols="12">
          <v-btn
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            block
            height="35px"
            @click="login"
            data-cy="login-form-login-btn"
            :loading="loading"
          >
            <v-icon
              class="mail-icon-position"
              dark
              size="25"
              v-text="'mdi-email-outline'"
            />
            ログイン
          </v-btn>
        </v-col>
        <!-- TODO: googleのSSO実装 -->
        <!-- <v-col cols="12">
          <v-btn
            :href="googleAuthUrl()"
            elevation="0"
            block
            outlined
            color="primary"
          >
            <v-img src="@/assets/img/google.svg" class="google-icon-position" />
            Googleでログイン
          </v-btn>
        </v-col> -->
      </v-row>
    </v-card-actions>
    <v-card-actions class="mt-3 pa-0 justify-space-between">
      <v-btn
        text
        color="primary"
        class="font-weight-bold"
        @click="$router.push({ path: '/resetpassword' })"
        v-text="'パスワードをお忘れですか？'"
        data-cy="login-form-resetpassword-btn"
      />
      <v-btn
        text
        color="primary"
        class="font-weight-bold"
        @click="$router.push({ path: '/registeruser' })"
        v-text="'新規登録へ'"
        data-cy="login-form-registeruser-btn"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'LoginFormPc',
  props: ['value', 'isConfirmationEmail', 'loading'],
  data() {
    return {
      btnDisable: false,
      passwordCheckbox: false,
      rules: {
        email: [value => validate.userEmail(value)],
        password: [value => validate.userPassword(value)],
      },
    };
  },
  computed: {
    passwordType() {
      return this.passwordCheckbox ? 'text' : 'password';
    },

    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },

    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },
  },
  methods: {
    // googleAuthUrl() {
    //   return process.env.VUE_APP_GOOGLE_AUTH_URL;
    // },

    login() {
      this.$emit('login', !this.btnDisable);
    },

    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },

    updatePassword(event_password) {
      const newValue = {
        ...this.value,
        password: event_password,
      };
      this.$emit('input', newValue);
    },

    routerPush(path) {
      const url = path;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}

/* .google-icon-position {
  position: absolute;
  left: -12px;
  bottom: -12px;
} */
</style>
