<template>
  <v-card-text>
    <v-row>
      <!-- 基本情報 -->
      <v-col cols="9">
        <h2 v-text="'基本情報'" data-cy="entry-step1-section-title" />
      </v-col>
      <v-col cols="3" class="pt-1" align="right">
        <v-btn depressed text @click="openAccountDialog">
          <span>
            <v-icon color="primary" v-text="'mdi-pencil'" />
          </span>
          <div
            class="primary--text"
            v-text="'編集'"
            data-cy="entry-step1-open-dialog-btn"
          />
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!-- 姓カナ・名カナ -->
      <v-col cols="2">
        <div class="pl-4">
          <h5 data-cy="entry-step1-col-name">
            【名前】<span style="color: red">※</span>
          </h5>
        </div>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="nameInitialValue"
          data-cy="entry-step1-data-name"
        />
      </v-col>
      <v-col cols="2">
        <h5 data-cy="entry-step1-col-kana">
          【カナ】<span style="color: red">※</span>
        </h5>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="nameKanaInitialValue"
          data-cy="entry-step1-data-kana"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- メールアドレス・電話番号 -->
      <v-col cols="2">
        <div class="pl-4">
          <h5 data-cy="entry-step1-col-email">
            【メールアドレス】<span style="color: red">※</span>
          </h5>
        </div>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="emailInitialValue"
          data-cy="entry-step1-data-email"
        />
      </v-col>
      <v-col cols="2">
        <h5 data-cy="entry-step1-col-tel">
          【電話番号】<span style="color: red">※</span>
        </h5>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="telInitialValue"
          data-cy="entry-step1-data-tel"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 生年月日・性別 -->
      <v-col cols="2">
        <div class="pl-4">
          <h5 data-cy="entry-step1-col-birth">
            【生年月日】<span style="color: red">※</span>
          </h5>
        </div>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="birthInitialValue"
          data-cy="entry-step1-data-birth"
        />
      </v-col>
      <v-col cols="2">
        <h5 data-cy="entry-step1-col-gender">
          【性別】<span style="color: red">※</span>
        </h5>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="genderInitialValue"
          data-cy="entry-step1-data-gender"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 現在の状況・住所（市区町村） -->
      <v-col cols="2">
        <div class="pl-4">
          <h5 data-cy="entry-step1-col-status">
            【現在の状況】<span style="color: red">※</span>
          </h5>
        </div>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="currentStatusInitialValue"
          data-cy="entry-step1-data-status"
        />
      </v-col>
      <v-col cols="2">
        <h5 data-cy="entry-step1-col-area">
          【住所（市区町村）】<span style="color: red">※</span>
        </h5>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="liveAreaInitialValue"
          data-cy="entry-step1-data-area"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 最寄り駅 -->
      <v-col cols="2">
        <div class="pl-4">
          <h5 data-cy="entry-step1-col-station">
            【最寄り駅】<span style="color: red">※</span>
          </h5>
        </div>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="closestStationInitialValue"
          data-cy="entry-step1-data-station"
        />
      </v-col>
      <!-- 国籍 -->
      <v-col cols="2">
        <h5 data-cy="entry-step1-col-country">
          【国籍】<span style="color: red">※</span>
        </h5>
      </v-col>
      <v-col cols="4">
        <h3
          class="d-flex justify-center"
          v-text="nationalityInitialValue"
          data-cy="entry-step1-data-country"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 特記事項 -->
      <v-col cols="2" class="pb-2">
        <div class="pl-4">
          <h5 data-cy="entry-step1-col-note">【特記事項】（任意）</h5>
        </div>
      </v-col>
      <v-col cols="10">
        <h3
          style="white-space: pre-line"
          v-text="noteInitialValue"
          data-cy="entry-step1-data-note"
        />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import mixinCurrentStatuses from '../../../const/user/currentStatuses';
import mixinGenders from '../../../const/user/genders';
import dayjs from 'dayjs';

export default {
  name: 'EntryUserDetailsPc',
  props: ['currentUserData', 'skillSheet', 'project'],
  mixins: [mixinCurrentStatuses, mixinGenders],
  computed: {
    nameInitialValue() {
      if (this.currentUserData == null) {
        return '未登録';
      } else {
        const name =
          this.currentUserData.last_name == null &&
          this.currentUserData.first_name == null
            ? '未登録'
            : this.currentUserData.last_name +
              ' ' +
              this.currentUserData.first_name;
        return name;
      }
    },

    nameKanaInitialValue() {
      if (this.currentUserData == null) {
        return '未登録';
      } else {
        const nameKana =
          this.currentUserData.last_name_kana == null &&
          this.currentUserData.first_name_kana == null
            ? '未登録'
            : this.currentUserData.last_name_kana +
              ' ' +
              this.currentUserData.first_name_kana;
        return nameKana;
      }
    },

    emailInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.email != null
        ? this.currentUserData.email
        : '未登録';
    },

    telInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.tel != null && this.currentUserData.tel != ''
        ? this.currentUserData.tel
        : '未登録';
    },

    genderInitialValue() {
      if (this.currentUserData != null) {
        const target = this.genders.find(
          element => element.id === this.currentUserData.gender
        );
        return target != null ? target.value : '未登録';
      } else {
        return '未登録';
      }
    },

    birthInitialValue() {
      if (this.currentUserData && this.currentUserData.birth) {
        return dayjs(this.currentUserData.birth).format('YYYY-MM-DD');
      } else {
        return '未登録';
      }
    },

    liveAreaInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.live_area != null &&
        this.currentUserData.live_area != ''
        ? this.currentUserData.live_area
        : '未登録';
    },

    currentStatusInitialValue() {
      if (this.currentUserData != null) {
        const target = this.currentStatuses.find(
          element => element.id === this.currentUserData.current_status
        );
        return target != null ? target.value : '未登録';
      } else {
        return '未登録';
      }
    },

    closestStationInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.closest_station != null &&
        this.currentUserData.closest_station != ''
        ? `${this.currentUserData.closest_station}駅`
        : '未登録';
    },

    nationalityInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.nationality != null &&
        this.currentUserData.nationality != ''
        ? this.currentUserData.nationality
        : '未登録';
    },

    noteInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.note != null &&
        this.currentUserData.note != ''
        ? this.currentUserData.note
        : '未登録';
    },
  },
  methods: {
    openAccountDialog() {
      this.$emit('openAccountDialog');
    },

    closeAccountDialog() {
      this.$emit('closeAccountDialog');
    },

    nextPage() {
      this.$emit('nextPage');
    },
  },
};
</script>
