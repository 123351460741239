<template>
  <v-card>
    <v-card-title class="text-h5 font-weight-bold">
      <v-row>
        <v-col cols="10"> スキルを選択 </v-col>
        <v-col cols="2" align="right">
          <v-icon v-text="'mdi-close'" @click="close" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container class="px-3">
        <v-tabs vertical slider-color="white">
          <v-tab
            v-for="item in sideHeaders"
            :key="item.value"
            class="font-weight-bold"
            v-text="item.text"
          />
          <v-tab-item class="pl-6" style="height: 480px">
            <v-card
              outlined
              elevation="0"
              class="pt-1 pb-2 px-3 d-flex align-content-start flex-wrap"
            >
              <v-checkbox
                v-for="(item, index) in adjustSkills(0)"
                :key="index"
                dense
                v-model="selectedSkillsAsDialog"
                :value="item.number"
                :label="item.name"
                hide-details
                class="mr-10 mb-2"
                color="primary"
                style="width: 180px"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item class="pl-6" style="height: 480px">
            <v-card
              outlined
              elevation="0"
              class="pt-1 pb-2 px-3 d-flex align-content-start flex-wrap"
            >
              <v-checkbox
                v-for="(item, index) in adjustSkills(1)"
                :key="index"
                dense
                v-model="selectedSkillsAsDialog"
                :value="item.number"
                :label="item.name"
                hide-details
                class="mr-10 mb-2"
                color="primary"
                style="width: 180px"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item class="pl-6" style="height: 480px">
            <v-card
              outlined
              elevation="0"
              class="pt-1 pb-2 px-3 d-flex align-content-start flex-wrap"
            >
              <v-checkbox
                v-for="(item, index) in adjustSkills(2)"
                :key="index"
                dense
                v-model="selectedSkillsAsDialog"
                :value="item.number"
                :label="item.name"
                hide-details
                class="mr-10 mb-2"
                color="primary"
                style="width: 180px"
              />
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-space-between pb-5 px-6">
      <v-btn
        elevation="0"
        outlined
        color="primary"
        @click="reset"
        width="40%"
        class="font-weight-bold"
        v-text="'クリア'"
      />
      <v-btn
        elevation="0"
        color="primary white--text"
        @click="changeCheckBox"
        width="40%"
        class="font-weight-bold"
        v-text="'選択を適用する'"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SkillsDialog',
  props: ['skills', 'selectedSkills'],
  data() {
    return {
      selectedSkillsAsDialog: [],
      displaySkill: 0,
      sideHeaders: [
        { value: 0, text: '言語' },
        { value: 1, text: 'フレームワーク' },
        { value: 2, text: 'その他' },
      ],
    };
  },
  created() {
    this.setSelectValue();
  },
  watch: {
    // selectedSkills(親のdata)を監視して
    // 変更時にselectedSkillsAsDialog(子のdata)に代入
    selectedSkills() {
      this.selectedSkillsAsDialog = this.selectedSkills;
    },
  },
  methods: {
    setSelectValue() {
      this.selectedSkillsAsDialog = this.selectedSkills;
    },

    adjustSkills(category) {
      return this.skills.filter(skill => {
        if (skill.category == category) return true;
      });
    },

    close() {
      this.$emit('close');
    },

    reset() {
      this.selectedSkillsAsDialog = [];
    },

    changeCheckBox() {
      this.$emit('changeSkills', this.selectedSkillsAsDialog);
      this.$emit('close');
    },
  },
};
</script>
