<template>
  <v-dialog
    v-model="searchDialog"
    scrollable
    persistent
    @click:outside="setDialog(false)"
  >
    <v-card flat class="py-1">
      <v-card-title class="pt-1 pb-2">
        <v-row class="py-2">
          <v-col cols="2" class="pl-0 py-0" align="left">
            <v-icon class="pt-2" @click="setDialog(false)">mdi-close</v-icon>
          </v-col>
          <v-col cols="8" class="py-0" align="center">
            <div class="font-weight-bold pt-2" style="font-size: 14px">
              検索条件
            </div>
          </v-col>
          <v-col cols="2" class="pl-0 py-0" align="right" />
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-1 pb-0 px-4">
        <v-responsive>
          <div class="pt-1">
            <TermsAppliedSearch
              :searchWordsArray="searchWordsArray"
              @resetSearch="resetSearch"
            />
          </div>
          <v-row class="pt-1">
            <!-- 検索：フリーワード -->
            <v-col cols="12" class="pb-0">
              <h4 class="black--text">フリーワード検索</h4>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                color="primary"
                placeholder="Ruby リモート"
                :value="outputFreeWord"
                @input="inputFreeWord"
              />
            </v-col>
          </v-row>
          <v-row class="px-2 pb-0">
            <!-- 検索：勤務形態 -->
            <v-col cols="12" class="pb-0 pl-1" align="left">
              <h4 class="black--text">勤務形態</h4>
            </v-col>
            <v-col cols="12" class="py-0 pl-1">
              <v-checkbox
                v-for="(item, index) in workLocations"
                :key="index"
                v-model="selectWorkLocations"
                @change="changeWorkLocations"
                :value="item.id"
                :label="item.value"
                class="py-0"
                hide-details="auto"
                color="primary"
                dense
              />
            </v-col>
          </v-row>
          <v-form :value="btnDisable" @input="changeBtnDisable">
            <!-- 検索：単価 -->
            <v-row class="px-2 pb-0 pt-2">
              <v-col cols="12" class="pb-0 pl-1" align="left">
                <h4 class="black--text">単価</h4>
              </v-col>
              <v-col cols="5" class="py-0 pl-1">
                <v-text-field
                  dense
                  placeholder="50"
                  suffix="万円"
                  color="primary"
                  :value="minCostQuery"
                  @input="updateMinCostQuery"
                  :rules="rules.minCost"
                />
              </v-col>
              <v-col cols="2" class="py-0" align="center">
                <h5 class="pt-3">〜</h5>
              </v-col>
              <v-col cols="5" class="py-0 pr-1">
                <v-text-field
                  dense
                  placeholder="70"
                  suffix="万円"
                  color="primary"
                  :value="maxCostQuery"
                  @input="updateMaxCostQuery"
                  :rules="rules.maxCost"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row class="px-2">
            <!-- 検索：スキル -->
            <SkillsCheckBox
              :skills="skills"
              :selectedSkills="selectedSkills"
              @changeSkills="changeSkills"
            />
          </v-row>
          <v-row class="px-2">
            <!-- 検索：勤務地 -->
            <MunicipalitiesCheckBox
              :selectedMunicipalities="selectedMunicipalities"
              @changeMunicipalities="changeMunicipalities"
            />
          </v-row>
        </v-responsive>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="py-1">
          <v-col cols="6" class="pr-1" align="left">
            <v-btn
              color="primary white--text"
              outlined
              class="font-weight-bold"
              width="80%"
              height="30"
              style="font-size: 13px"
              elevation="0"
              @click="setDialog(false)"
              v-text="'閉じる'"
            />
          </v-col>
          <v-col cols="6" class="pl-1" align="right">
            <v-btn
              color="primary white--text"
              class="font-weight-bold"
              width="80%"
              @click="search"
              style="font-size: 13px"
              height="30"
              elevation="0"
              :disabled="isInvalid"
              v-text="'検索する'"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TermsAppliedSearch from './TermsAppliedSearch.vue';
import SkillsCheckBox from './SkillsCheckBox.vue';
import MunicipalitiesCheckBox from './MunicipalitiesCheckBox.vue';
import mixinWorkLocation from '../../../const/project/workLocation';
import validate from '../../../plugins/validate';

export default {
  name: 'ProjectsSearchDialogSp',
  props: [
    'searchWordsArray',
    'outputFreeWord',
    'minCostQuery',
    'maxCostQuery',
    'skills',
    'selectedSkills',
    'selectedMunicipalities',
    'btnDisable',
    'searchDialog',
    'selectedWorkLocations',
  ],
  components: {
    TermsAppliedSearch,
    SkillsCheckBox,
    MunicipalitiesCheckBox,
  },
  mixins: [mixinWorkLocation],
  data() {
    return {
      selectWorkLocations: [],
      rules: {
        maxCost: [value => validate.projectCost(value)],
        minCost: [value => validate.projectCost(value)],
      },
    };
  },
  created() {
    // リロード＆ブラウザバック時の対応
    // created時にselectedWorkLocations(親のdata)をselectWorkLocations(子のdata)に代入
    this.selectWorkLocations = this.selectedWorkLocations;
  },
  watch: {
    // selectedWorkLocations(親のdata)を監視して
    // 変更時にselectWorkLocations(子のdata)に代入
    selectedWorkLocations() {
      this.selectWorkLocations = this.selectedWorkLocations;
    },
  },
  computed: {
    isInvalid() {
      if (!this.btnDisable) return true;
      // どちらか一方だけが入力されている場合はtrueを返す
      return !this.minCostQuery != !this.maxCostQuery;
    },
  },
  methods: {
    resetSearch() {
      this.$emit('resetSearch');
    },

    search() {
      this.$emit('search');
    },

    inputFreeWord(e) {
      this.$emit('inputFreeWord', e);
    },

    updateMaxCostQuery(e) {
      this.$emit('updateMaxCostQuery', e);
    },

    updateMinCostQuery(e) {
      this.$emit('updateMinCostQuery', e);
    },

    setDialog(type) {
      this.$emit('setDialog', type);
    },

    changeBtnDisable(boolean) {
      this.$emit('changeBtnDisable', boolean);
    },

    changeWorkLocations() {
      this.$emit('changeWorkLocations', this.selectWorkLocations);
    },

    changeSkills(value) {
      this.$emit('changeSkills', value);
    },

    changeMunicipalities(value) {
      this.$emit('changeMunicipalities', value);
    },
  },
};
</script>
