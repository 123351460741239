<template>
  <div>
    <v-col cols="12" class="pb-0 pl-1" align="left">
      <h4 class="black--text">勤務地</h4>
    </v-col>
    <v-row class="mb-12">
      <v-col cols="12" class="pl-3 pb-0" :style="vColWidth">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5 class="pr-15">東京都23区</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustMunicipalities('tokyo')"
                  :key="index"
                  dense
                  v-model="tokyoValue"
                  @change="changeCheckBox(item.id)"
                  :value="item.id"
                  :label="item.value"
                  hide-details
                  class="mr-5"
                  color="primary"
                  style="width: 140px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5 class="pr-10">東京都のその他の市町村</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-1">
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustMunicipalities('tokyoCity')"
                  :key="index"
                  dense
                  v-model="tokyoCityValue"
                  @change="changeCheckBox(item.id)"
                  :value="item.id"
                  :label="item.value"
                  hide-details
                  class="mr-5"
                  color="primary"
                  style="width: 100px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5>神奈川県</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-1">
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustMunicipalities('kanagawa')"
                  :key="index"
                  dense
                  v-model="kanagawaValue"
                  @change="changeCheckBox(item.id)"
                  :value="item.id"
                  :label="item.value"
                  hide-details
                  class="mr-15"
                  color="primary"
                  style="width: 100px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5>埼玉県</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-1">
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustMunicipalities('saitama')"
                  :key="index"
                  dense
                  v-model="saitamaValue"
                  @change="changeCheckBox(item.id)"
                  :value="item.id"
                  :label="item.value"
                  hide-details
                  class="mr-5"
                  color="primary"
                  style="width: 130px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5>千葉県</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-1">
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustMunicipalities('chiba')"
                  :key="index"
                  dense
                  v-model="chibaValue"
                  @change="changeCheckBox(item.id)"
                  :value="item.id"
                  :label="item.value"
                  hide-details
                  class="mr-5"
                  color="primary"
                  style="width: 130px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixinMunicipalities from '../../../const/project/municipality';

export default {
  name: 'MunicipalitiesCheckBox',
  props: ['selectedMunicipalities'],
  mixins: [mixinMunicipalities],
  data() {
    return {
      tokyoValue: [],
      tokyoCityValue: [],
      kanagawaValue: [],
      saitamaValue: [],
      chibaValue: [],

      tokyoAllValue: -1,
      tokyoCityAllValue: -2,
      kanagawaAllValue: -3,
      saitamaAllValue: -4,
      chibaAllValue: -5,
    };
  },
  created() {
    // リロード＆ブラウザバック時の対応
    this.selectedMunicipalities.forEach(element => {
      if (this.isTokyo(element)) {
        this.tokyoValue.push(element);
        return;
      }
      if (this.isTokyoCity(element)) {
        this.tokyoCityValue.push(element);
        return;
      }
      if (this.isKanagawa(element)) {
        this.kanagawaValue.push(element);
        return;
      }
      if (this.isSaitama(element)) {
        this.saitamaValue.push(element);
        return;
      }
      if (this.isChiba(element)) {
        this.chibaValue.push(element);
        return;
      }
    });
  },
  watch: {
    // selectedMunicipalities(親のdata)を監視
    selectedMunicipalities() {
      if (this.selectedMunicipalities.length === 0) {
        this.tokyoValue = [];
        this.tokyoCityValue = [];
        this.kanagawaValue = [];
        this.saitamaValue = [];
        this.chibaValue = [];
        return;
      }
      this.selectedMunicipalities.forEach(element => {
        if (this.isTokyo(element)) {
          this.tokyoValue.push(element);
          return;
        }
        if (this.isTokyoCity(element)) {
          this.tokyoCityValue.push(element);
          return;
        }
        if (this.isKanagawa(element)) {
          this.kanagawaValue.push(element);
          return;
        }
        if (this.isSaitama(element)) {
          this.saitamaValue.push(element);
          return;
        }
        if (this.isChiba(element)) {
          this.chibaValue.push(element);
          return;
        }
      });
    },
  },
  computed: {
    vColWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'width: 335px';
        case 'sm':
          return 'width: 535px';
        default:
          return 'width: 335px';
      }
    },
  },
  methods: {
    adjustMunicipalities(area) {
      const cities = this.getCitiesByArea(area);
      switch (area) {
        case 'tokyo':
          cities.unshift({ id: this.tokyoAllValue, value: 'すべて' });
          break;
        case 'tokyoCity':
          cities.unshift({ id: this.tokyoCityAllValue, value: 'すべて' });
          break;
        case 'kanagawa':
          cities.unshift({ id: this.kanagawaAllValue, value: 'すべて' });
          break;
        case 'saitama':
          cities.unshift({ id: this.saitamaAllValue, value: 'すべて' });
          break;
        case 'chiba':
          cities.unshift({ id: this.chibaAllValue, value: 'すべて' });
          break;
        default:
          break;
      }
      return cities;
    },

    changeCheckBox(selectedId) {
      switch (selectedId) {
        case this.tokyoAllValue:
          if (this.tokyoValue.includes(this.tokyoAllValue)) {
            for (let i = 0; i < 23; i++) {
              this.tokyoValue.push(i);
            }
          } else {
            this.tokyoValue = [];
          }
          break;
        case this.tokyoCityAllValue:
          if (this.tokyoCityValue.includes(this.tokyoCityAllValue)) {
            for (let i = 23; i < 31; i++) {
              this.tokyoCityValue.push(i);
            }
          } else {
            this.tokyoCityValue = [];
          }
          break;

        case this.kanagawaAllValue:
          if (this.kanagawaValue.includes(this.kanagawaAllValue)) {
            this.kanagawaValue.push(31);
            this.kanagawaValue.push(34);
          } else {
            this.kanagawaValue = [];
          }
          break;
        case this.saitamaAllValue:
          if (this.saitamaValue.includes(this.saitamaAllValue)) {
            this.saitamaValue.push(32);
            this.saitamaValue.push(35);
          } else {
            this.saitamaValue = [];
          }
          break;
        case this.chibaAllValue:
          if (this.chibaValue.includes(this.chibaAllValue)) {
            this.chibaValue.push(33);
            this.chibaValue.push(36);
          } else {
            this.chibaValue = [];
          }
          break;
        default:
          break;
      }

      const allSelected = [
        ...this.tokyoValue,
        ...this.tokyoCityValue,
        ...this.kanagawaValue,
        ...this.saitamaValue,
        ...this.chibaValue,
      ];

      // 負の値を削除
      const positive = allSelected.filter(element => {
        return element >= 0;
      });
      // 重複削除して、親コンポーネントに渡す
      this.$emit('changeMunicipalities', [...new Set(positive)]);
    },
  },
};
</script>
