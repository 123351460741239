<template>
  <v-app>
    <v-app-bar
      app
      outlined
      elevate-on-scroll
      clipped-left
      color="white"
      v-if="isHeader"
    >
      <v-responsive max-width="1161" class="mx-auto">
        <div class="d-flex align-center" v-if="!$vuetify.breakpoint.mobile">
          <router-link to="/" class="router-link">
            <v-btn text @click="logoClicked" class="logo">
              <v-img
                :max-width="!$vuetify.breakpoint.mobile ? '150' : '120'"
                src="@/assets/img/logo.png"
              />
            </v-btn>
          </router-link>
          <div v-if="!$vuetify.breakpoint.mobile">
            <router-link
              v-for="(header, index) in menuItems"
              :key="index"
              :to="header.path"
              class="router-link"
            >
              <v-btn depressed plain color="grey darken-4" class="mr-2">
                {{ header.title }}
              </v-btn>
            </router-link>
          </div>
          <v-spacer />
          <HeaderMenu
            :isLogin="isLogin"
            :user="user"
            @getMyProfile="getMyProfile"
            @logoutAction="logoutAction"
          />
        </div>
        <div v-else>
          <v-row>
            <v-col cols="6" align="left">
              <router-link to="/" class="router-link">
                <v-btn text @click="logoClicked" class="logo px-0 pt-2">
                  <v-img :max-width="'135'" src="@/assets/img/logo.png" />
                </v-btn>
              </router-link>
            </v-col>
            <v-col cols="6" class="px-0">
              <div class="d-flex justify-end">
                <router-link to="/registeruser" class="router-link">
                  <v-card :flat="true" v-if="!getLoginStatus()">
                    <v-card-text
                      class="py-0 pl-0 pr-4 d-flex flex-column justify-center"
                    >
                      <v-icon dense class="text-center" color="black">
                        mdi-account-plus-outline
                      </v-icon>
                      <div
                        class="black--text text-center text-caption"
                        style="white-space: nowrap"
                      >
                        新規登録
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>
                <router-link to="/login" class="router-link">
                  <v-card :flat="true" v-if="!getLoginStatus()">
                    <v-card-text
                      class="pl-0 pr-1 py-0 d-flex flex-column justify-center"
                    >
                      <v-icon dense class="text-center" color="black">
                        mdi-login
                      </v-icon>
                      <div
                        class="black--text text-center text-caption"
                        style="white-space: nowrap"
                      >
                        ログイン
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>
                <HeaderMenu
                  class="px-0"
                  :isLogin="isLogin"
                  :user="user"
                  @getMyProfile="getMyProfile"
                  @logoutAction="logoutAction"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-responsive>
    </v-app-bar>

    <v-main :class="mainPadding">
      <v-snackbar
        data-cy="app-snackbar"
        v-model="snackbar.show"
        top
        :color="snackbar.color"
        :timeout="3500"
        style="white-space: pre-line"
      >
        {{ snackbar.responseText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            閉じる
          </v-btn>
        </template>
      </v-snackbar>
      <router-view
        v-if="isLogin !== null"
        @showSnackbar="showSnackbar"
        @login="login"
        @logout="logout"
        @getMyProfile="getMyProfile"
        :isLogin="getLoginStatus"
      />
    </v-main>

    <v-divider />

    <MainFooter v-if="isFooter" />
  </v-app>
</template>

<style scoped>
.router-link {
  text-decoration: none;
}
</style>

<script>
import session from './plugins/session';
import HeaderMenu from './components/common/HeaderMenu.vue';
import MainFooter from './components/common/MainFooter.vue';

export default {
  name: 'App',
  components: {
    HeaderMenu,
    MainFooter,
  },
  data() {
    return {
      menuItems: [
        {
          path: '/projects',
          title: '案件検索',
          icon: 'mdi-file-document-multiple-outline',
        },
        { path: '/help', title: 'ヘルプ', icon: 'mdi-book-open' },
        { path: '/terms', title: '利用規約', icon: 'mdi-book-open-outline' },
        {
          path: '/privacy',
          title: 'プライバシーポリシー',
          icon: 'mdi-book-open',
        },
        {
          path: '/contact',
          title: 'お問い合わせ',
          icon: 'mdi-book-open',
        },
      ],
      snackbar: {
        show: false,
        color: null,
        responseText: null,
      },
      isLogin: session.isAuthenticated(),
      user: null,
    };
  },

  created() {
    this.routerGuard();
    this.$router.beforeEach((to, from, next) => {
      const requiresAuth = to.meta.requiresAuth;
      this.isLogin = session.isAuthenticated();
      let toPath = to.path;

      let isRedirect = true;
      if (requiresAuth && !this.isLogin) {
        toPath = '/login';
      } else if (
        (this.isLogin && from.path === '/' && to.path === '/login') ||
        (['Login', 'RegisterUser'].includes(to.name) && this.isLogin)
      ) {
        toPath = '/';
      } else {
        isRedirect = false;
      }

      let isTakeover =
        from.query.utm_source &&
        !to.query.utm_source &&
        from.query.utm_medium &&
        !to.query.utm_medium &&
        from.query.utm_campaign &&
        !to.query.utm_campaign &&
        from.query.utm_content &&
        !to.query.utm_content;
      if (isTakeover) {
        to.query.utm_source = from.query.utm_source;
        to.query.utm_medium = from.query.utm_medium;
        to.query.utm_campaign = from.query.utm_campaign;
        to.query.utm_content = from.query.utm_content;
      }

      if (isRedirect || isTakeover) {
        if (toPath === '/' || toPath === '/login') {
          next({ path: toPath, query: { ...to.query } });
        } else {
          const query = { ...to.query };
          const path = `${toPath}?${Object.entries(query)
            .map(([key, val]) => `${key}=${val}`)
            .join('&')}`;
          const fullPath = `${path}${to.hash || ''}`;
          next({ ...to, fullPath });
        }
      } else {
        this.getMyProfile();
        next();
      }
    });
  },
  mounted() {
    this.setPageMeta(this.$route);
  },
  computed: {
    displayAsPc() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'sp';
        case 'sm':
          return 'sp';
        case 'md':
          return 'pc';
        case 'lg':
          return 'pc';
        case 'xl':
          return 'pc';
        default:
          return 'pc';
      }
    },

    isHeader() {
      switch (this.$route.name) {
        case 'RegisterUser':
          return false;
        case 'Login':
          return false;
        case 'ConfirmationEmail':
          return false;
        case 'ResetPassword':
          return false;
        case 'UpdatePassword':
          return false;
        default:
          return true;
      }
    },

    isFooter() {
      switch (this.$route.name) {
        case 'RegisterUser':
          return false;
        case 'Login':
          return false;
        case 'ConfirmationEmail':
          return false;
        case 'ResetPassword':
          return false;
        case 'UpdatePassword':
          return false;
        default:
          return true;
      }
    },

    mainPadding() {
      return this.$route.name === 'Home' ? '' : 'pb-4';
    },
  },
  watch: {
    $route(routeInstance) {
      this.setPageMeta(routeInstance);
    },
  },
  methods: {
    // route.beforeEachでも同じようなコードがあるが、
    // こちらは直リンク初回アクセス時の対応
    routerGuard() {
      const requiresAuth = this.$route.meta.requiresAuth;
      this.isLogin = session.isAuthenticated();

      if (!this.isLogin && requiresAuth) {
        this.$router.push({ name: 'Login' }).catch(() => {});
      } else if (
        this.isLogin &&
        ['Login', 'RegisterUser'].includes(this.$route.name)
      ) {
        this.$router.push({ name: 'Home' }).catch(() => {});
      }
    },

    setPageMeta(routeInstance) {
      // title
      const suffix = ' | qualiteA';
      if (routeInstance.meta.title) {
        document.title = routeInstance.meta.title + suffix;
      } else {
        document.title =
          'フリーランスエンジニアのためのIT案件紹介サイト' + suffix;
      }
      // discription
      if (routeInstance.meta.description) {
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', routeInstance.meta.description);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', '');
      }
    },

    showSnackbar(color, text) {
      this.snackbar.color = color;
      this.snackbar.responseText = text;
      this.snackbar.show = true;
    },

    logoutAction() {
      this.logout();
      this.drawer = false;
      this.showSnackbar('success', 'ログアウトに成功しました。');
    },

    login(headers) {
      session.setItemKeys(headers);
      this.isLogin = true;
    },

    googleLogin(headers) {
      session.setGoogleItemKeys(headers);
      this.isLogin = true;
    },

    logout() {
      session.removeItemKeys();
      this.isLogin = false;
      this.user = null;
    },

    async getMyProfile() {
      if (!this.getLoginStatus()) return;

      try {
        const result = await this.$axios.get('/api/v1/users/mypage', {
          headers: session.apiAuthHeaders(),
        });
        this.user = result.data;
      } catch (err) {
        session.removeItemKeys();
        this.user = null;
      }
    },

    logoClicked() {
      if (this.$route.name !== 'Home') return;

      // 開いているページがホーム画面の際はリロード&画面の一番上にスクロールさせる
      location.reload();
      window.scrollTo(0, 0);
    },

    // NOTO: localstorage保存のセッション情報では、computedのように検知ができない。
    // 大抵はbeforeEachでカバーできるが、router-viewに渡す値では、router.push()する前に
    // 情報が知りたいため、苦肉の策としてメソッドで提供する。
    getLoginStatus() {
      this.isLogin = session.isAuthenticated();
      if (!this.isLogin) this.user = null;
      return this.isLogin;
    },
  },
};
</script>
