<template>
  <v-container class="home-container px-0">
    <section>
      <v-row align="center">
        <v-img
          class="mt-6 mx-auto"
          :width="topIconWidth"
          :min-height="$vuetify.breakpoint.mobile ? '200' : '400'"
          max-width="800"
          :src="topIcon"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5" />
            </v-row>
          </template>
        </v-img>
      </v-row>
      <div
        class="mt-5 py-8 text-center font-weight-bold primary white--text"
        style="height: 384px"
      >
        <div class="prompt-login-btn-label mb-3">
          ＼ 30秒で簡単無料登録！ ／
        </div>
        <router-link
          :to="isLogin() ? '/mypage' : '/registeruser'"
          class="router-link"
        >
          <v-btn elevation="0" text rounded class="btn btn-register py-3 px-16">
            無料登録はこちら
          </v-btn>
        </router-link>
        <div class="d-flex justify-center flex-wrap pa-2" style="gap: 6px">
          <v-img
            width="calc(33.3% - 4px)"
            class="mt-6 d-block rounded-lg"
            src="@/assets/img/money.webp"
            style="max-width: 200px"
          />
          <v-img
            width="calc(33.3% - 4px)"
            class="mt-6 d-block rounded-lg"
            src="@/assets/img/remote.webp"
            style="max-width: 200px"
          />
          <v-img
            width="calc(33.3% - 4px)"
            class="mt-6 d-block rounded-lg"
            src="@/assets/img/job.webp"
            style="max-width: 200px"
          />
        </div>
      </div>
    </section>

    <v-sheet>
      <section class="pt-5" style="padding-bottom: 96px">
        <v-responsive max-width="1161" class="mx-auto">
          <v-container fluid>
            <div class="d-flex justify-center">
              <div :class="sectionTitle" class="fade-in" v-text="'新着案件'" />
            </div>
            <v-row v-if="loading">
              <v-col cols="12" align="center" style="height: 50vh">
                <div
                  class="d-flex justify-center align-center"
                  style="height: 100%"
                >
                  <v-progress-circular
                    :width="3"
                    size="32"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-col>
            </v-row>
            <v-carousel v-else hide-delimiters height="">
              <template v-slot:prev="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  min-height="48px"
                  min-width="48px"
                  color="primary"
                >
                  <v-icon v-text="'mdi-chevron-left'" />
                </v-btn>
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  min-height="48px"
                  min-width="48px"
                  color="primary"
                >
                  <v-icon v-text="'mdi-chevron-right'" />
                </v-btn>
              </template>

              <template v-if="$vuetify.breakpoint.mobile">
                <v-carousel-item
                  v-for="(items, i) in projects"
                  :key="i"
                  class="px-4 py-8 pa-sm-12"
                >
                  <v-container class="pa-4">
                    <v-row justify="center">
                      <v-col cols="12" justify-content="center">
                        <ProjectOverviewSp :project="items" class="mx-auto" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-carousel-item>
              </template>

              <template v-else>
                <v-carousel-item
                  v-for="(items, i) in splitProjects"
                  :key="i"
                  class="pa-12"
                >
                  <v-container class="pa-4">
                    <v-row justify="center">
                      <v-col
                        cols="6"
                        v-for="(item, j) in items"
                        :key="j"
                        justify-content="center"
                      >
                        <ProjectOverviewPc :project="item" class="mx-auto" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-carousel-item>
              </template>
            </v-carousel>

            <div class="text-center">
              <router-link to="/projects" class="router-link">
                <v-btn
                  x-large
                  rounded
                  width="220"
                  elevation="0"
                  class="font-weight-bold fade-in btn"
                  color="primary"
                  v-text="'すべての案件を見る'"
                  data-cy="home-projects-overview-btn"
                />
              </router-link>
            </div>
          </v-container>
        </v-responsive>
      </section>
    </v-sheet>

    <v-sheet>
      <section class="py-10 primary">
        <v-responsive max-width="1161" class="mx-auto">
          <div class="d-flex justify-center">
            <div
              :class="sectionTitle"
              class="fade-in white--text"
              v-text="'qualitéAの特徴'"
            />
          </div>
          <v-container fluid class="mt-3">
            <v-row class="pt-8" style="justify-content: center">
              <v-col
                cols="12"
                sm="6"
                v-for="(feature, index) in features"
                :key="index"
                class="pa-3 data-scroll fade-in"
                align="center"
              >
                <v-card class="fill-height rounded-lg" max-width="510">
                  <v-img :src="feature" width="100%" class="mx-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
            <div class="mt-10 py-8 text-center font-weight-bold white--text">
              <div class="prompt-login-btn-label mb-3">
                ＼ 30秒で簡単無料登録！ ／
              </div>
              <router-link
                :to="isLogin() ? '/mypage' : '/registeruser'"
                class="router-link"
              >
                <v-btn
                  elevation="0"
                  text
                  rounded
                  class="btn btn-register py-3 px-16"
                >
                  無料登録はこちら
                </v-btn>
              </router-link>
            </div>
          </v-container>
        </v-responsive>
      </section>
    </v-sheet>

    <v-sheet>
      <section style="padding-top: 96px">
        <v-responsive max-width="1161" class="mx-auto">
          <div class="d-flex justify-center fade-in">
            <div :class="sectionTitle" v-text="'応募までの流れ'" />
          </div>

          <v-container class="py-8 px-0">
            <v-sheet color="lighten-4" class="pa-8 rounded-lg">
              <v-row>
                <v-col
                  v-for="(plan, index) in beforeEntry"
                  :key="index"
                  cols="12"
                  md="4"
                  class="fade-in"
                >
                  <v-card
                    :min-height="cardMinHeight"
                    class="primary"
                    elevation="10"
                  >
                    <div class="py-5 white">
                      <v-img
                        :src="plan.icon"
                        class="d-block mx-auto"
                        width="100px"
                        height="100px"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            />
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <div
                      :class="sectionSubTitle"
                      class="text-h5 font-weight-bold white text-center py-2"
                    >
                      <span class="pr-2 primary--text">
                        Step
                        {{ index + 1 }}
                      </span>
                      {{ plan.title }}
                    </div>
                    <v-divider />
                    <div class="pa-6 primary" style="min-height: 165px">
                      <div
                        class="text-start py-2 white--text"
                        v-text="plan.description"
                        style="
                          white-space: pre-line;
                          font-size: 14px;
                          line-height: 24px;
                        "
                      />
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-container>
        </v-responsive>
      </section>
    </v-sheet>

    <v-sheet>
      <section>
        <v-responsive max-width="1161" class="mx-auto">
          <div class="d-flex justify-center fade-in">
            <div :class="sectionTitle" v-text="'参画までの流れ'" />
          </div>

          <v-container class="py-8 px-0">
            <v-sheet color="lighten-4" class="pa-8 rounded-lg">
              <v-row>
                <v-col
                  v-for="(plan, index) in afterEntry"
                  :key="index"
                  cols="12"
                  md="4"
                  class="fade-in"
                >
                  <v-card
                    :min-height="cardMinHeight"
                    class="primary"
                    elevation="10"
                  >
                    <div class="py-5 white">
                      <v-img
                        :src="plan.icon"
                        class="d-block mx-auto"
                        width="100px"
                        height="100px"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            />
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <div
                      :class="sectionSubTitle"
                      class="text-h5 font-weight-bold white text-center py-2"
                    >
                      <span class="pr-2 primary--text">
                        Step
                        {{ index + 1 }}
                      </span>
                      {{ plan.title }}
                    </div>
                    <v-divider />
                    <div class="pa-6 primary">
                      <div
                        class="text-start py-2 white--text"
                        v-text="plan.description"
                        style="
                          white-space: pre-line;
                          font-size: 14px;
                          line-height: 24px;
                        "
                      />
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-container>
        </v-responsive>
      </section>
    </v-sheet>

    <v-sheet style="margin-bottom: 100px">
      <div class="mt-5 py-8 text-center font-weight-bold primary white--text">
        <div class="pt-12" style="font-size: 22px">
          フルリモート・高単価案件多数
        </div>
        <div class="mb-3" style="font-size: 22px">オンラインで完結！</div>
        <div class="prompt-login-btn-label pt-10 mb-3">
          ＼ 30秒で簡単無料登録！ ／
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: start;
          "
        >
          <router-link
            :to="isLogin() ? '/mypage' : '/registeruser'"
            class="router-link"
          >
            <v-btn
              elevation="0"
              text
              rounded
              class="btn btn-register py-3 px-16"
            >
              無料登録はこちら
            </v-btn>
          </router-link>
          <router-link to="/projects" class="router-link">
            <v-btn
              outlined
              color="#fffff"
              rounded
              v-on="on"
              elevation="0"
              class="btn py-3 px-16 my-15"
              style="height: auto; font-size: 16px"
            >
              まずは案件を探す
            </v-btn>
          </router-link>
        </div>
      </div>
    </v-sheet>
  </v-container>
</template>

<style scoped>
.home-container {
  max-width: 1161px;
}
.fade-in {
  opacity: 0;
}
.fade-in.active {
  opacity: 1;
  animation: fade-in 0.5s;
}
.btn {
  display: inline-block;
  text-decoration: none;
  color: #fff !important;
  transition: 0.4s;
  font-weight: bold;
}
.btn:hover {
  background-color: #ef6c00 !important;
  border: thin solid #ef6c00 !important;
}
.btn-register {
  font-size: 16px;
  height: auto !important;
  background-color: #ef6c00 !important;
  border: thin solid #ef6c00 !important;
}
.btn-register:hover {
  background-color: #0277bd !important;
  display: inline-block;
  text-decoration: none;
  color: #fff !important;
  transition: 0.4s;
  font-weight: bold;
  border: thin solid currentColor !important;
}
.prompt-login-btn-label {
  font-size: 15px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(0.7em);
    transition-duration: 0.5s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration: 0.5s;
  }
}
</style>

<script>
import ProjectOverviewPc from '../components/pc/project/ProjectOverview';
import ProjectOverviewSp from '../components/sp/project/ProjectOverview';

export default {
  name: 'Home',
  props: ['isLogin'],
  components: {
    ProjectOverviewPc,
    ProjectOverviewSp,
  },
  data() {
    return {
      loading: true,
      features: [
        require('@/assets/img/explain_find_job.webp'),
        require('@/assets/img/support.webp'),
        require('@/assets/img/payment.webp'),
        require('@/assets/img/usage_fee.webp'),
        require('@/assets/img/private_job.webp'),
      ],
      beforeEntry: [
        {
          title: '案件を探す',
          icon: require('@/assets/icon/search.svg'),
          description:
            'qualitéAでは案件をユーザー登録することなく確認いただけます。\nご自身の希望に合った条件で案件をお探しください。',
        },
        {
          title: '登録',
          icon: require('@/assets/icon/register.svg'),
          description:
            'ご登録がお済みでない場合は、エントリーに必要なユーザー情報を qualitéA に登録していただきます。',
        },
        {
          title: '案件へ応募',
          icon: require('@/assets/icon/entry.svg'),
          description:
            '登録後、ご希望の案件にご応募ください。\n応募していただいた内容にて選考を実施させていただきます。',
        },
      ],
      afterEntry: [
        {
          title: '営業面談',
          icon: require('@/assets/icon/sales_interview.svg'),
          description:
            '応募後は、担当営業が責任を持ってあなたのご状況に沿ったご提案をさせていただきます。\nご希望の案件・求人に参画できるよう全力でサポートいたします。',
        },
        {
          title: 'キャリア面談',
          icon: require('@/assets/icon/career_interview.svg'),
          description:
            '参画予定案件のキャリア面談を実施します。担当営業が面談前に万全にサポートいたします。\n※日中になかなか時間が取れないという方でも、柔軟に日程可能です。',
        },
        {
          title: '案件参画',
          icon: require('@/assets/icon/join.svg'),
          description:
            '面談後、合意に至った場合は弊社とご契約いただきます。\n契約をはじめとした各種手続きや注意点については、弊社がサポートいたします。',
        },
      ],
      projects: [],
      items: [
        require('@/assets/img/entrance.webp'),
        require('@/assets/img/office.webp'),
        require('@/assets/img/contact_room.webp'),
      ],
    };
  },
  created() {
    this.getProjects();
    window.scrollTo(0, 0);
  },
  mounted() {
    const targets = this.$el.querySelectorAll('.fade-in');

    const options = {
      root: null,
      rootMargin: '0% 0% -25% 0%',
      threshold: 0,
    };

    const doWhenIntersect = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      });
    };

    const observer = new IntersectionObserver(doWhenIntersect, options);
    targets.forEach(target => {
      observer.observe(target);
    });
  },
  computed: {
    sectionTitle() {
      if (this.$vuetify.breakpoint.mobile) {
        return 'text-h5 font-weight-bold text-start pa-2';
      }
      return 'text-h4 font-weight-bold text-start';
    },

    sectionSubTitle() {
      if (this.$vuetify.breakpoint.mobile) {
        return 'text-h6 font-weight-bold text-center';
      }
      return 'text-h5 font-weight-bold text-center';
    },

    topIcon() {
      return require('@/assets/img/remote_work.webp');
    },

    topIconWidth() {
      if (this.$vuetify.breakpoint.mobile) {
        return '100%';
      }
      return '600px';
    },

    splitProjects() {
      let result = [];
      const needLoop = Math.floor(this.projects.length / 6);
      for (let i = 0; i < needLoop; i++) {
        const startSlice = i * 6;
        const splited = this.projects.slice(startSlice, startSlice + 6);
        result.push(splited);
      }
      return result;
    },

    cardMinHeight() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return '200px';
      } else if (this.$vuetify.breakpoint.smOnly) {
        return '250px';
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return '400px';
      }
      return '300px';
    },
  },
  methods: {
    async getProjects() {
      try {
        const result = await this.$axios.get('/api/v1/projects?order=1', {
          headers: { 'Content-Type': 'application/json' },
        });
        this.projects = result.data.projects;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
