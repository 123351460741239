<template>
  <v-responsive max-width="1161" class="mx-auto">
    <v-stepper
      v-model="stepperPage"
      outlined
      vertical
      elevation="2"
      class="mb-15 mt-5"
    >
      <v-stepper-step step="1" :complete="stepperPage > 1" color="primary">
        退会理由入力
      </v-stepper-step>
      <v-stepper-content step="1" class="pa-0">
        <v-responsive max-width="1122">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <WithdrawalFormSp
              v-model="user.withdrawal_comment"
              :withdrawalReasons="withdrawalReasons"
              :loading="withdrawalReasonLoading"
              @nextPage="nextPage"
              @changeWithdrawalReasons="changeWithdrawalReasons"
            />
          </template>
          <template v-else>
            <WithdrawalFormPc
              v-model="user.withdrawal_comment"
              :withdrawalReasons="withdrawalReasons"
              :loading="withdrawalReasonLoading"
              @nextPage="nextPage"
              @changeWithdrawalReasons="changeWithdrawalReasons"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
      <v-divider class="mt-6" />
      <v-stepper-step
        step="2"
        class="ma-1"
        :complete="stepperPage > 2"
        color="primary"
      >
        最終確認
      </v-stepper-step>
      <v-stepper-content step="2" class="pa-0">
        <v-responsive max-width="1122">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <WithdrawalConfirmSp
              :selectedWithdrawalReasons="user.withdrawal_reasons"
              :otherReason="user.withdrawal_comment"
              :withdrawalLoading="withdrawalLoading"
              @withdrawal="withdrawal"
              @backPage="backPage"
            />
          </template>
          <template v-else>
            <WithdrawalConfirmPc
              :selectedWithdrawalReasons="user.withdrawal_reasons"
              :otherReason="user.withdrawal_comment"
              :withdrawalLoading="withdrawalLoading"
              @withdrawal="withdrawal"
              @backPage="backPage"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
      <v-divider class="mt-7" />
      <v-stepper-step step="3" color="primary"> 退会完了 </v-stepper-step>
      <v-stepper-content step="3" class="pa-0">
        <v-responsive max-width="1122">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <WithdrawalCompleteSp @finish="finish" />
          </template>
          <template v-else>
            <WithdrawalCompletePc @finish="finish" />
          </template>
        </v-responsive>
      </v-stepper-content>
    </v-stepper>
  </v-responsive>
</template>

<script>
import session from '../plugins/session';
import WithdrawalFormPc from '../components/pc/withdrawal/WithdrawalForm.vue';
import WithdrawalFormSp from '../components/sp/withdrawal/WithdrawalForm.vue';
import WithdrawalConfirmPc from '../components/pc/withdrawal/WithdrawalConfirm.vue';
import WithdrawalConfirmSp from '../components/sp/withdrawal/WithdrawalConfirm.vue';
import WithdrawalCompletePc from '../components/pc/withdrawal/WithdrawalComplete.vue';
import WithdrawalCompleteSp from '../components/sp/withdrawal/WithdrawalComplete.vue';

export default {
  name: 'Withdrawal',
  components: {
    WithdrawalFormPc,
    WithdrawalFormSp,
    WithdrawalConfirmPc,
    WithdrawalConfirmSp,
    WithdrawalCompletePc,
    WithdrawalCompleteSp,
  },
  data() {
    return {
      stepperPage: 1,
      withdrawalReasons: [],
      withdrawalReasonLoading: true,
      withdrawalLoading: false,
      user: {
        withdrawal_reasons: [],
        withdrawal_comment: null,
      },
    };
  },
  created() {
    this.getWithdrawalReasons();
  },
  methods: {
    async getWithdrawalReasons() {
      try {
        const result = await this.$axios.get('/api/v1/withdrawal_reasons', {
          headers: session.apiAuthHeaders(),
        });
        this.withdrawalReasons = result.data;
        this.withdrawalReasonLoading = false;
      } catch (err) {
        this.$router.push({ path: '/' });
        this.withdrawalReasonLoading = false;
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    changeWithdrawalReasons(event) {
      this.user.withdrawal_reasons = event;
    },

    backPage() {
      this.stepperPage = 1;
    },

    nextPage(btnDisable) {
      if (btnDisable) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      this.stepperPage = 2;
    },

    async withdrawal() {
      try {
        this.withdrawalLoading = true;
        const withdrawalReasonIds =
          this.user.withdrawal_reasons.length > 0
            ? this.user.withdrawal_reasons.map(element => element.id)
            : [];
        const result = await this.$axios.post(
          '/api/v1/users/withdrawal',
          {
            user: {
              withdrawal_reasons: withdrawalReasonIds,
              withdrawal_comment: this.user.withdrawal_comment,
            },
          },
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 204) {
          this.stepperPage = 3;
          session.removeItemKeys();
        }
        this.withdrawalLoading = false;
      } catch (err) {
        this.withdrawalLoading = false;
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    finish() {
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>
