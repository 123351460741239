<template>
  <div>
    <v-col cols="12" class="pb-0 pl-1" align="left">
      <h4 class="black--text">スキル</h4>
    </v-col>
    <v-row class="pb-4">
      <v-col cols="12" class="pl-3 pb-0" :style="vColWidth">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5 class="pr-15">言語</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustSkills(0)"
                  :key="index"
                  dense
                  v-model="selectSkills"
                  @change="changeCheckBox"
                  :value="item.number"
                  :label="item.name"
                  hide-details
                  class="mr-5"
                  color="primary"
                  style="width: 200px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5 class="pr-10">フレームワーク</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-1">
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustSkills(1)"
                  :key="index"
                  dense
                  v-model="selectSkills"
                  @change="changeCheckBox"
                  :value="item.number"
                  :label="item.name"
                  hide-details
                  class="mr-5"
                  color="primary"
                  style="width: 200px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-2">
              <h5>その他</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-1">
              <v-card
                elevation="0"
                class="d-flex align-content-start flex-wrap"
              >
                <v-checkbox
                  v-for="(item, index) in adjustSkills(2)"
                  :key="index"
                  dense
                  v-model="selectSkills"
                  @change="changeCheckBox"
                  :value="item.number"
                  :label="item.name"
                  hide-details
                  class="mr-15"
                  color="primary"
                  style="width: 200px"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SkillsCheckBox',
  props: ['skills', 'selectedSkills'],
  data() {
    return {
      selectSkills: [],
    };
  },
  created() {
    // リロード＆ブラウザバック時の対応
    this.selectSkills = this.selectedSkills;
  },
  watch: {
    // selectedSkills(親のdata)を監視して
    // 変更時にselectSkills(子のdata)に代入
    selectedSkills() {
      this.selectSkills = this.selectedSkills;
    },
  },
  computed: {
    vColWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'width: 335px';
        case 'sm':
          return 'width: 535px';
        default:
          return 'width: 335px';
      }
    },
  },
  methods: {
    adjustSkills(category) {
      return this.skills.filter(skill => {
        if (skill.category == category) return true;
      });
    },

    changeCheckBox() {
      this.$emit('changeSkills', this.selectSkills);
    },
  },
};
</script>
