export default {
  data() {
    return {
      supportedExtension: [
        '.doc',
        '.docm',
        '.dot',
        '.dotm',
        '.docx',
        '.xls',
        '.xlsm',
        '.xlsx',
        '.xltm',
        '.ppt',
        '.pptm',
        '.pptx',
        '.pdf',
        '.dox',
        '.numbers',
        '.keynote',
        '.pages',
      ],
    };
  },
};
