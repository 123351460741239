<template>
  <v-card
    flat
    min-width="400"
    min-height="515"
    class="px-10 pb-2 text-center d-flex align-center"
  >
    <v-responsive max-width="340" style="height: 100%" class="mx-auto d-flex">
      <div class="d-flex justify-center">
        <div>
          <v-img width="180" src="@/assets/img/logo.png" />
        </div>
      </div>
      <v-card-title class="justify-center mb-2">
        <h3>パスワードの再設定</h3>
      </v-card-title>
      <v-card-text>
        <v-form v-model="btnDisable">
          <v-row class="pt-3">
            <v-col cols="12" align="left" class="pa-0">
              <h5 class="black--text">メールアドレス</h5>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-text-field
                color="primary"
                placeholder="メールアドレス"
                hide-details="auto"
                outlined
                dense
                :value="emailInitialValue"
                @input="updateEmail"
                :rules="rules.email"
                data-cy="reset-password-form-email-form"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-4 pa-0">
        <v-row class="px-1">
          <v-col cols="12" align="center">
            <v-btn
              elevation="0"
              color="primary"
              class="white--text font-weight-bold"
              block
              height="40px"
              @click="resetPassword"
              data-cy="reset-password-form-submit-btn"
              :loading="loading"
            >
              <v-icon
                class="mail-icon-position"
                dark
                size="25"
                v-text="'mdi-email-outline'"
              />
              再設定メールを送信
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions class="pt-6 pa-0 justify-center">
        <v-btn
          class="font-weight-bold"
          text
          color="primary"
          @click="$router.push({ path: '/login' })"
          v-text="'ログインへ戻る'"
          data-cy="reset-password-form-first-to-login-btn"
        />
      </v-card-actions>
    </v-responsive>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'ResetPasswordFormPc',
  props: ['value', 'loading'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.userEmail(value)],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },
  },
  methods: {
    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },

    resetPassword() {
      this.$emit('resetPassword', !this.btnDisable);
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
