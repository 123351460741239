<template>
  <div>
    <v-menu offset-y v-if="vMenuDisplay">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          elevation="0"
          text
          color="primary"
          @click="activateAction"
          data-cy="header-menu-btn"
          class="pl-2 pt-1"
        >
          <v-icon :large="$vuetify.breakpoint.mobile" v-text="menuIcon" />
        </v-btn>
      </template>
      <v-card class="mx-auto" width="256" tile>
        <v-list v-if="user">
          <v-list-item>
            <v-list-item-content class="pt-3 pb-1">
              <v-list-item-title class="text-h6">
                {{ displayUserName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider v-if="user" />
        <v-list nav dense>
          <v-list-item-group v-model="selectedMypageItem" color="primary">
            <router-link
              v-for="(item, i) in menuItems"
              :key="i"
              :to="item"
              class="router-link"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    data-cy="header-menu-text-list"
                    v-text="item.text"
                  />
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon v-text="`mdi-minus-circle`" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  data-cy="header-menu-text-list"
                  v-text="`ログアウト`"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <div v-else>
      <router-link to="/login" class="router-link">
        <v-btn
          depressed
          plain
          color="grey darken-4"
          class="font-weight-bold mr-2"
          v-text="'ログイン'"
          data-cy="header-menu-login-btn"
        />
      </router-link>
      <router-link to="/registeruser" class="router-link">
        <v-btn
          elevation="0"
          color="primary white--text"
          class="font-weight-bold"
          v-text="'新規登録へ'"
          data-cy="header-menu-register-btn"
        />
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.router-link {
  text-decoration: none;
}
</style>

<script>
export default {
  name: 'HeaderMenu',
  props: ['isLogin', 'user'],
  data() {
    return {
      selectedMypageItem: null,
      mypageItems: [
        {
          path: '/mypage/account',
          text: '基本情報',
          icon: 'mdi-account',
        },
        {
          path: '/mypage/skill',
          text: 'スキル',
          icon: 'mdi-account-wrench',
        },
        {
          path: '/mypage/email',
          text: 'メールアドレス変更',
          icon: 'mdi-email',
        },
        {
          path: '/mypage/password',
          text: 'パスワード変更',
          icon: 'mdi-lock',
        },
        {
          path: '/mypage/skillsheet',
          text: 'スキルシート',
          icon: 'mdi-upload',
        },
        {
          path: '/mypage/bookmark',
          text: 'ブックマーク一覧',
          icon: 'mdi-bookmark-multiple',
        },
        { path: '/mypage/entry', text: '応募履歴', icon: 'mdi-folder' },
      ],
      unauthenticatedItems: [
        {
          path: '/projects',
          text: '案件検索',
          icon: 'mdi-magnify',
        },
        { path: '/help', text: 'ヘルプ', icon: 'mdi-help-circle-outline' },
        { path: '/terms', text: '利用規約', icon: 'mdi-book-open-outline' },
        {
          path: '/privacy',
          text: 'プライバシーポリシー',
          icon: 'mdi-badge-account-horizontal-outline',
        },
        {
          path: '/contact',
          text: 'お問い合わせ',
          icon: 'mdi-email-outline',
        },
      ],
      authenticatedItems: [
        {
          path: '/projects',
          text: '案件検索',
          icon: 'mdi-magnify',
        },
        { path: '/mypage', text: 'マイページ', icon: 'mdi-account' },
        { path: '/help', text: 'ヘルプ', icon: 'mdi-help-circle-outline' },
        { path: '/terms', text: '利用規約', icon: 'mdi-book-open-outline' },
        {
          path: '/privacy',
          text: 'プライバシーポリシー',
          icon: 'mdi-badge-account-horizontal-outline',
        },
        {
          path: '/contact',
          text: 'お問い合わせ',
          icon: 'mdi-email-outline',
        },
      ],
    };
  },
  computed: {
    vMenuDisplay() {
      // PC版でログインしていない場合はfalse
      return !(!this.$vuetify.breakpoint.mobile && !this.isLogin);
    },

    menuIcon() {
      return this.$vuetify.breakpoint.mobile ? 'mdi-menu' : 'mdi-dots-vertical';
    },

    menuItems() {
      if (!this.$vuetify.breakpoint.mobile) return this.mypageItems;
      return this.isLogin ? this.authenticatedItems : this.unauthenticatedItems;
    },

    displayUserName() {
      if (this.user.last_name && this.user.first_name)
        return this.user.last_name + ' ' + this.user.first_name;
      return null;
    },
  },
  methods: {
    activateAction() {
      this.selectedMypageItem = null;
      this.$emit('getMyProfile');
    },

    logout() {
      this.$emit('logoutAction');
    },
  },
};
</script>
