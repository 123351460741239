<template>
  <div>
    <v-card outlined elevation="0" class="pt-3 pb-5">
      <v-container>
        <v-form v-model="btnDisable">
          <v-row>
            <v-col cols="6" class="pb-0">
              <h5 class="pl-1">
                姓<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                data-cy="update-form-last-name"
                placeholder="姓"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="lastNameInitialValue"
                @input="updateLastName"
                :rules="rules.lastName"
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <h5 class="pl-1">
                名<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                data-cy="update-form-first-name"
                placeholder="名"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="firstNameInitialValue"
                @input="updateFirstName"
                :rules="rules.firstName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <h5 class="pl-1">
                姓カナ<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                data-cy="update-form-last-name-kana"
                placeholder="姓カナ"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="lastNameKanaInitialValue"
                @input="updateLastNameKana"
                :rules="rules.lastNameKana"
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <h5 class="pl-1">
                名カナ<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                data-cy="update-form-first-name-kana"
                placeholder="名カナ"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="firstNameKanaInitialValue"
                @input="updateFirstNameKana"
                :rules="rules.firstNameKana"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <h5 class="pl-1">
                メールアドレス<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                data-cy="update-form-email"
                placeholder="メールアドレス"
                dense
                outlined
                disabled
                hideDetails="auto"
                color="primary"
                :value="emailInitialValue"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <h5 class="pl-1">電話番号</h5>
              <v-text-field
                data-cy="update-form-tel"
                placeholder="電話番号"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="telInitialValue"
                @input="updateTel"
                :rules="rules.tel"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <h5 class="pl-1">性別</h5>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
              <v-chip-group
                data-cy="update-form-gender"
                column
                active-class="primary--text"
                :value="genderInitialValue"
                @change="updateGender"
              >
                <v-chip
                  outlined
                  label
                  column
                  class="px-8"
                  v-for="tag in genders"
                  :key="tag.id"
                  :color="
                    genderInitialValue === tag.id
                      ? 'primary'
                      : 'rgba(0, 0, 0, 0.6)'
                  "
                  :class="
                    genderInitialValue === tag.id ? 'font-weight-bold' : ''
                  "
                >
                  {{ tag.value }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pt-0">
              <h5 class="pl-1">生年月日</h5>
              <v-select
                data-cy="update-form-birth-year"
                hide-details="auto"
                dense
                outlined
                label="年"
                class="pt-2"
                :items="birthYears"
                menu-props="auto"
                item-text="value"
                color="primary"
                item-color="primary"
                :value="birthYearInitialValue"
                @input="updateBirthYear"
                :rules="rules.birthYear"
              />
            </v-col>
            <v-col cols="4" class="pt-5">
              <v-select
                data-cy="update-form-birth-month"
                hide-details="auto"
                dense
                outlined
                label="月"
                class="pt-2"
                :items="months"
                item-text="value"
                menu-props="auto"
                color="primary"
                item-color="primary"
                :value="birthMonthInitialValue"
                @input="updateBirthMonth"
                :rules="rules.birthMonth"
              />
            </v-col>
            <v-col cols="4" class="pt-5">
              <v-select
                data-cy="update-form-birth-date"
                hide-details="auto"
                dense
                outlined
                label="日"
                class="pt-2"
                :items="setDateList"
                item-text="value"
                menu-props="auto"
                color="primary"
                item-color="primary"
                :value="birthDateInitialValue"
                @input="updateBirthDate"
                :rules="rules.birthDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <h5 class="pl-1">住所（市区町村）</h5>
              <v-text-field
                data-cy="update-form-live-area"
                placeholder="東京都 中央区"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="liveAreaInitialValue"
                @input="updateLiveArea"
                :rules="rules.liveArea"
                :counter="30"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <h5 class="pl-1">最寄り駅</h5>
              <v-text-field
                data-cy="update-form-closest-station"
                placeholder="神田"
                suffix="駅"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="closestStationInitialValue"
                @input="updateClosestStation"
                :rules="rules.closestStation"
                :counter="30"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" class="py-0">
              <h5 class="pl-1">国籍</h5>
              <v-text-field
                data-cy="update-form-nationality"
                placeholder="日本"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="nationalityInitialValue"
                @input="updateNationality"
                :rules="rules.nationality"
                :counter="30"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <h5 class="pl-1">希望単価</h5>
              <v-text-field
                data-cy="update-form-desired-cost"
                placeholder="80"
                suffix="万"
                outlined
                dense
                hideDetails="auto"
                color="primary"
                :value="desiredCostInitialValue"
                @input="updateDesiredCost"
                :rules="rules.desiredCost"
                :counter="3"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <h5 class="pl-1">現在の状況</h5>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
              <v-chip-group
                data-cy="update-form-current-status"
                column
                active-class="primary--text"
                :value="currentStatusInitialValue"
                @change="updateCurrentStatus"
              >
                <v-chip
                  outlined
                  label
                  column
                  class="px-7"
                  v-for="tag in currentStatuses"
                  :key="tag.id"
                  :color="
                    currentStatusInitialValue === tag.id
                      ? 'primary'
                      : 'rgba(0, 0, 0, 0.6)'
                  "
                  :class="
                    currentStatusInitialValue === tag.id
                      ? 'font-weight-bold'
                      : ''
                  "
                >
                  {{ tag.value }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <h5 class="pl-1">特記事項</h5>
              <v-textarea
                data-cy="update-form-note"
                outlined
                dense
                hide-details="auto"
                class="pt-2"
                color="primary"
                :value="noteInitialValue"
                @input="updateNote"
                :rules="rules.note"
                :counter="300"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h5>メール通知</h5>
              <v-radio-group
                row
                :value="emailNotificationInitialValue"
                @change="updateEmailNotificationFlag"
                class="mx-auto"
              >
                <v-radio label="ON" :value="true"></v-radio>
                <v-radio label="OFF" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <v-row class="mt-1 mb-3">
      <v-col cols="6" align="left">
        <v-btn
          data-cy="update-form-withdrawal-btn"
          v-text="'退会はこちら'"
          text
          color="primary"
          class="font-weight-bold"
          @click="$router.push({ path: '/withdrawal' })"
        />
      </v-col>
      <v-col cols="6" align="right">
        <v-btn
          data-cy="update-form-update-btn"
          elevation="0"
          class="font-weight-bold"
          color="primary white--text"
          width="100"
          @click="update"
          :loading="loadingByAccountInfo"
        >
          更新
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import validate from '../../../plugins/validate';
import mixinDate from '../../../const/date';
import mixinGenders from '../../../const/user/genders';
import mixinCurrentStatuses from '../../../const/user/currentStatuses';
import dayjs from 'dayjs';

export default {
  name: 'AccountMypageSp',
  props: ['value', 'loadingByAccountInfo'],
  mixins: [mixinDate, mixinGenders, mixinCurrentStatuses],
  data() {
    return {
      btnDisable: false,
      rules: {
        firstName: [value => validate.userName(value, '名')],
        firstNameKana: [value => validate.userNameKana(value, '名(カナ)')],
        lastName: [value => validate.userName(value, '姓')],
        lastNameKana: [value => validate.userNameKana(value, '姓(カナ)')],
        birthDate: [value => validate.checkNumber(value)],
        birthMonth: [value => validate.checkNumber(value)],
        birthYear: [value => validate.checkNumber(value)],
        tel: [value => validate.userTel(value)],
        liveArea: [value => validate.userLiveArea(value)],
        closestStation: [value => validate.userClosestStation(value)],
        nationality: [value => validate.userNationality(value)],
        desiredCost: [value => validate.userDesiredCost(value)],
        note: [value => validate.userNote(value)],
      },
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Account');
  },
  computed: {
    setDateList() {
      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) {
        // 入力されていない場合は28日までのリストを表示
        return this.dates(28);
      }
      return this.dates(dayjsParse.endOf('month').date());
    },

    emailInitialValue() {
      return this.value.email == null ? null : this.value.email;
    },

    firstNameInitialValue() {
      return this.value.first_name == null ? null : this.value.first_name;
    },

    firstNameKanaInitialValue() {
      return this.value.first_name_kana == null
        ? null
        : this.value.first_name_kana;
    },

    lastNameInitialValue() {
      return this.value.last_name == null ? null : this.value.last_name;
    },

    lastNameKanaInitialValue() {
      return this.value.last_name_kana == null
        ? null
        : this.value.last_name_kana;
    },

    telInitialValue() {
      return this.value.tel == null ? null : this.value.tel;
    },

    birthYearInitialValue() {
      if (this.value.birth == null) return null;

      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.year();
    },

    birthMonthInitialValue() {
      if (this.value.birth == null) return null;

      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.month() + 1;
    },

    birthDateInitialValue() {
      if (this.value.birth == null) return null;

      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.date();
    },

    genderInitialValue() {
      return this.value.gender == null ? null : this.value.gender;
    },

    currentStatusInitialValue() {
      return this.value.current_status == null
        ? null
        : this.value.current_status;
    },

    closestStationInitialValue() {
      return this.value.closest_station == null
        ? null
        : this.value.closest_station;
    },

    liveAreaInitialValue() {
      return this.value.live_area == null ? null : this.value.live_area;
    },

    nationalityInitialValue() {
      return this.value.nationality == null ? null : this.value.nationality;
    },

    desiredCostInitialValue() {
      return this.value.desired_cost == null ? null : this.value.desired_cost;
    },

    noteInitialValue() {
      return this.value.note == null ? null : this.value.note;
    },

    emailNotificationInitialValue() {
      return this.value.email_notification_flag;
    },
  },
  methods: {
    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateFirstName(e) {
      const newValue = {
        ...this.value,
        first_name: e,
      };
      this.$emit('input', newValue);
    },

    updateFirstNameKana(e) {
      const newValue = {
        ...this.value,
        first_name_kana: e,
      };
      this.$emit('input', newValue);
    },

    updateLastName(e) {
      const newValue = {
        ...this.value,
        last_name: e,
      };
      this.$emit('input', newValue);
    },

    updateLastNameKana(e) {
      const newValue = {
        ...this.value,
        last_name_kana: e,
      };
      this.$emit('input', newValue);
    },

    updateTel(e) {
      const newValue = {
        ...this.value,
        tel: e,
      };
      this.$emit('input', newValue);
    },

    updateBirthYear(e) {
      if (this.value.birth == null) {
        const setValue = {
          ...this.value,
          birth: dayjs().year(e).month(0).date(1).hour(0).minute(0).format(),
        };
        this.$emit('input', setValue);
        return;
      }
      const dayjsParse = dayjs(this.value.birth);
      const newValue = {
        ...this.value,
        birth: dayjsParse.year(e).format(),
      };
      this.$emit('input', newValue);
    },

    updateBirthMonth(e) {
      if (this.value.birth == null) {
        const setValue = {
          ...this.value,
          birth: dayjs()
            .year(2000)
            .month(e - 1)
            .date(1)
            .hour(0)
            .minute(0)
            .format(),
        };
        this.$emit('input', setValue);
        return;
      }
      const dayjsParse = dayjs(this.value.birth);
      const newValue = {
        ...this.value,
        birth: dayjsParse.month(e - 1).format(),
      };
      this.$emit('input', newValue);
    },

    updateBirthDate(e) {
      if (this.value.birth == null) {
        const setValue = {
          ...this.value,
          birth: dayjs().year(2000).month(1).date(e).hour(0).minute(0).format(),
        };
        this.$emit('input', setValue);
        return;
      }
      const dayjsParse = dayjs(this.value.birth);
      const newValue = {
        ...this.value,
        birth: dayjsParse.date(e).format(),
      };
      this.$emit('input', newValue);
    },

    updateGender(e) {
      const newValue = {
        ...this.value,
        gender: e,
      };
      this.$emit('input', newValue);
    },

    updateCurrentStatus(e) {
      const newValue = {
        ...this.value,
        current_status: e,
      };
      this.$emit('input', newValue);
    },

    updateClosestStation(e) {
      const newValue = {
        ...this.value,
        closest_station: e,
      };
      this.$emit('input', newValue);
    },

    updateLiveArea(e) {
      const newValue = {
        ...this.value,
        live_area: e,
      };
      this.$emit('input', newValue);
    },

    updateNationality(e) {
      const newValue = {
        ...this.value,
        nationality: e,
      };
      this.$emit('input', newValue);
    },

    updateDesiredCost(e) {
      const newValue = {
        ...this.value,
        desired_cost: Number(e),
      };
      this.$emit('input', newValue);
    },

    updateNote(e) {
      const newValue = {
        ...this.value,
        note: e,
      };
      this.$emit('input', newValue);
    },

    updateEmailNotificationFlag(e) {
      const newValue = {
        ...this.value,
        email_notification_flag: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
