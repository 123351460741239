<template>
  <v-dialog
    v-model="baseInfoDialog"
    scrollable
    persistent
    @click:outside="closeDialog"
  >
    <v-form v-model="btnDisable">
      <v-card class="pa-0" data-cy="entry-step1-dialog">
        <v-card-title class="py-2">
          <div
            class="font-weight-bold"
            style="font-size: 17px"
            v-text="'基本情報・編集'"
            data-cy="entry-step1-dialog-title"
          />
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <!-- 名前 -->
            <v-col cols="6" class="pb-0 pt-1">
              <h5 data-cy="entry-step1-dialog-col-name-last">
                姓<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="姓"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="lastNameInitialValue"
                @input="updateLastName"
                :rules="rules.lastName"
                data-cy="entry-step1-dialog-input-name-last"
              />
            </v-col>
            <v-col cols="6" class="pb-0 pt-1">
              <h5 data-cy="entry-step1-dialog-col-name-first">
                名<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="名"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="firstNameInitialValue"
                @input="updateFirstName"
                :rules="rules.firstName"
                data-cy="entry-step1-dialog-input-name-first"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- カナ -->
            <v-col cols="6" class="pb-0">
              <h5 data-cy="entry-step1-dialog-col-kana-last">
                姓カナ<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="姓カナ"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="lastNameKanaInitialValue"
                @input="updateLastNameKana"
                :rules="rules.lastNameKana"
                data-cy="entry-step1-dialog-input-kana-last"
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <h5 data-cy="entry-step1-dialog-col-kana-first">
                名カナ<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="名カナ"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="firstNameKanaInitialValue"
                @input="updateFirstNameKana"
                :rules="rules.firstNameKana"
                data-cy="entry-step1-dialog-input-kana-first"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- メールアドレス・電話番号 -->
            <v-col cols="12" class="pb-0">
              <h5 data-cy="entry-step1-dialog-col-email">
                メールアドレス<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="メールアドレス"
                dense
                disabled
                outlined
                hideDetails="auto"
                color="primary"
                :value="emailInitialValue"
                data-cy="entry-step1-dialog-input-email"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <h5 data-cy="entry-step1-dialog-col-tel">
                電話番号<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="電話番号"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="telInitialValue"
                @input="updateTel"
                :rules="rules.tel"
                data-cy="entry-step1-dialog-input-tel"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- 性別 -->
            <v-col cols="12" class="pb-0">
              <h5 data-cy="entry-step1-dialog-col-gender">
                性別<span class="pl-1" style="color: red">※</span>
              </h5>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
              <v-chip-group
                column
                active-class="primary--text"
                :value="genderInitialValue"
                @change="updateGender"
              >
                <v-chip
                  v-for="tag in genders"
                  :key="tag.id"
                  :color="
                    genderInitialValue === tag.id
                      ? 'primary'
                      : 'rgba(0, 0, 0, 0.6)'
                  "
                  :class="
                    genderInitialValue === tag.id ? 'font-weight-bold' : ''
                  "
                  outlined
                  label
                  column
                  class="px-6"
                  :data-cy="`entry-step1-dialog-input-gender-${tag.id}`"
                >
                  {{ tag.value }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row>
            <!-- 生年月日 -->
            <v-col cols="12" class="py-0">
              <h5 data-cy="entry-step1-dialog-col-birth">
                生年月日<span class="pl-1" style="color: red">※</span>
              </h5>
            </v-col>
            <v-col cols="4" class="pt-0 pr-0">
              <v-select
                hide-details="auto"
                dense
                outlined
                label="年"
                class="pt-2"
                :items="birthYears"
                item-text="value"
                menu-props="auto"
                color="primary"
                item-color="primary"
                :value="birthYearInitialValue"
                @input="updateBirthYear"
                :rules="rules.birthYear"
                data-cy="entry-step1-dialog-input-birth-year"
              />
            </v-col>
            <v-col cols="4" class="pt-0 px-1">
              <v-select
                hide-details="auto"
                dense
                outlined
                label="月"
                class="pt-2"
                :items="months"
                item-text="value"
                menu-props="auto"
                item-color="primary"
                color="primary"
                :value="birthMonthInitialValue"
                @input="updateBirthMonth"
                :rules="rules.birthMonth"
                data-cy="entry-step1-dialog-input-birth-month"
              />
            </v-col>
            <v-col cols="4" class="pt-0 pl-0">
              <v-select
                hide-details="auto"
                dense
                outlined
                label="日"
                class="pt-2"
                :items="setDateList"
                item-text="value"
                menu-props="auto"
                color="primary"
                item-color="primary"
                :value="birthDateInitialValue"
                @input="updateBirthDate"
                :rules="rules.birthDate"
                data-cy="entry-step1-dialog-input-birth-date"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- 住所（市区町村）・最寄り駅 -->
            <v-col cols="6" class="pt-0">
              <h5 data-cy="entry-step1-dialog-col-area">
                住所（市区町村）<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="東京都 中央区"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="liveAreaInitialValue"
                @input="updateLiveArea"
                :rules="rules.liveArea"
                data-cy="entry-step1-dialog-input-area"
                :counter="30"
              />
            </v-col>
            <v-col cols="6" class="pt-0">
              <h5 data-cy="entry-step1-dialog-col-station">
                最寄り駅<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="神田"
                suffix="駅"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="closestStationInitialValue"
                @input="updateClosestStation"
                :rules="rules.closestStation"
                data-cy="entry-step1-dialog-input-station"
                :counter="30"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- 国籍 -->
            <v-col cols="12" class="py-0">
              <h5 data-cy="entry-step1-dialog-col-country">
                国籍<span class="pl-1" style="color: red">※</span>
              </h5>
              <v-text-field
                placeholder="日本"
                dense
                outlined
                hideDetails="auto"
                color="primary"
                :value="nationalityInitialValue"
                @input="updateNationality"
                :rules="rules.nationality"
                data-cy="entry-step1-dialog-input-country"
                :counter="30"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- 現在の状況 -->
            <v-col cols="12" class="pb-0">
              <h5 data-cy="entry-step1-dialog-col-status">
                現在の状況<span class="pl-1" style="color: red">※</span>
              </h5>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
              <v-chip-group
                column
                active-class="primary--text"
                :value="currentStatusInitialValue"
                @change="updateCurrentStatus"
              >
                <v-chip
                  v-for="tag in currentStatuses"
                  :key="tag.id"
                  :color="
                    currentStatusInitialValue === tag.id
                      ? 'primary'
                      : 'rgba(0, 0, 0, 0.6)'
                  "
                  :class="
                    currentStatusInitialValue === tag.id
                      ? 'font-weight-bold'
                      : ''
                  "
                  label
                  column
                  outlined
                  class="px-7"
                  v-text="tag.value"
                  :data-cy="`entry-step1-dialog-input-status-${tag.id}`"
                />
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row>
            <!-- 特記事項 -->
            <v-col cols="12" class="pt-0">
              <h5 data-cy="entry-step1-dialog-col-note">特記事項</h5>
              <v-textarea
                outlined
                dense
                hide-details="auto"
                class="pt-2"
                color="primary"
                :value="noteInitialValue"
                @input="updateNote"
                :rules="rules.note"
                data-cy="entry-step1-dialog-input-note"
                :counter="300"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="mx-2 py-3 justify-space-between">
          <v-btn
            color="primary"
            elevation="0"
            width="40%"
            height="40"
            outlined
            class="font-weight-bold"
            @click="closeDialog"
            v-text="'閉じる'"
            style="font-size: 13px"
            data-cy="entry-step1-dialog-close-btn"
          />
          <v-btn
            color="primary white--text"
            elevation="0"
            width="40%"
            height="40"
            class="font-weight-bold"
            @click="updateUser"
            :disabled="!btnDisable"
            :loading="loadingByUpdateUser"
            style="font-size: 13px"
            data-cy="entry-step1-dialog-save-btn"
          >
            編集を保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import validate from '../../../plugins/validate';
import mixinDate from '../../../const/date';
import mixinCurrentStatus from '../../../const/user/currentStatuses';
import mixinGendersStatus from '../../../const/user/genders';
import dayjs from 'dayjs';
import utils from '../../../plugins/utils';

export default {
  name: 'AccountDialogSp',
  props: ['value', 'baseInfoDialog', 'loadingByUpdateUser'],
  mixins: [mixinDate, mixinCurrentStatus, mixinGendersStatus],
  data() {
    return {
      btnDisable: false,
      rules: {
        firstName: [value => validate.userName(value, '名')],
        firstNameKana: [value => validate.userNameKana(value, '名(カナ)')],
        lastName: [value => validate.userName(value, '姓')],
        lastNameKana: [value => validate.userNameKana(value, '姓(カナ)')],
        birthDate: [value => validate.checkNumber(value)],
        birthMonth: [value => validate.checkNumber(value)],
        birthYear: [value => validate.checkNumber(value)],
        tel: [value => validate.userTel(value)],
        liveArea: [value => validate.userLiveArea(value)],
        closestStation: [value => validate.userClosestStation(value)],
        nationality: [value => validate.userNationality(value)],
        note: [value => validate.userNote(value)],
      },
    };
  },
  computed: {
    setDateList() {
      if (!this.value) return this.dates(28);
      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) {
        // 入力されていない場合は28日までのリストを表示
        return this.dates(28);
      }
      return this.dates(dayjsParse.endOf('month').date());
    },

    firstNameInitialValue() {
      return this.validateTextInitialValue('first_name');
    },

    firstNameKanaInitialValue() {
      return this.validateTextInitialValue('first_name_kana');
    },

    lastNameInitialValue() {
      return this.validateTextInitialValue('last_name');
    },

    lastNameKanaInitialValue() {
      return this.validateTextInitialValue('last_name_kana');
    },

    emailInitialValue() {
      return this.validateTextInitialValue('email');
    },

    birthYearInitialValue() {
      if (
        !(
          this.value &&
          utils.hasOwnPropertyCall(this.value, 'birth') &&
          this.value['birth']
        )
      )
        return null;

      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.year();
    },

    birthMonthInitialValue() {
      if (
        !(
          this.value &&
          utils.hasOwnPropertyCall(this.value, 'birth') &&
          this.value['birth']
        )
      )
        return null;

      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.month() + 1;
    },

    birthDateInitialValue() {
      if (
        !(
          this.value &&
          utils.hasOwnPropertyCall(this.value, 'birth') &&
          this.value['birth']
        )
      )
        return null;

      const dayjsParse = dayjs(this.value.birth);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.date();
    },

    telInitialValue() {
      return this.validateTextInitialValue('tel');
    },

    liveAreaInitialValue() {
      return this.validateTextInitialValue('live_area');
    },

    currentStatusInitialValue() {
      if (
        !(
          this.value &&
          utils.hasOwnPropertyCall(this.value, 'current_status') &&
          this.value['current_status'] !== null
        )
      ) {
        return null;
      } else {
        return this.value['current_status'];
      }
    },

    nationalityInitialValue() {
      return this.validateTextInitialValue('nationality');
    },

    genderInitialValue() {
      if (
        !(
          this.value &&
          utils.hasOwnPropertyCall(this.value, 'gender') &&
          this.value['gender'] !== null
        )
      ) {
        return null;
      } else {
        return this.value['gender'];
      }
    },

    closestStationInitialValue() {
      return this.validateTextInitialValue('closest_station');
    },

    noteInitialValue() {
      return this.validateTextInitialValue('note');
    },

    currentStatusesInitialValue() {
      return this.validateTextInitialValue('current_status');
    },
  },
  methods: {
    // NOTE: null check and return initial value
    validateTextInitialValue(propertyName) {
      return this.value &&
        utils.hasOwnPropertyCall(this.value, propertyName) &&
        this.value[propertyName]
        ? this.value[propertyName]
        : null;
    },

    updateFirstName(e) {
      const newValue = {
        ...this.value,
        first_name: e,
      };
      this.$emit('input', newValue);
    },

    updateFirstNameKana(e) {
      const newValue = {
        ...this.value,
        first_name_kana: e,
      };
      this.$emit('input', newValue);
    },

    updateLastName(e) {
      const newValue = {
        ...this.value,
        last_name: e,
      };
      this.$emit('input', newValue);
    },

    updateLastNameKana(e) {
      const newValue = {
        ...this.value,
        last_name_kana: e,
      };
      this.$emit('input', newValue);
    },

    updateBirthYear(e) {
      if (this.value.birth == null) {
        const setValue = {
          ...this.value,
          birth: dayjs().year(e).month(0).date(1).hour(0).minute(0).format(),
        };
        this.$emit('input', setValue);
        return;
      }
      const dayjsParse = dayjs(this.value.birth);
      const newValue = {
        ...this.value,
        birth: dayjsParse.year(e).format(),
      };
      this.$emit('input', newValue);
    },

    updateBirthMonth(e) {
      if (this.value.birth == null) {
        const setValue = {
          ...this.value,
          birth: dayjs()
            .year(2000)
            .month(e - 1)
            .date(1)
            .hour(0)
            .minute(0)
            .format(),
        };
        this.$emit('input', setValue);
        return;
      }
      const dayjsParse = dayjs(this.value.birth);
      const newValue = {
        ...this.value,
        birth: dayjsParse.month(e - 1).format(), // 0indexのため-1
      };
      this.$emit('input', newValue);
    },

    updateBirthDate(e) {
      if (this.value.birth == null) {
        const setValue = {
          ...this.value,
          birth: dayjs().year(2000).month(0).date(e).hour(0).minute(0).format(),
        };
        this.$emit('input', setValue);
        return;
      }
      const dayjsParse = dayjs(this.value.birth);
      const newValue = {
        ...this.value,
        birth: dayjsParse.date(e).format(),
      };
      this.$emit('input', newValue);
    },

    updateTel(e) {
      const newValue = {
        ...this.value,
        tel: e,
      };
      this.$emit('input', newValue);
    },

    updateCurrentStatus(e) {
      const newValue = {
        ...this.value,
        current_status: e,
      };
      this.$emit('input', newValue);
    },

    updateLiveArea(e) {
      const newValue = {
        ...this.value,
        live_area: e,
      };
      this.$emit('input', newValue);
    },

    updateGender(e) {
      const newValue = {
        ...this.value,
        gender: e,
      };
      this.$emit('input', newValue);
    },

    updateNationality(e) {
      const newValue = {
        ...this.value,
        nationality: e,
      };
      this.$emit('input', newValue);
    },

    updateClosestStation(e) {
      const newValue = {
        ...this.value,
        closest_station: e,
      };
      this.$emit('input', newValue);
    },

    updateNote(e) {
      const newValue = {
        ...this.value,
        note: e,
      };
      this.$emit('input', newValue);
    },

    closeDialog() {
      this.$emit('close');
    },

    updateUser() {
      this.$emit('updateUser', !this.btnDisable, false, this.currentSkills());
    },

    currentSkills() {
      if (this.value.user_skills.length === 0) return [];
      const v = this.value.user_skills.map(skill => {
        return {
          required_skill_id: skill.required_skill_id,
          experience_year: skill.experience_year,
        };
      });
      return v;
    },
  },
};
</script>
