<template>
  <div>
    <MarkdownViewer :markDownSource="markDownSource" />
  </div>
</template>

<script>
import MarkdownViewer from '../components/common/MarkdownViewer.vue';
import Terms from '../assets/md/term_of_use.md';

export default {
  name: 'TermUse',
  components: {
    MarkdownViewer,
  },
  data() {
    return {
      markDownSource: {
        body: Terms,
        title: '利用規約',
      },
    };
  },
};
</script>
