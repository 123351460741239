<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-stepper v-model="stepperPage" elevation="0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <template v-if="!$vuetify.breakpoint.xsOnly">
            <RegisterFormPc
              v-model="user"
              :loading="loading"
              @back="back"
              @create="create"
            />
          </template>
          <template v-else>
            <RegisterFormSp
              v-model="user"
              :loading="loading"
              @back="back"
              @create="create"
            />
          </template>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <template v-if="!$vuetify.breakpoint.xsOnly">
            <RegisterCompletePc />
          </template>
          <template v-else>
            <RegisterCompleteSp />
          </template>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import RegisterFormPc from '../components/pc/register/RegisterForm.vue';
import RegisterFormSp from '../components/sp/register/RegisterForm.vue';
import RegisterCompletePc from '../components/pc/register/RegisterComplete.vue';
import RegisterCompleteSp from '../components/sp/register/RegisterComplete.vue';

export default {
  name: 'RegisterUser',
  components: {
    RegisterFormPc,
    RegisterFormSp,
    RegisterCompletePc,
    RegisterCompleteSp,
  },
  data() {
    return {
      stepperPage: 1,
      user: {
        email: null,
        password: null,
        password_confirmation: null,
      },
      loading: false,
    };
  },
  methods: {
    back(pageNum) {
      switch (pageNum) {
        case 2:
          this.stepperPage = 1;
          break;
        default:
          break;
      }
    },

    async create(isFormValid, isFormCheck) {
      if (isFormValid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      if (isFormCheck) {
        this.$emit(
          'showSnackbar',
          'error',
          '「利用規約に同意する」にチェックを入れてください。'
        );
        return;
      }

      const hasNoMatchedPassword = !(
        this.user.password === this.user.password_confirmation
      );
      if (hasNoMatchedPassword) {
        this.$emit(
          'showSnackbar',
          'error',
          'パスワードとパスワード確認が一致していません。'
        );
        return;
      }

      try {
        this.loading = true;
        const result = await this.$axios.post(
          '/api/v1/auth',
          { user: this.user },
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (result.status === 200) this.stepperPage = 2;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status === 409) {
          this.$emit('logout');
          this.$emit(
            'showSnackbar',
            'error',
            '会員登録に失敗しました。\n入力いただいたメールアドレスは既に登録済みの可能性があります。'
          );
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
