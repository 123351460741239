const validate = {};

// common
validate.checkNumber = function (value) {
  return Number.isInteger(value) || '不正な値です。';
};

validate.checkBoolean = function (value) {
  const result = value === true || value === false;
  return result || '不正な値です。';
};

validate.checkExist = function (value) {
  return !!value || '入力してください';
};

// user
validate.userName = function (value, type) {
  if (!value) return '入力してください';
  const userNameMaxLength = 20;
  if (value.length > userNameMaxLength) {
    return `${type}は20文字以内で入力してください`;
  }
  // ひらがな、カタカナ、漢字、英字のみ許容
  const regExp =
    /^[\p{scx=Hiragana}\p{scx=Katakana}\p{scx=Han}\p{scx=Latin}]+$/u;
  return regExp.test(value) || '不正な値です';
};

validate.userNameKana = function (value, type) {
  if (!value) return '入力してください';
  const userNameKanaMaxLength = 20;
  if (value.length > userNameKanaMaxLength) {
    return `${type}は20文字以内で入力してください`;
  }
  const REGEX = /^[ァ-ンヴー]*$/;
  return REGEX.test(value) || `${type}は全角カタカナで入力してください`;
};

validate.userEmail = function (value) {
  if (!value) return '入力してください';
  const REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return REGEX.test(value) || '不正なメールアドレスです';
};

validate.userPassword = function (value) {
  if (!value) return '入力してください';
  const REGEX = /^[a-zA-Z0-9!-/:-@\\[-`{-~]{8,32}$/;
  return (
    REGEX.test(value) ||
    '半角英数記号のみで、8文字以上32文字以内で入力してください'
  );
};

validate.userTel = function (value) {
  if (!value) return true;
  const REGEX_HYPHEN_EXISTS = /^(0\d{1,3}-\d{2,4}-\d{3,4}$)?$/;
  const REGEX_NO_HYPHEN = /^(0\d{9,10}$)?$/;
  return (
    REGEX_HYPHEN_EXISTS.test(value) ||
    REGEX_NO_HYPHEN.test(value) ||
    '不正な電話番号です'
  );
};

validate.userLiveArea = function (value) {
  if (!value) return true;
  const liveAreaMaxLength = 30;
  return (
    value.length <= liveAreaMaxLength ||
    '住所(市区町村)は30文字以内で入力してください'
  );
};

validate.userNationality = function (value) {
  if (!value) return true;
  const nationalityMaxLength = 30;
  return (
    value.length <= nationalityMaxLength || '国籍は30文字以内で入力してください'
  );
};

validate.userClosestStation = function (value) {
  if (!value) return true;
  const closestStationMaxLength = 30;
  return (
    value.length <= closestStationMaxLength ||
    '最寄り駅は30文字以内で入力してください'
  );
};

validate.userDesiredCost = function (value) {
  if (!value) return true;
  const REGEX = /^[0-9]*$/;
  const isLength = !value ? true : String(value).length <= 3;
  return (
    (REGEX.test(value) && isLength) ||
    '希望単価は3桁以内の半角数値で入力してください'
  );
};

validate.userOtherSkills = function (value) {
  if (!value) return true;
  const REGEX = new RegExp(/^(?!.*([!"$%'()*+\-.,/:;<=>?@[\\\]^_`{|}~])).*$/);
  if (!REGEX.test(value)) return '登録できない文字が含まれています。';

  const otherSkillMaxLength = 15;
  return (
    value.length <= otherSkillMaxLength ||
    `${otherSkillMaxLength}文字以内で入力してください`
  );
};

validate.userNote = function (value) {
  if (!value) return true;
  const noteMaxLength = 300;
  return (
    value.length <= noteMaxLength || '特記事項は300文字以内で入力してください'
  );
};

validate.inquiryOtherTitle = function (value) {
  if (!value) return '入力してください';
  const inquiryOtherTitleMaxLength = 50;
  return (
    value.length <= inquiryOtherTitleMaxLength ||
    '件名は50字以内で入力してください'
  );
};

validate.inquiryContent = function (value) {
  if (!value) return '入力してください';
  const inquiryContentMaxLength = 500;
  return (
    value.length <= inquiryContentMaxLength || `500字以内で入力してください`
  );
};

// withdrawal reason
validate.withdrawalOtherReason = function (value) {
  if (!value) return true;
  const withdrawalOtherReasonMaxLength = 500;
  return (
    value.length <= withdrawalOtherReasonMaxLength ||
    '500文字以内で入力してください'
  );
};

// project
validate.projectCost = function (value) {
  if (!value) return true;
  const REGEX = /^[0-9]*$/;
  const isLength = !value ? true : String(value).length <= 3;
  return (REGEX.test(value) && isLength) || '不正な入力です';
};

export default validate;
