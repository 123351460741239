<template>
  <v-container>
    <v-responsive max-width="1024" class="mx-auto">
      <template v-if="markDownSource.title != null">
        <h2 v-text="markDownSource.title" />
      </template>
      <v-card outlined elevation="0" class="pa-5 mb-6">
        <div v-html="compiledText" />
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script>
import { marked } from 'marked';

export default {
  name: 'MarkdownViewer',
  props: ['markDownSource'],
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    compiledText() {
      return marked(this.markDownSource.body);
    },
  },
};
</script>
