<template>
  <v-card
    outlined
    link
    hover
    :to="{ name: 'Project', params: { id: project.id } }"
    width="500"
    class="rounded-lg"
    :height="$vuetify.breakpoint.xsOnly ? '480px' : '300px'"
    data-cy="home-project-overview-card"
  >
    <v-container class="pa-6">
      <v-row>
        <v-col cols="12" class="pb-0">
          <div style="height: 50px; display: flex; align-items: center">
            <h3
              class=""
              style="
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              "
              v-text="project.name"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pb-0">
          <v-divider color="#0277BD" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <h5>
            <v-icon small class="pb-1 pr-1" v-text="'mdi-cash'" />
            単価
          </h5>
          <div
            class="pl-1 text-subtitle-1 font-weight-bold accent--text"
            v-text="getCosts"
          />
        </v-col>

        <v-col cols="12" sm="4" class="pb-0">
          <h5>
            <v-icon
              small
              class="pb-1 pr-1"
              v-text="'mdi-map-marker-radius-outline'"
            />
            エリア
          </h5>
          <div class="pt-2 pl-1 text-body-2" v-text="project.station" />
        </v-col>

        <v-col cols="12" sm="4" class="pb-0">
          <h5>
            <v-icon small class="pb-1 pr-1" v-text="'mdi-account-outline'" />
            勤務形態
          </h5>
          <div class="pt-2 pl-1 text-body-2" v-text="workLocation" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="">
          <h5>
            <v-icon small class="pb-1 pr-1" v-text="'mdi-content-paste'" />
            概要
          </h5>
          <div
            class="pt-2 pl-1 text-body-2"
            style="
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
            "
            v-text="description"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixinWorkLocation from '../../../const/project/workLocation';

export default {
  name: 'ProjectOverviewPc',
  props: ['project'],
  mixins: [mixinWorkLocation],
  computed: {
    getCosts() {
      return this.project?.cost ? `${this.project.cost}万円` : null;
    },

    workLocation() {
      return this.workLocations.find(
        element => element.id === this.project.work_location
      ).value;
    },

    description() {
      return this.project?.description;
    },
  },
};
</script>
