<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-container>
        <v-form ref="form" v-model="btnDisable">
          <v-row>
            <v-col cols="12">
              <h5>現在のパスワード</h5>
              <v-text-field
                data-cy="update-password-form-current-password"
                placeholder="現在のパスワード"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                :type="passwordType"
                color="primary"
                :value="currentPasswordInitialValue"
                @input="updateCurrentPassword"
                :rules="rules.currentPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h5>新しいパスワード</h5>
              <v-text-field
                data-cy="update-password-form-new-password"
                placeholder="新しいパスワード"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                :type="passwordType"
                color="primary"
                :value="passwordInitialValue"
                @input="updatePassword"
                :rules="rules.password"
              />
            </v-col>
            <v-col cols="6">
              <h5>新しいパスワード確認</h5>
              <v-text-field
                data-cy="update-password-form-password-confirm"
                placeholder="新しいパスワード確認"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                :type="passwordType"
                color="primary"
                :value="passwordConfirmationInitialValue"
                @input="updatePasswordConfirmation"
                :rules="rules.passwordConfirmation"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" align="left" class="pt-0 pr-0">
              <v-checkbox
                data-cy="update-password-form-checkbox"
                v-model="passwordCheckbox"
                label="パスワードを表示する"
                dense
                color="primary"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="7" align="left" class="pt-0 pl-0">
              <h5
                class="pt-3 password-hint font-weight-bold"
                style="color: rgba(0, 0, 0, 0.6)"
              >
                ※ 半角英数記号のみで、8文字以上入力してください。
              </h5>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <div class="d-flex mt-3">
      <v-spacer />
      <v-btn
        data-cy="update-password-form-btn"
        width="100"
        class="mt-2 font-weight-bold"
        elevation="0"
        height="35px"
        color="primary white--text"
        @click="update"
        :loading="loadingByPassword"
      >
        更新
      </v-btn>
    </div>
  </div>
</template>

<script>
import validate from '../../../plugins/validate';

export default {
  name: 'PasswordMypagePc',
  props: ['value', 'loadingByPassword'],
  data() {
    return {
      btnDisable: false,
      rules: {
        currentPassword: [value => validate.userPassword(value)],
        password: [value => validate.userPassword(value)],
        passwordConfirmation: [value => validate.userPassword(value)],
      },
      passwordCheckbox: false,
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Password');
  },
  computed: {
    passwordType() {
      return this.passwordCheckbox ? 'text' : 'password';
    },

    currentPasswordInitialValue() {
      return this.value?.current_password == null
        ? null
        : this.value.current_password;
    },

    passwordInitialValue() {
      return this.value?.password == null ? null : this.value.password;
    },

    passwordConfirmationInitialValue() {
      return this.value?.password_confirmation == null
        ? null
        : this.value.password_confirmation;
    },
  },
  methods: {
    resetValidate() {
      this.$refs.form.resetValidation();
    },

    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateCurrentPassword(e) {
      const newValue = {
        ...this.value,
        current_password: e,
      };
      this.$emit('input', newValue);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
