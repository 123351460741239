<template>
  <v-card elevation="0" width="618" class="pt-5 px-5 pb-0">
    <v-row>
      <v-col cols="12" class="pt-1" align="left">
        <v-img width="140" src="@/assets/img/logo.png" />
      </v-col>
    </v-row>
    <v-card-title class="justify-left pl-0">
      <h4>アカウント作成</h4>
    </v-card-title>
    <v-form v-model="btnDisable">
      <v-row class="pt-3">
        <v-col cols="12" class="py-0 pl-4" align="left">
          <h5>メールアドレス</h5>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            data-cy="register-form-email"
            outlined
            placeholder="メールアドレス"
            dense
            hide-details="auto"
            color="primary"
            :value="emailInitialValue"
            @input="updateEmail"
            :rules="rules.email"
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="12" class="py-0 pl-4" align="left">
          <h5>パスワード</h5>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            data-cy="register-form-password"
            outlined
            placeholder="パスワード"
            dense
            hide-details="auto"
            color="primary"
            :type="passwordTypeText"
            :append-icon="passwordTypeIcon"
            @click:append="passwordType = !passwordType"
            :value="passwordInitialValue"
            @input="updatePassword"
            :rules="rules.password"
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="12" class="py-0 pl-4" align="left">
          <h5>パスワード確認</h5>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            data-cy="register-form-password-confirm"
            outlined
            placeholder="パスワード確認"
            dense
            hide-details="auto"
            color="primary"
            :type="passwordConfirmationTypeText"
            :append-icon="passwordConfirmationTypeIcon"
            @click:append="passwordConfirmationType = !passwordConfirmationType"
            :value="passwordConfirmationInitialValue"
            @input="updatePasswordConfirmation"
            :rules="rules.passwordConfirmation"
          />
        </v-col>
      </v-row>
      <v-row class="pb-6">
        <v-col cols="12" align="left" class="pb-0 pt-3">
          <h5 class="font-weight-regular password-hint">
            ※ 半角英数記号のみで、8文字以上入力してください。
          </h5>
        </v-col>
      </v-row>
      <v-row class="pt-8 pl-1">
        <v-col cols="12" class="py-0" align="left">
          <div>
            アカウントを作成するには
            <a
              href="/terms"
              target="_blank"
              data-cy="register-form-atag-terms-link"
              >利用規約</a
            >
            の同意が必要となります。<br />
            また、アカウントを作成する際は
            <a
              href="/privacy"
              target="_blank"
              data-cy="register-form-atag-privacy-link"
              >プライバシーポリシー</a
            >
            が適用されます。
          </div>
        </v-col>
        <v-col cols="12" class="py-0">
          <div class="d-flex justify-center">
            <div>
              <v-checkbox
                data-cy="register-form-primary-checkbox"
                v-model="checkbox"
                color="primary"
                label="利用規約に同意する"
                class="pt-2"
                dense
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="mt-3 pa-0 justify-space-between">
      <v-btn
        data-cy="register-form-first-back-btn"
        text
        color="primary"
        class="font-weight-bold"
        @click="$router.go(-1)"
        v-text="'キャンセル'"
      />
      <v-btn
        data-cy="register-form-second-next-btn"
        elevation="0"
        color="primary"
        class="font-weight-bold white--text px-3"
        width="130"
        @click="create"
        :loading="loading"
      >
        アカウント作成
      </v-btn>
    </v-card-actions>
    <v-row class="mt-14 py-5">
      <v-col cols="12" class="px-0">
        <div class="d-flex justify-end">
          <div>
            <v-btn
              data-cy="register-form-first-help-link"
              text
              color="grey"
              class="font-weight-bold"
              v-text="'ヘルプ'"
              @click="routerPush('/help')"
            />
          </div>
          <div>
            <v-btn
              data-cy="register-form-first-privacy-link"
              text
              color="grey"
              class="font-weight-bold"
              v-text="'プライバシー'"
              @click="routerPush('/privacy')"
            />
          </div>
          <div>
            <v-btn
              data-cy="register-form-first-terms-link"
              text
              color="grey"
              class="font-weight-bold"
              v-text="'規約'"
              @click="routerPush('/terms')"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import validate from '../../../plugins/validate';

export default {
  name: 'RegisterFormSp',
  props: ['value', 'loading'],
  data() {
    return {
      btnDisable: false,
      passwordType: false,
      passwordConfirmationType: false,
      rules: {
        email: [value => validate.userEmail(value)],
        password: [value => validate.userPassword(value)],
        passwordConfirmation: [value => validate.userPassword(value)],
      },
      checkbox: false,
    };
  },
  computed: {
    passwordTypeText() {
      return this.passwordType ? 'text' : 'password';
    },

    passwordConfirmationTypeText() {
      return this.passwordConfirmationType ? 'text' : 'password';
    },

    passwordTypeIcon() {
      return this.passwordType ? 'mdi-eye' : 'mdi-eye-off';
    },

    passwordConfirmationTypeIcon() {
      return this.passwordConfirmationType ? 'mdi-eye' : 'mdi-eye-off';
    },

    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },

    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },

    passwordConfirmationInitialValue() {
      return this.value == null ? null : this.value.password_confirmation;
    },
  },
  methods: {
    create() {
      this.$emit('create', !this.btnDisable, !this.checkbox);
    },

    updateEmail(e) {
      const newValue = {
        ...this.value,
        email: e,
      };
      this.$emit('input', newValue);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },

    routerPush(path) {
      const url = path;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.password-hint {
  color: rgb(0 0 0 / 60%);
}
</style>
