<template>
  <v-stepper v-model="stepperPage" elevation="0">
    <v-stepper-items>
      <v-stepper-content step="1" class="pa-0">
        <v-responsive
          max-width="450"
          style="height: 75vh"
          class="mx-auto d-flex justify-center align-center"
        >
          <template v-if="!$vuetify.breakpoint.xsOnly">
            <ConfirmationEmailFormPc
              v-model="user"
              :loading="loading"
              @sendEmail="sendEmail"
            />
          </template>
          <template v-else>
            <ConfirmationEmailFormSp
              v-model="user"
              :loading="loading"
              @sendEmail="sendEmail"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
      <v-stepper-content step="2" class="pa-0">
        <v-responsive
          max-width="450"
          style="height: 75vh"
          class="mx-auto d-flex justify-center align-center"
        >
          <v-card
            :outlined="$vuetify.breakpoint.mdAndUp"
            flat
            max-width="430"
            class="pt-5 ma-2"
          >
            <v-container>
              <div class="d-flex justify-center">
                <div>
                  <v-img width="140" src="@/assets/img/logo.png" />
                </div>
              </div>
              <v-card-title class="justify-center font-weight-bold mt-3 px-0">
                確認メールの送信
              </v-card-title>
              <v-card-text
                class="mt-2 mb-2"
                data-cy="confirmation-email-form-complete-text"
              >
                {{ user.email }}宛に確認メールを送信しました。<br />
                1時間以内にメール内に記載されているURLにアクセスして登録を完了させてください。
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  class="font-weight-bold"
                  color="primary"
                  elevation="0"
                  text
                  @click="$router.push({ path: '/login' })"
                  v-text="'ログインへ'"
                  data-cy="confirmation-email-form-second-to-login-btn"
                />
              </v-card-actions>
            </v-container>
          </v-card>
        </v-responsive>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ConfirmationEmailFormPc from '../components/pc/ConfirmationEmailForm';
import ConfirmationEmailFormSp from '../components/sp/ConfirmationEmailForm';

export default {
  name: 'UpdatePassword',
  components: {
    ConfirmationEmailFormPc,
    ConfirmationEmailFormSp,
  },
  data() {
    return {
      stepperPage: 1,
      user: {
        email: null,
      },
      loading: false,
    };
  },
  methods: {
    async sendEmail(isInvalid) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      try {
        this.loading = true;
        const result = await this.$axios.post(
          '/api/v1/users/send_confirmation_email',
          {
            user: {
              email: this.user.email,
            },
          }
        );
        if (result.status == 200) this.stepperPage = 2;
        this.loading = false;
      } catch (err) {
        if (err.response.status === 401) {
          this.$router.push({ name: 'Login' });
          this.$emit('logout');
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
