<template>
  <div>
    <v-card
      outlined
      elevation="0"
      width="800"
      min-height="550"
      class="pa-7 mb-1"
    >
      <v-row>
        <v-col cols="12" align="left">
          <v-img width="140" src="@/assets/img/logo.png" />
        </v-col>
      </v-row>
      <v-card-title class="justify-left pl-0">
        <h3>アカウント作成</h3>
      </v-card-title>
      <v-form v-model="btnDisable">
        <v-row class="pt-4">
          <v-col cols="6" class="py-0" align="left">
            <h5>メールアドレス</h5>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              data-cy="register-form-email"
              placeholder="メールアドレス"
              outlined
              dense
              hide-details="auto"
              color="primary"
              :value="emailInitialValue"
              @input="updateEmail"
              :rules="rules.email"
            />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col cols="6" class="py-0" align="left">
            <h5>パスワード</h5>
          </v-col>
          <v-col cols="6" class="py-0" align="left">
            <h5>パスワード確認</h5>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              data-cy="register-form-password"
              placeholder="パスワード"
              outlined
              dense
              hide-details="auto"
              color="primary"
              :type="passwordType"
              :value="passwordInitialValue"
              @input="updatePassword"
              :rules="rules.password"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              data-cy="register-form-password-confirm"
              placeholder="パスワード確認"
              outlined
              dense
              hide-details="auto"
              color="primary"
              :type="passwordType"
              :value="passwordConfirmationInitialValue"
              @input="updatePasswordConfirmation"
              :rules="rules.passwordConfirmation"
            />
          </v-col>
        </v-row>
        <v-row class="pb-6">
          <v-col cols="5" align="left" class="pt-0 pr-0">
            <v-checkbox
              data-cy="register-form-password-checkbox"
              v-model="passwordCheckbox"
              label="パスワードを表示する"
              dense
              color="primary"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="7" align="left" class="pt-0 pl-0">
            <h5 class="pt-3 password-hint font-weight-regular">
              ※ 半角英数記号のみで、8文字以上入力してください。
            </h5>
          </v-col>
        </v-row>
        <v-row class="pt-8 pl-2">
          <v-col cols="12" class="py-0" align="center">
            <div>
              アカウントを作成するには
              <a
                href="/terms"
                target="_blank"
                data-cy="register-form-atag-terms-link"
                >利用規約</a
              >
              の同意が必要です。<br />
              また、アカウントを作成する際は
              <a
                href="/privacy"
                target="_blank"
                data-cy="register-form-atag-privacy-link"
                >プライバシーポリシー</a
              >
              が適用されます。
            </div>
          </v-col>
          <v-col cols="12" class="py-0" align="right">
            <div class="d-flex justify-center">
              <div>
                <v-checkbox
                  data-cy="register-form-primary-checkbox"
                  v-model="termsCheckbox"
                  color="primary"
                  label="利用規約に同意する"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="mt-1 pa-0 justify-space-between">
        <v-btn
          data-cy="register-form-first-back-btn"
          text
          color="primary"
          class="font-weight-bold"
          @click="$router.go(-1)"
          v-text="'キャンセル'"
        />
        <v-btn
          data-cy="register-form-second-next-btn"
          elevation="0"
          color="primary"
          class="font-weight-bold white--text"
          width="140"
          @click="create"
          :loading="loading"
        >
          アカウント作成
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <div>
            <v-btn
              data-cy="register-form-first-help-link"
              text
              color="grey"
              class="font-weight-bold"
              v-text="'ヘルプ'"
              @click="routerPush('/help')"
            />
          </div>
          <div>
            <v-btn
              data-cy="register-form-first-privacy-link"
              text
              color="grey"
              class="font-weight-bold"
              v-text="'プライバシー'"
              @click="routerPush('/privacy')"
            />
          </div>
          <div>
            <v-btn
              data-cy="register-form-first-terms-link"
              text
              color="grey"
              class="font-weight-bold"
              v-text="'規約'"
              @click="routerPush('/terms')"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import validate from '../../../plugins/validate';

export default {
  name: 'RegisterFormPc',
  props: ['value', 'loading'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.userEmail(value)],
        password: [value => validate.userPassword(value)],
        passwordConfirmation: [value => validate.userPassword(value)],
      },
      passwordCheckbox: false,
      termsCheckbox: false,
    };
  },
  computed: {
    passwordType() {
      return this.passwordCheckbox ? 'text' : 'password';
    },

    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },

    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },

    passwordConfirmationInitialValue() {
      return this.value == null ? null : this.value.password_confirmation;
    },
  },
  methods: {
    create() {
      this.$emit('create', !this.btnDisable, !this.termsCheckbox);
    },

    updateEmail(e) {
      const newValue = {
        ...this.value,
        email: e,
      };
      this.$emit('input', newValue);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },

    routerPush(path) {
      const url = path;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.password-hint {
  color: rgb(0 0 0 / 60%);
}
</style>
