<template>
  <v-card class="pr-10 pl-2" flat>
    <v-card-title class="py-5">
      <h2 data-cy="entry-step3-project-name">{{ project.name }}</h2>
    </v-card-title>
    <v-divider class="mb-3"></v-divider>
    <v-card-text>
      <v-row justify="center" class="mt-5">
        <h1 data-cy="entry-step3-text-title">
          {{ userProjectInfo.entered ? '再応募' : '応募' }}が完了しました
          <v-icon
            color="primary"
            class="pb-1"
            v-text="'mdi-check-circle-outline'"
          />
        </h1>
      </v-row>
      <v-row justify="center" class="mt-8">
        <h3 data-cy="entry-step3-text-body-1">
          ※ 企業に推薦の後、進捗がございましたら事務局よりご連絡いたします。
        </h3>
        <h3 data-cy="entry-step3-text-body-2">
          ※
          応募から5営業日以内に事務局から連絡がない場合は見送りとなりますのでご了承ください。
        </h3>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center pt-10">
      <v-btn
        outlined
        color="primary"
        class="font-weight-bold"
        height="50"
        width="300"
        @click="$router.push({ name: 'Projects' })"
        v-text="'閉じる'"
        data-cy="entry-step3-close-btn"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'EntryCompletePc',
  props: ['project', 'userProjectInfo'],
};
</script>
