<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-row v-if="skillSheet != null" class="pt-1">
        <v-col cols="12" class="pb-1">
          <h4>アップロード済</h4>
        </v-col>
        <v-col cols="12" class="pt-0 pl-6">
          <h5 data-cy="skill-sheet-uploaded">
            {{ skillSheetName }}
            <span class="pl-1">
              <v-icon
                data-cy="skill-sheet-download-icon"
                color="#1976D2"
                @click="download"
                v-text="'mdi-download'"
              />
              <v-icon
                data-cy="skill-sheet-delete-icon"
                color="error"
                @click="deleteSkillSheet"
                v-text="'mdi-delete'"
              />
            </span>
          </h5>
        </v-col>
      </v-row>
      <v-row v-else class="pt-5">
        <v-col cols="12" class="py-0" align="left">
          <h4 class="font-weight-regular" data-cy="skill-sheet-not-uploaded">
            アップロードされていません。
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <h4>新規アップロード</h4>
        </v-col>
        <v-col cols="12" class="py-0" align="left">
          <h5 class="font-weight-thin pt-1">
            テンプレートのダウンロードは
            <span
              data-cy="download-template-skill-sheet"
              class="pa-0 primary--text text-decoration-underline"
              :style="`cursor: ${
                loadingByTemplateDownload ? 'wait' : 'pointer'
              };`"
              @click="templateDownload"
              v-text="'こちら'"
            />
          </h5>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-file-input
            data-cy="file-input"
            v-model="fileInput"
            label="ファイルを選択する"
            @change="change"
            :accept="supportedExtension"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-1 mb-1">
      <v-col cols="12" align="right">
        <v-btn
          data-cy="upload-file-btn"
          color="primary white--text"
          class="font-weight-bold"
          small
          elevation="1"
          @click="upload"
          width="130"
          :loading="loadingByUpload"
          :disabled="loadingByUpload"
        >
          アップロード
          <template v-slot:loader>
            <v-progress-circular
              :rotate="-90"
              color="primary"
              size="22"
              width="3"
              :value="uploadProgress"
            />
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixinDate from '../../../const/date';
import mixinSkillSheetExtension from '../../../const/file/skillSheet';

export default {
  name: 'SkillSheetMypageSp',
  props: [
    'skillSheet',
    'loadingByUpload',
    'uploadProgress',
    'loadingByTemplateDownload',
  ],
  mixins: [mixinDate, mixinSkillSheetExtension],
  data() {
    return {
      fileData: null,
      fileInput: null,
      btnDisable: true,
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'SkillSheet');
  },
  computed: {
    skillSheetName() {
      return (
        this.adjustmentFilename(this.skillSheet.filename) +
        ` ( ${this.formatDatetimeWithSec(this.skillSheet.created_at)} )`
      );
    },
  },
  methods: {
    adjustmentFilename(fileName) {
      let maxLength = 10;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          maxLength = 7;
          break;
        case 'sm':
          maxLength = 35;
          break;
        default:
          maxLength = 10;
          break;
      }
      return fileName.length >= maxLength
        ? fileName.substring(0, maxLength) + '...'
        : fileName;
    },

    download() {
      this.$emit('download');
    },

    templateDownload() {
      this.$emit('templateDownload');
    },

    upload() {
      let formData = new FormData();
      if (this.fileData !== null) {
        formData.append(
          'sheet',
          new Blob([this.fileData.data], { type: this.fileData.type }),
          this.fileData.fileName
        );
      } else {
        formData = null;
      }
      if (formData == null) this.btnDisable = true;
      this.$emit('upload', formData, this.btnDisable);
      this.fileData = null;
      this.fileInput = null;
    },

    change(selectedFile) {
      // ファイルを一度選択してから再度選択を試みてキャンセルした際にundefinedになるため。
      if (selectedFile !== null && selectedFile !== undefined) {
        this.fileData = {
          type: selectedFile.type,
          fileName: selectedFile.name,
          data: selectedFile,
        };
        this.btnDisable = false;
        return;
      } else {
        this.fileData = null;
        this.btnDisable = true;
        return;
      }
    },

    deleteSkillSheet() {
      this.$emit('setModal', 'deleteSkillSheet');
    },
  },
};
</script>
