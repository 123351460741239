<template>
  <v-card class="pr-12 pl-4" flat>
    <v-card-title class="mb-2 pt-1 pl-0">
      <h4>退会手続き</h4>
    </v-card-title>
    <v-card-subtitle class="font-weight-bold pl-0" style="font-size: 13px">
      お手数ですが、退会理由をお聞かせください。<br />
      入力後、確認するボタンを押してください。
    </v-card-subtitle>
    <v-card-text class="pl-0">
      <v-row v-if="loading">
        <v-col cols="12" align="center" style="height: 50vh">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              :width="3"
              size="32"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-row>
      <v-form v-model="btnDisable" v-else>
        <v-row class="pt-2">
          <v-col
            cols="12"
            class="py-0"
            v-for="(item, index) in withdrawalReasons"
            :key="index"
          >
            <v-checkbox
              dense
              color="primary"
              hide-details="auto"
              v-model="selectWithdrawalReasons"
              :label="item.text"
              :value="item"
              @change="changeCheckBox"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4 class="pl-1">
              その他の理由や、サイトへの希望などがありましたらご入力ください。
            </h4>
            <v-textarea
              outlined
              dense
              hide-details="auto"
              color="primary"
              class="pt-2"
              :value="otherReasonInitialValue"
              @input="updateOtherReason"
              :rules="rules.otherReason"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12">
          <div class="font-weight-bold annotation pl-3" style="font-size: 13px">
            退会手続きを完了しますと、<br />
            これまで入力いただいた内容や応募履歴などは確認できなくなります。
          </div>
          <div
            class="font-weight-bold annotation pl-3 pt-1"
            style="font-size: 13px"
          >
            次回の営業活動の際に再度入力を行いたくない場合は、退会されずに登録情報を残しておくことをおすすめ致します。
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-space-between pt-3">
      <v-btn
        text
        width="38%"
        height="40px"
        class="font-weight-bold ml-2"
        color="primary"
        @click="$router.push({ path: '/mypage/account' })"
        v-text="'マイページ画面へ戻る'"
        style="font-size: 13px"
      />
      <v-btn
        elevation="0"
        width="38%"
        height="40px"
        color="primary"
        class="white--text font-weight-bold mr-2"
        @click="nextPage"
        v-text="'確認する'"
        style="font-size: 13px"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../../plugins/validate';

export default {
  name: 'WithdrawalFormPc',
  props: ['withdrawalReasons', 'value', 'loading'],
  data() {
    return {
      selectWithdrawalReasons: [],
      btnDisable: false,
      rules: {
        otherReason: [value => validate.withdrawalOtherReason(value)],
      },
    };
  },
  computed: {
    otherReasonInitialValue() {
      return this.value != null ? this.value : null;
    },
  },
  methods: {
    nextPage() {
      this.$emit('nextPage', !this.btnDisable);
    },

    updateOtherReason(e) {
      this.$emit('input', e);
    },

    changeCheckBox() {
      this.$emit('changeWithdrawalReasons', this.selectWithdrawalReasons);
    },
  },
};
</script>

<style scoped lang="scss">
.annotation::before {
  content: '※';
  display: inline-block;
  margin-left: -1.2em;
  width: 0.8em;
}
</style>
