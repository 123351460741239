export default {
  data() {
    return {
      // backendで管理しているRequiredSkillのnameとnumberの対応表
      skillNumbers: {
        javascript: 1,
        java: 2,
        php: 3,
        python: 6,
        ruby: 7,
        rails: 48,
      },
    };
  },
};
