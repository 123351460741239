<template>
  <v-responsive max-width="1161" class="mx-auto" v-if="project">
    <v-stepper
      v-model="stepperPage"
      outlined
      vertical
      elevation="2"
      class="mb-15 mt-5"
    >
      <v-stepper-step
        step="1"
        :complete="stepperPage > 1"
        color="primary"
        data-cy="entry-step1-label"
      >
        必須項目を入力
      </v-stepper-step>
      <v-stepper-content step="1" class="pa-0">
        <v-responsive max-width="1122">
          <v-card
            :class="$vuetify.breakpoint.mdAndUp ? 'pr-14 pl-4' : 'pr-14 pl-1'"
            width="100%"
            flat
          >
            <v-card-title
              :class="$vuetify.breakpoint.mdAndUp ? 'py-5' : 'py-3'"
            >
              <h2 v-text="project.name" data-cy="entry-step1-project-name" />
            </v-card-title>
            <v-divider class="mb-3"></v-divider>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <EntryUserDetailsPc
                :currentUserData="currentUserData"
                :project="project"
                :skillSheet="skillSheet"
                :userSkills="userSkills"
                :loadingByUserSkill="loadingByUserSkill"
                @openAccountDialog="openAccountDialog"
                @closeAccountDialog="closeAccountDialog"
              />
              <EntryUserSkillsPc
                :currentUserData="currentUserData"
                :userSkills="userSkills"
                :skillSheet="skillSheet"
                :loadingByUserSkill="loadingByUserSkill"
                @setSkillInfoDialog="setSkillInfoDialog"
              />
              <AccountDialogPc
                v-model="userDataForEdit"
                :baseInfoDialog="baseInfoDialog"
                :loadingByUpdateUser="loadingByUpdateUser"
                @updateUser="updateUser"
                @close="closeAccountDialog"
              />
              <SkillDialogPc
                ref="skillDialogPc"
                :skillInfoDialog="skillInfoDialog"
                :currentUserData="currentUserData"
                :userSkills="userSkills"
                :skills="skills"
                :skillSheet="skillSheet"
                :loadingByUpload="loadingByUpload"
                :uploadProgress="uploadProgress"
                :loadingByUpdateUser="loadingByUpdateUser"
                :loadingByUpdateSkill="loadingByUpdateSkill"
                :loadingByDeleteSkill="loadingByDeleteSkill"
                :loadingByCreateSkill="loadingByCreateSkill"
                @updateUser="updateUser"
                @upload="upload"
                @download="download"
                @deleteSkillSheet="deleteSkillSheet"
                @setSkillInfoDialog="setSkillInfoDialog"
                @templateDownload="templateDownload"
                @createUserSkills="createUserSkills"
                @updateUserSkills="updateUserSkills"
                @updateOtherSkill="updateOtherSkill"
                @deleteOtherSkill="deleteOtherSkill"
              />
            </template>
            <template v-else>
              <EntryUserDetailsSp
                :currentUserData="currentUserData"
                :project="project"
                :skillSheet="skillSheet"
                :userSkills="userSkills"
                :loadingByUserSkill="loadingByUserSkill"
                @openAccountDialog="openAccountDialog"
                @closeAccountDialog="closeAccountDialog"
              />
              <EntryUserSkillsSp
                :currentUserData="currentUserData"
                :userSkills="userSkills"
                :skillSheet="skillSheet"
                :loadingByUserSkill="loadingByUserSkill"
                @setSkillInfoDialog="setSkillInfoDialog"
              />
              <AccountDialogSp
                v-model="userDataForEdit"
                :baseInfoDialog="baseInfoDialog"
                :loadingByUpdateUser="loadingByUpdateUser"
                @updateUser="updateUser"
                @close="closeAccountDialog"
              />
              <SkillDialogSp
                ref="skillDialogSp"
                :skillInfoDialog="skillInfoDialog"
                :currentUserData="currentUserData"
                :userSkills="userSkills"
                :skills="skills"
                :skillSheet="skillSheet"
                :loadingByUpload="loadingByUpload"
                :uploadProgress="uploadProgress"
                :loadingByUpdateUser="loadingByUpdateUser"
                :loadingByUpdateSkill="loadingByUpdateSkill"
                :loadingByDeleteSkill="loadingByDeleteSkill"
                :loadingByCreateSkill="loadingByCreateSkill"
                @updateUser="updateUser"
                @upload="upload"
                @download="download"
                @deleteSkillSheet="deleteSkillSheet"
                @setSkillInfoDialog="setSkillInfoDialog"
                @templateDownload="templateDownload"
                @createUserSkills="createUserSkills"
                @updateUserSkills="updateUserSkills"
                @updateOtherSkill="updateOtherSkill"
                @deleteOtherSkill="deleteOtherSkill"
              />
            </template>
            <v-card-actions class="justify-space-between mt-3 mb-5">
              <v-btn
                outlined
                width="45%"
                height="40px"
                class="font-weight-bold"
                color="primary"
                @click="$router.push({ path: `/project/${project.id}` })"
                v-text="'キャンセル'"
                data-cy="entry-step1-cancel-btn"
              />
              <v-btn
                elevation="0"
                width="45%"
                height="40px"
                color="primary"
                class="white--text font-weight-bold"
                @click="nextPage"
                v-text="'次へ'"
                data-cy="entry-step1-next-btn"
              />
            </v-card-actions>
          </v-card>
        </v-responsive>
      </v-stepper-content>
      <v-divider class="mt-6" />
      <v-stepper-step
        step="2"
        class="ma-1"
        :complete="stepperPage > 2"
        color="primary"
        data-cy="entry-step2-label"
      >
        最終確認
      </v-stepper-step>
      <v-stepper-content step="2" class="pa-0">
        <v-responsive max-width="1122">
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <EntryFormPc
              :project="project"
              :user="currentUserData"
              :skillSheet="skillSheet"
              :entryLoading="entryLoading"
              :projectSkills="projectSkills"
              :userProjectInfo="userProjectInfo"
              @createEntry="createEntry"
              @reEntry="reEntry"
              @backPage="backPage"
            />
          </template>
          <template v-else>
            <EntryFormSp
              :project="project"
              :user="currentUserData"
              :skillSheet="skillSheet"
              :entryLoading="entryLoading"
              :projectSkills="projectSkills"
              :userProjectInfo="userProjectInfo"
              @createEntry="createEntry"
              @reEntry="reEntry"
              @backPage="backPage"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
      <v-divider class="mt-7" />
      <v-stepper-step step="3" color="primary" data-cy="entry-step3-label">
        {{ userProjectInfo.entered ? '再応募' : '応募' }}完了
      </v-stepper-step>
      <v-stepper-content step="3" class="pa-0">
        <v-responsive max-width="1122">
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <EntryCompletePc
              :project="project"
              :userProjectInfo="userProjectInfo"
            />
          </template>
          <template v-else>
            <EntryCompleteSp
              :project="project"
              :userProjectInfo="userProjectInfo"
            />
          </template>
        </v-responsive>
      </v-stepper-content>
    </v-stepper>
  </v-responsive>
</template>

<script>
import session from '../plugins/session';
import EntryFormPc from '../components/pc/entry/EntryForm.vue';
import EntryFormSp from '../components/sp/entry/EntryForm.vue';
import AccountDialogPc from '../components/pc/entry/AccountDialog.vue';
import AccountDialogSp from '../components/sp/entry/AccountDialog.vue';
import SkillDialogPc from '../components/pc/entry/SkillDialog.vue';
import SkillDialogSp from '../components/sp/entry/SkillDialog.vue';
import EntryUserDetailsPc from '../components/pc/entry/EntryUserDetails.vue';
import EntryUserDetailsSp from '../components/sp/entry/EntryUserDetails.vue';
import EntryUserSkillsPc from '../components/pc/entry/EntryUserSkills.vue';
import EntryUserSkillsSp from '../components/sp/entry/EntryUserSkills.vue';
import EntryCompletePc from '../components/pc/entry/EntryComplete.vue';
import EntryCompleteSp from '../components/sp/entry/EntryComplete.vue';

export default {
  name: 'Entry',
  components: {
    EntryFormPc,
    EntryFormSp,
    AccountDialogPc,
    AccountDialogSp,
    SkillDialogPc,
    SkillDialogSp,
    EntryUserDetailsPc,
    EntryUserDetailsSp,
    EntryUserSkillsPc,
    EntryUserSkillsSp,
    EntryCompletePc,
    EntryCompleteSp,
  },
  data() {
    return {
      stepperPage: 1,
      project: null,
      currentUserData: null,
      userDataForEdit: null,
      skillSheet: null,
      baseInfoDialog: false,
      skillInfoDialog: false,
      entryLoading: false,
      loadingByUpload: false,
      uploadProgress: 0,
      loadingByUserSkill: true,
      loadingByCreateSkill: false,
      loadingByUpdateUser: false,
      loadingByDeleteSkill: false,
      loadingByUpdateSkill: false,
      skills: [],
      projectSkills: {
        languages: [],
        frameworks: [],
        others: [],
      },
      userSkills: {
        languages: [],
        frameworks: [],
        others: [],
      },
      userProjectInfo: {
        bookmarkId: null,
        entered: false,
        entryDetail: null,
      },
    };
  },
  created() {
    this.getProject().then(() => {
      this.getUserProjectInfo();
      this.getSkills().then(() => {
        this.setSkills();
        this.getUser().then(() => {
          this.setUserSkills().then(() => {
            this.loadingByUserSkill = false;
          });
        });
      });
    });
    this.getSkillSheet();
  },
  methods: {
    async getProject() {
      try {
        const result = await this.$axios.get(
          '/api/v1/projects/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        this.project = result.data;
      } catch (err) {
        switch (err.response.status) {
          case 401:
            this.$emit('logout');
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定した案件が見つかりません。'
            );
            this.$router.push({ name: 'Projects' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            this.$router.push({ name: 'Projects' });
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            this.$router.push({ name: 'Projects' });
            break;
        }
      }
    },

    async getUserProjectInfo() {
      try {
        const result = await this.$axios.get(
          '/api/v1/users/project_info/' + this.project.id,
          {
            headers: session.apiAuthHeaders(),
          }
        );
        this.userProjectInfo = result.data;
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getUser() {
      try {
        const result = await this.$axios.get('/api/v1/users/mypage', {
          headers: session.apiAuthHeaders(),
        });
        this.currentUserData = result.data;
      } catch (err) {
        if (err.response.status == 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' }).catch(() => {});
          return;
        }
      }
    },

    async getSkillSheet() {
      try {
        const result = await this.$axios.get('/api/v1/skill_sheets/detail', {
          headers: session.apiAuthHeaders(),
        });
        this.skillSheet = result.data;
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' }).catch(() => {});
          return;
        }
      }
    },

    async updateUser(
      btnDisable,
      skillUpdateFlag,
      updateSkills,
      skillDeleteFlag
    ) {
      this.loadingByUpdateUser = true;
      if (btnDisable) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      try {
        const user = skillUpdateFlag
          ? this.currentUserData
          : this.userDataForEdit;
        const result = await this.$axios.patch(
          '/api/v1/users/' + this.currentUserData.id,
          { user: user, user_skills: updateSkills },
          { headers: session.apiAuthHeaders() }
        );

        this.currentUserData = result.data;
        this.userDataForEdit = null;
        this.baseInfoDialog = false;
        session.setUidToItemKeys(result.headers.uid);
        const message = skillDeleteFlag ? '削除' : '更新';
        this.$emit('showSnackbar', 'success', `${message}に成功しました。`);
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.$refs.skillDialogPc.resetCreateSkillValue();
        } else {
          this.$refs.skillDialogSp.resetCreateSkillValue();
        }
        this.setUserSkills().then(() => {
          this.loadingByUpdateUser = false;
        });
      } catch (err) {
        this.loadingByUpdateUser = false;
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async download() {
      try {
        const result = await this.$axios.get('/api/v1/skill_sheets/download', {
          headers: session.apiAuthHeaders(),
          responseType: 'blob',
        });
        if (result.status === 200 && result.data) {
          // Generate response data.
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.download = this.skillSheet.filename;
          a.href = url;
          a.click();
          // Remove a tag only use downloading.
          a.remove();
          URL.revokeObjectURL(url);
        }
      } catch (err) {
        switch (err.response.status) {
          case 401:
            this.$emit('logout');
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              'スキルシートがアップロードされていません。'
            );
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              'スキルシートのダウンロードに失敗しました。'
            );
            break;
        }
      }
    },

    async templateDownload() {
      try {
        const result = await this.$axios.get(
          '/api/v1/skill_sheets/template_download',
          {
            headers: session.apiAuthHeaders(),
            responseType: 'blob',
          }
        );
        if (result.status === 200 && result.data) {
          // Generate response data.
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.download = 'skill_sheet_template.xlsx';
          a.href = url;
          a.click();
          // Remove a tag only use downloading.
          a.remove();
          URL.revokeObjectURL(url);
        }
      } catch (err) {
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async upload(formData, btnDisable) {
      if (btnDisable) {
        this.$emit('showSnackbar', 'error', 'ファイルを選択してください');
        return;
      }
      try {
        this.loadingByUpload = true;
        this.uploadProgress = 0;

        const config = {
          headers: session.fileApiAuthHeaders(),
          onUploadProgress: progressEvent => {
            const progressRate = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.uploadProgress = progressRate;
          },
        };

        const result = await this.$axios.post(
          '/api/v1/skill_sheets',
          formData,
          config
        );

        this.skillSheet = result.data;
        this.$emit('showSnackbar', 'primary', 'アップロードに成功しました。');
      } catch (err) {
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      } finally {
        this.loadingByUpload = false;
      }
    },

    async deleteSkillSheet() {
      try {
        const result = await this.$axios.delete(
          '/api/v1/skill_sheets/' + this.currentUserData.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 204) {
          this.skillSheet = null;
          this.$emit(
            'showSnackbar',
            'primary',
            'スキルシートが削除されました。'
          );
        }
      } catch (err) {
        switch (err.response.status) {
          case 401:
            this.$emit('logout');
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              'スキルシートアップロードされていません。'
            );
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    nextPage() {
      const checklist = Object.keys(this.currentUserData);
      // 応募の必須項目から外すkey
      const skipValidKeys = [
        'desired_cost',
        'note',
        'user_skills',
        'other_skills',
      ];
      const isValid = checklist.every(key => {
        if (skipValidKeys.includes(key)) {
          return true;
        } else {
          return (
            this.currentUserData[key] !== null &&
            this.currentUserData[key] !== ''
          );
        }
      });

      if (isValid && this.skillSheet) {
        this.stepperPage = 2;
      } else {
        this.$emit(
          'showSnackbar',
          'error',
          '必須項目が正しく入力されていません。'
        );
      }
    },

    async createEntry() {
      try {
        this.entryLoading = true;
        await this.$axios.post(
          '/api/v1/entries',
          {
            entry: {
              admin_id: null,
              project_id: this.project.id,
              status: 0,
              priority: 1,
              evaluation: 0,
            },
          },
          { headers: session.apiAuthHeaders() }
        );
        this.entryLoading = false;
        this.stepperPage = 3;
      } catch (err) {
        this.entryLoading = false;
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if ([400, 404].includes(err.response.status)) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          if (err.response.status === 404)
            this.$router.push({ name: 'Projects' });
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async reEntry() {
      try {
        this.entryLoading = true;
        await this.$axios.get(
          `/api/v1/entries/${this.userProjectInfo.entryDetail.id}/re_entry`,
          { headers: session.apiAuthHeaders() }
        );
        this.entryLoading = false;
        this.stepperPage = 3;
      } catch (err) {
        this.entryLoading = false;
        if (err.response.status === 401) {
          this.$emit('logout');
          this.$router.push({ name: 'Login' });
          return;
        }
        if ([400, 404].includes(err.response.status)) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          if (err.response.status === 404)
            this.$router.push({ name: 'Projects' });
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/required_skills', {
          headers: { 'Content-Type': 'application/json' },
        });
        if (result.status == 200) this.skills = result.data;
      } catch (err) {
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    setSkills() {
      this.projectSkills = {
        languages: [],
        frameworks: [],
        others: [],
      };
      this.project.project_skills.forEach(skill => {
        const targetValue = this.skills.find(targetSkill => {
          return skill.required_skill_id === targetSkill.id;
        });
        if (targetValue) {
          switch (targetValue.category) {
            case 0:
              this.projectSkills.languages.push(targetValue);
              break;
            case 1:
              this.projectSkills.frameworks.push(targetValue);
              break;
            case 2:
              this.projectSkills.others.push(targetValue);
              break;
            default:
              break;
          }
        }
      });
    },

    async setUserSkills() {
      this.userSkills = {
        languages: [],
        frameworks: [],
        others: [],
      };
      this.currentUserData.user_skills.forEach(skill => {
        const targetValue = this.skills.find(targetSkill => {
          return skill.required_skill_id === targetSkill.id;
        });
        if (targetValue) {
          switch (targetValue.category) {
            case 0:
              this.userSkills.languages.push({
                ...targetValue,
                experience_year: skill.experience_year,
              });
              break;
            case 1:
              this.userSkills.frameworks.push({
                ...targetValue,
                experience_year: skill.experience_year,
              });
              break;
            case 2:
              this.userSkills.others.push({
                ...targetValue,
                experience_year: skill.experience_year,
              });
              break;
            default:
              break;
          }
        }
      });
    },

    currentSkills() {
      if (this.currentUserData.user_skills.length === 0) return [];
      const v = this.currentUserData.user_skills.map(skill => {
        return {
          required_skill_id: skill.required_skill_id,
          experience_year: skill.experience_year,
        };
      });
      return v;
    },

    createUserSkills(skillValues) {
      if (skillValues.length === 0) {
        this.$emit('showSnackbar', 'error', 'スキルを選択してください。');
        return;
      }
      const currentSkills = this.currentUserData.user_skills.map(skill => {
        return {
          required_skill_id: skill.required_skill_id,
          experience_year: skill.experience_year,
        };
      });
      this.loadingByCreateSkill = true;
      this.updateUser(false, true, currentSkills.concat(skillValues)).then(
        () => {
          this.loadingByCreateSkill = false;
          this.closeSkillModal();
        }
      );
    },

    updateUserSkills(type, skillValues) {
      this.setSkillLoadingStatus(type, true);
      this.updateUser(false, true, skillValues, type === 'delete').then(() => {
        this.setSkillLoadingStatus(type, false);
        this.closeSkillModal();
      });
    },

    closeSkillModal(type) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.$refs.skillDialogPc.setSkillModal(false, type);
        this.$refs.skillDialogPc.setOtherSkillModal(false, type);
      } else {
        this.$refs.skillDialogSp.setSkillModal(false, type);
        this.$refs.skillDialogSp.setOtherSkillModal(false, type);
      }
    },

    setSkillLoadingStatus(type, value) {
      if (type === 'update') {
        this.loadingByUpdateSkill = value;
      } else {
        this.loadingByDeleteSkill = value;
      }
    },

    updateOtherSkill(type, otherSkillValue) {
      // NOTE:
      // type = 'create' || 'edit'
      // otherSkillValue = { target: 空文字(create) or 変更する対象(update), newValue: 更新する値 }
      if (otherSkillValue.newValue === '') {
        this.$emit('showSnackbar', 'error', 'スキル名を入力してください。');
        return;
      }

      if (
        type == 'edit' &&
        otherSkillValue.target == otherSkillValue.newValue
      ) {
        this.$emit('showSnackbar', 'success', '更新に成功しました。');
        this.closeSkillModal(type);
        return;
      } else {
        if (
          this.currentUserData.other_skills &&
          this.currentUserData.other_skills.includes(otherSkillValue.newValue)
        ) {
          this.$emit('showSnackbar', 'error', '既にスキルが登録されています。');
          return;
        }
      }

      if (type === 'create') {
        // 登録
        if (this.currentUserData.other_skills) {
          this.currentUserData.other_skills.push(otherSkillValue.newValue);
        } else {
          // other_skillsがnilの場合に下記で対応
          this.currentUserData.other_skills = [otherSkillValue.newValue];
        }
      } else {
        // 更新
        let removedOtherSkill = this.currentUserData.other_skills.filter(
          skill => {
            return skill !== otherSkillValue.target;
          }
        );
        removedOtherSkill.push(otherSkillValue.newValue);
        this.currentUserData.other_skills = removedOtherSkill;
      }
      this.updateUser(false, true, this.currentSkills()).then(() => {
        this.closeSkillModal(type);
      });
    },

    deleteOtherSkill(deleteOtherSkill) {
      let removedOtherSkill = this.currentUserData?.other_skills.filter(
        skill => {
          return skill !== deleteOtherSkill;
        }
      );
      this.currentUserData.other_skills = removedOtherSkill;
      this.updateUser(false, true, this.currentSkills(), true).then(() => {
        this.closeSkillModal('delete');
      });
    },

    openAccountDialog() {
      this.userDataForEdit = JSON.parse(JSON.stringify(this.currentUserData));
      this.baseInfoDialog = true;
    },

    closeAccountDialog() {
      this.userDataForEdit = null;
      this.baseInfoDialog = false;
    },

    setSkillInfoDialog(value) {
      this.skillInfoDialog = value;
    },

    backPage() {
      this.stepperPage = 1;

      this.getProject();
      this.getUser();
      this.getSkillSheet();
    },
  },
};
</script>
