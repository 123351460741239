<template>
  <div class="pb-9">
    <v-card outlined elevation="0" class="px-0 pt-0 pb-0">
      <v-data-table
        :headers="entriesHeaders"
        :items="entries"
        :items-per-page="10"
        :page.sync="page"
        loading-text="Loading..."
        no-data-text="応募の履歴は存在しません。"
        @page-count="pageCount = $event"
        hide-default-footer
        :loading="loadingByEntry"
      >
        <template v-slot:[`item.project`]="{ item }">
          <router-link
            :to="{ name: 'Project', params: { id: item.project.id } }"
            target="_blank"
            v-if="item.project.public_project"
            v-text="item.project.name"
          />
          <div v-else v-text="item.project.name" />
        </template>

        <template v-slot:[`item.internal_interview`]="{ item }">
          <div class="pr-4" v-text="item.internal_interview ? 'あり' : '-'" />
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="pr-4">
            <v-chip
              :color="getEntryStatus(item.status).color"
              dense
              v-if="item.project.public_project"
              v-text="status(item)"
            />
            <div v-else v-text="'-'" />
          </div>
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
          <div class="pr-4" v-text="updatedAt(item)" />
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            v-if="item.cancelable_flag"
            color="error"
            elevation="1"
            @click="entryCancel(item.id, item.cancelable_flag)"
            v-text="'mdi-close-circle-outline'"
          />
        </template>
      </v-data-table>
      <v-divider />
      <div class="text-center py-2" v-if="entries.length > 10">
        <v-pagination v-model="page" :length="pageCount" color="primary" />
      </div>
    </v-card>
  </div>
</template>

<script>
import mixinDate from '../../../const/date';
import mixinEntryEvaluation from '../../../const/entry/evaluation';
import mixinEntryStatus from '../../../const/entry/status';
import mixinEntryPriority from '../../../const/entry/priority';

export default {
  name: 'EntryMypage',
  props: ['entries', 'loadingByEntry'],
  mixins: [
    mixinDate,
    mixinEntryEvaluation,
    mixinEntryStatus,
    mixinEntryPriority,
  ],
  data() {
    return {
      page: 1,
      pageCount: 0,
      selectedEntries: [],
      entriesHeaders: [
        {
          text: '案件名',
          align: 'center',
          value: 'project',
          sortable: false,
          width: '42%',
        },
        {
          text: '事前面談',
          align: 'center',
          value: 'internal_interview',
          width: '12%',
        },
        { text: '進行状況', align: 'center', value: 'status', width: '13%' },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '20%',
        },
        {
          text: 'キャンセル',
          align: 'center',
          value: 'action',
          sortable: false,
          width: '13%',
        },
      ],
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Entry');
  },
  computed: {
    updatedAt() {
      return entry => {
        if (!entry.project.public_project) return '-';

        return this.formatDatetime(entry.updated_at);
      };
    },

    evaluation() {
      return entry => {
        if (!entry.project.public_project) return '-';
        return this.getEntryEvaluation(entry.evaluation).value;
      };
    },

    status() {
      return entry => {
        if (!entry.project.public_project) return '-';
        return this.getEntryStatus(entry.status).value;
      };
    },

    cancelableIconColor() {
      return cancelableFlag => {
        return cancelableFlag ? 'error' : 'grey';
      };
    },
  },
  methods: {
    getEntryEvaluation(eachNum) {
      const targetValue = this.entryEvaluation.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getEntryPriority(eachNum) {
      const targetValue = this.entryPriority.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getEntryStatus(eachNum) {
      const targetValue = this.entryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    entryCancel(entryId, cancelableFlag) {
      if (cancelableFlag) {
        this.$emit('setModal', 'entryCancel', entryId);
      }
    },
  },
};
</script>
