<template>
  <v-card
    outlined
    link
    hover
    @click="checkRouterPush('v-card')"
    class="pt-1 mb-5"
  >
    <v-card-title class="py-2 font-weight-bold">
      <v-row>
        <v-col cols="12" class="pt-1 pb-0" style="height: 38px">
          <span
            class="font-weight-bold"
            style="font-size: 14px"
            v-text="'案件番号: ' + projectNumber(project.id)"
          />
        </v-col>
        <v-col cols="12" class="pt-0">
          <div>
            <v-chip
              dense
              label
              outlined
              color="black"
              class="mr-3 mb-1"
              v-if="isAlreadyEntry(project.id)"
              v-text="'応募済み'"
            />
            <v-chip
              dense
              label
              outlined
              :color="vChipColor(project)"
              class="mr-2 mb-1"
              v-if="vChipVisibility(project)"
              v-text="vChipText(project)"
            />
            <span class="pt-3" style="font-size: 19px" v-text="project.name" />
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <div class="px-2">
      <v-card-text class="px-0 pb-0">
        <v-container>
          <v-row>
            <v-col cols="12" class="py-0 pr-0">
              <div class="d-flex">
                <div class="pr-1">
                  <v-icon v-text="'mdi-cash'" />
                </div>
                <div class="">
                  <h3 class="accent--text">
                    {{ costPrice(project.cost) }}
                    <span style="color: black">/月</span>
                  </h3>
                </div>
                <div class="pl-1" v-if="!$vuetify.breakpoint.xsOnly">
                  <h5>{{ paymentSpan(project) }}</h5>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              align="left"
              class="pl-2 pt-0 pb-1"
              v-if="this.$vuetify.breakpoint.xsOnly"
            >
              <h5>{{ paymentSpan(project) }}</h5>
            </v-col>
            <v-col :cols="vColSize()" class="py-0">
              <h4 class="pt-1">
                <span>
                  <v-icon class="pb-1" v-text="'mdi-map-search-outline'" />
                </span>
                {{ municipalityName(project.municipality) }}
              </h4>
            </v-col>
            <v-col :cols="vColSize()" class="py-0">
              <h4 class="pt-1">
                <span>
                  <v-icon
                    class="pb-1"
                    v-text="'mdi-map-marker-radius-outline'"
                  />
                </span>
                {{ project.station }}
              </h4>
            </v-col>
            <v-col :cols="vColSize('workLocation')" class="py-0">
              <h4 class="pt-1">
                <span>
                  <v-icon class="pb-1" v-text="'mdi-account-outline'" />
                </span>
                {{ workLocation(project.work_location) }}
              </h4>
            </v-col>
          </v-row>
          <v-row v-if="project.project_skills.length !== 0">
            <v-col cols="12" class="pb-0 pt-1">
              <v-chip
                v-for="(skill, index) in project.project_skills"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="getSkillName(skill.required_skill_id)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-1">
              <v-textarea
                outlined
                dense
                readonly
                hide-details
                auto-grow
                color="primary"
                rows="1"
                class="mt-2"
                label="業務内容"
                :value="prefixDescription(projectDetails)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="my-2 justify-space-between">
        <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
        <!-- <v-btn
          v-if="vChipColor(project) !== 'black'"
          elevation="0"
          outlined
          class="font-weight-bold ml-2"
          color="primary"
          width="45%"
          :style="fontSize"
          @click="checkRouterPush('bookmark')"
        > -->
        <v-btn
          elevation="0"
          outlined
          class="font-weight-bold ml-2"
          color="primary"
          width="45%"
          :style="fontSize"
          @click="checkRouterPush('bookmark')"
        >
          <v-icon color="primary" v-text="bookmarkIcon(project.id)" />
          <h4 v-text="bookmarkText(project.id)" />
        </v-btn>
        <v-spacer />
        <router-link
          :to="{ name: 'Project', params: { id: project.id } }"
          class="router-link"
          style="width: 45%"
        >
          <v-btn
            elevation="0"
            class="font-weight-bold mr-2"
            color="primary white--text"
            width="100%"
            :style="fontSize"
            @click="checkRouterPush('projectDetails')"
            v-text="'詳細を見る'"
          />
        </router-link>
        <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
        <!-- <v-spacer v-if="vChipColor(project) === 'black'" /> -->
        <v-spacer />
      </v-card-actions>
    </div>
  </v-card>
</template>

<style scoped>
/* 各スキルのホバー表示を消す */
.skills.v-chip {
  pointer-events: none;
}
.router-link {
  text-decoration: none;
}
</style>

<script>
import mixinMunicipalities from '../../../const/project/municipality';
import mixinWorkLocation from '../../../const/project/workLocation';
import common from '../../../plugins/common';

export default {
  name: 'ProjectCardSp',
  props: ['project', 'bookmarks', 'entries', 'skills'],
  mixins: [mixinMunicipalities, mixinWorkLocation],
  data() {
    return {
      isLink: true,
    };
  },
  computed: {
    projectNumber() {
      return projectId => {
        return common.formatProjectNum(projectId);
      };
    },

    projectDetails() {
      const { project } = this.$props;
      // 見栄え統一のため改行を削除して結合
      const repDes = project.description.replace(/\n/g, '');
      const repReq = project.require_skill.replace(/\n/g, '');
      const repNote = project.note ? project.note.replace(/\n/g, '') : '';
      return `${repDes}${repReq}${repNote}`;
    },

    fontSize() {
      return this.$vuetify.breakpoint.xsOnly ? 'font-size: 12px' : '';
    },

    vColSize() {
      return type => {
        if (this.$vuetify.breakpoint.xsOnly) {
          return type == 'workLocation' ? '12' : '6';
        } else {
          return '4';
        }
      };
    },

    vChipVisibility() {
      return project => {
        const dayjs = require('dayjs');
        const now = dayjs();
        const newProjectFinish = dayjs(project.new_project_finish_at);
        if (!newProjectFinish.isValid()) return false;
        const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
        dayjs.extend(isSameOrAfter);
        // 新着終了日を過ぎていない場合はtrue
        if (
          newProjectFinish.format('YYYY-MM-DD') == now.format('YYYY-MM-DD') ||
          newProjectFinish.isSameOrAfter(now)
        ) {
          return true;
        } else {
          // 募集終了日が過ぎている場合はtrue、それ以外はfalse
          const recruitmentFinish = dayjs(project.recruitment_finish_at);
          if (!recruitmentFinish.isValid()) return false;

          // TODO: QA-480 募集終了を復活させる場合はコメントアウトを復活させる
          // return now.isSameOrAfter(recruitmentFinish);
          return false;
        }
      };
    },

    vChipText() {
      return project => {
        const dayjs = require('dayjs');
        const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
        dayjs.extend(isSameOrAfter);
        const now = dayjs();
        const recruitmentFinish = dayjs(project.recruitment_finish_at);
        if (!recruitmentFinish.isValid()) return false;
        return now.isSameOrAfter(recruitmentFinish) ? '募集終了' : 'new';
      };
    },

    vChipColor() {
      return project => {
        const dayjs = require('dayjs');
        const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
        dayjs.extend(isSameOrAfter);
        const now = dayjs();
        const recruitmentFinish = dayjs(project.recruitment_finish_at);
        if (!recruitmentFinish.isValid()) return 'black';

        return now.isSameOrAfter(recruitmentFinish) ? 'black' : 'accent';
      };
    },

    bookmarkIcon() {
      return projectId => {
        return this.isBookmark(projectId)
          ? 'mdi-bookmark'
          : 'mdi-bookmark-outline';
      };
    },

    bookmarkText() {
      return projectId => {
        return this.isBookmark(projectId)
          ? 'ブックマーク済み'
          : 'ブックマークする';
      };
    },

    isAlreadyEntry() {
      return projectId => {
        return (
          this.entries.filter(element => {
            return element.projectId === projectId;
          }).length != 0
        );
      };
    },

    costPrice() {
      return cost => {
        return cost != null ? `${cost}万円` : '';
      };
    },

    workLocation() {
      return workLocationEachNum => {
        return this.workLocations.find(
          element => element.id === workLocationEachNum
        ).value;
      };
    },

    municipalityName() {
      return municipalityEachNum => {
        return this.municipalities.find(
          element => element.id === municipalityEachNum
        ).value;
      };
    },

    prefixDescription() {
      return description => {
        const maxlength = 80;
        const abbreviation = description.length > maxlength ? '...' : '';
        return description.substring(0, maxlength) + abbreviation;
      };
    },

    paymentSpan() {
      return project => {
        return `（ 精算幅: ${project.work_time_start}-${project.work_time_finish}h、 支払いサイト: 30日 ）`;
      };
    },
  },
  methods: {
    changeBookmark() {
      this.$emit(
        'changeBookmark',
        this.project.id,
        this.isBookmark(this.project.id)
      );
    },

    linkToProject() {
      if (this.isLink) {
        this.$router
          .push({ name: 'Project', params: { id: this.project.id } })
          .catch(() => {});
      }
    },

    checkRouterPush(type) {
      switch (type) {
        case 'v-card':
          this.linkToProject();
          this.isLink = true;
          return;
        case 'projectDetails':
          this.linkToProject();
          this.isLink = true;
          return;
        case 'bookmark':
          this.isLink = false;
          this.changeBookmark();
          return;
        default:
          return;
      }
    },

    isBookmark(projectId) {
      const result = this.bookmarks.filter(element => {
        return element.projectId === projectId;
      });
      return result.length != 0 ? true : false;
    },

    getSkillName(skillId) {
      return this.skills.find(skill => skill.id === skillId)?.name;
    },
  },
};
</script>
