<template>
  <v-container>
    <v-responsive max-width="1161" class="mx-auto">
      <v-container class="px-0 d-flex">
        <v-row>
          <v-col cols="5">
            <h2>マイページ</h2>
          </v-col>
          <v-col cols="7" v-if="!vListItemGroupBreakPoint">
            <v-select
              data-cy="mypage-side-menu-sp"
              outlined
              hide-details="auto"
              dense
              color="primary"
              item-color="primary"
              :items="sideHeaders"
              item-text="text"
              item-value="pageName"
              class="font-weight-medium"
              :value="vSelectValue"
              @change="routerPushName"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  :class="
                    attrs['aria-selected'] == 'true'
                      ? 'font-weight-bold'
                      : 'font-weight-medium'
                  "
                >
                  {{ item.text }}
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-container class="mt-4 pa-0">
        <v-row>
          <v-col cols="3" class="mt-16" v-if="vListItemGroupBreakPoint">
            <v-card outlined elevation="0" class="mx-auto" width="100%">
              <v-list dense>
                <v-list-item-group :value="selectedPage" color="primary">
                  <v-list-item
                    data-cy="mypage-side-menu-pc"
                    v-for="item in sideHeaders"
                    :key="item.pageId"
                    @click="routerPushName(item.pageName)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.text"
                        class="font-weight-bold text--secondary"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" md="9" xl="9" lg="9">
            <v-toolbar elevation="0">
              <v-toolbar-title>
                <h4>{{ vToolbarTitle }}</h4>
              </v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <router-view
              ref="childMypage"
              v-model="user"
              :entries="entries"
              :bookmarks="bookmarks"
              :skillSheet="skillSheet"
              :name="displayAsPc"
              :skills="skills"
              :userSkills="userSkills"
              :loadingByBookmark="loadingByBookmark"
              :loadingByEntry="loadingByEntry"
              :loadingByAccountInfo="loadingByAccountInfo"
              :loadingByEmail="loadingByEmail"
              :loadingByPassword="loadingByPassword"
              :loadingByUpload="loadingByUpload"
              :loadingByUserSkill="loadingByUserSkill"
              :loadingByCreateSkill="loadingByCreateSkill"
              :loadingByUpdateSkill="loadingByUpdateSkill"
              :loadingByDeleteSkill="loadingByDeleteSkill"
              :loadingByTemplateDownload="loadingByTemplateDownload"
              :uploadProgress="uploadProgress"
              @setSelectPage="setSelectPage"
              @update="update"
              @download="download"
              @upload="upload"
              @createUserSkills="createUserSkills"
              @updateUserSkills="updateUserSkills"
              @updateOtherSkill="updateOtherSkill"
              @deleteOtherSkill="deleteOtherSkill"
              @deleteSkillSheet="deleteSkillSheet"
              @deleteBookmark="deleteBookmark"
              @templateDownload="templateDownload"
              @setModal="setModal"
            />
          </v-col>
        </v-row>
      </v-container>
      <ConfirmModal
        ref="child"
        :type="modal.type"
        :dialog="modal.show"
        :title="modal.title"
        :text="modal.text"
        :yesColor="modal.yesColor"
        :noColor="modal.noColor"
        :yesBtn="modal.yesBtn"
        @commit="commitAction"
        @close="closeAction(modal.type)"
      />
    </v-responsive>
  </v-container>
</template>

<script>
import session from '../plugins/session';
import ConfirmModal from '../components/common/ConfirmModal.vue';

export default {
  name: 'UserMypage',
  components: {
    ConfirmModal,
  },
  data() {
    return {
      user: {
        id: null,
        email: null,
        last_name: null,
        last_name_kana: null,
        first_name: null,
        first_name_kana: null,
        password: null,
        password_confirmation: null,
        birth: null,
        gender: null,
        current_status: null,
        closest_station: null,
        live_area: null,
        nationality: null,
        note: null,
        tel: null,
        current_password: null,
        unconfirmed_email: null,
        other_skills: [],
        email_notification_flag: null,
      },
      bookmarks: [],
      loadingByBookmark: true,
      entries: [],
      loadingByEntry: true,
      skillSheet: null,
      skills: [],
      userSkills: {
        languages: [],
        frameworks: [],
        others: [],
      },
      loadingByUserSkill: true,
      loadingByUpdateSkill: false,
      loadingByCreateSkill: false,
      loadingByDeleteSkill: false,
      selectedPage: 0,
      sideHeaders: [
        { pageId: 0, text: '基本情報', pageName: 'AccountMypage' },
        { pageId: 1, text: 'スキル', pageName: 'SkillMypage' },
        { pageId: 2, text: 'メールアドレス変更', pageName: 'EmailMypage' },
        { pageId: 3, text: 'パスワード変更', pageName: 'PasswordMypage' },
        { pageId: 4, text: 'スキルシート', pageName: 'SkillSheetMypage' },
        { pageId: 5, text: 'ブックマーク一覧', pageName: 'BookmarksMypage' },
        { pageId: 6, text: '応募履歴', pageName: 'EntryMypage' },
      ],
      vSelectValue: 'AccountMypage',
      modal: {
        type: null,
        show: false,
        title: null,
        text: null,
        yesColor: null,
        noColor: null,
        yesBtn: null,
        functionArg: null,
      },
      loadingByAccountInfo: false,
      loadingByEmail: false,
      loadingByPassword: false,
      loadingByUpload: false,
      uploadProgress: 0,
      loadingByTemplateDownload: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.vSelectValue = to.name;
    });
  },
  beforeRouteUpdate(to, form, next) {
    this.vSelectValue = to.name;
    next();
  },
  computed: {
    vListItemGroupBreakPoint() {
      return this.$vuetify.breakpoint.mdAndUp;
    },

    displayAsPc() {
      return this.$vuetify.breakpoint.mdAndUp ? 'pc' : 'sp';
    },

    vToolbarTitle() {
      switch (this.selectedPage) {
        case 0:
          return '基本情報';
        case 1:
          return 'スキル';
        case 2:
          return 'メールアドレス変更';
        case 3:
          return 'パスワード変更';
        case 4:
          return 'スキルシート';
        case 5:
          return 'ブックマーク一覧';
        case 6:
          return '応募履歴';
        default:
          return '基本情報';
      }
    },
  },
  methods: {
    resetModel() {
      this.modal.type = null;
      this.modal.title = null;
      this.modal.text = null;
      this.modal.show = false;
      this.modal.functionArg = null;
      this.$refs.child.finishLoading();
    },

    setModal(type, arg) {
      this.modal.type = type;
      this.modal.show = true;
      this.modal.functionArg = arg;
      switch (type) {
        case 'deleteSkillSheet':
          this.modal.yesColor = 'error';
          this.modal.noColor = '#1976D2';
          this.modal.yesBtn = '削除';
          this.modal.title = 'スキルシートを削除しますか?';
          this.modal.text =
            '一度削除すると再度アップロードする必要があります。';
          break;
        case 'deleteBookmark':
          this.modal.yesColor = 'error';
          this.modal.noColor = '#1976D2';
          this.modal.yesBtn = '削除';
          this.modal.title = 'ブックマークを削除しますか?';
          this.modal.text = '一度削除すると元に戻せません。';
          break;
        case 'entryCancel':
          this.modal.yesColor = 'error';
          this.modal.noColor = '#1976D2';
          this.modal.yesBtn = '実行';
          this.modal.title = '応募をキャンセルしますか?';
          this.modal.text = '一度キャンセルすると元に戻せません。';
          break;
        default:
          this.modal.type = null;
          this.modal.title = null;
          this.modal.text = null;
          this.modal.show = false;
      }
    },

    commitAction(type) {
      switch (type) {
        case 'deleteSkillSheet':
          this.deleteSkillSheet();
          break;
        case 'deleteBookmark':
          this.deleteBookmark(this.modal.functionArg);
          break;
        case 'entryCancel':
          this.entryCancel(this.modal.functionArg);
          break;
      }
    },

    closeAction(type) {
      this.resetModel();
      switch (type) {
        case 'deleteSkillSheet':
          break;
        case 'deleteBookmark':
          break;
      }
    },

    routerPushName(pageName) {
      if (this.$route.name !== pageName)
        this.$router.push({
          name: pageName,
          params: { name: this.displayAsPc },
        });
    },

    setSelectPage(pageName) {
      switch (pageName) {
        case 'Account':
          this.selectedPage = 0;
          this.getUser();
          break;
        case 'Skill':
          this.selectedPage = 1;
          this.getUser().then(() => {
            this.getSkills().then(() => {
              this.setSkills().then(() => {
                this.loadingByUserSkill = false;
              });
            });
          });
          break;
        case 'Email':
          this.selectedPage = 2;
          this.getUser();
          break;
        case 'Password':
          this.selectedPage = 3;
          break;
        case 'SkillSheet':
          this.selectedPage = 4;
          this.getSkillSheet();
          break;
        case 'Bookmark':
          this.selectedPage = 5;
          this.getBookmarks();
          break;
        case 'Entry':
          this.selectedPage = 6;
          this.getEntries();
          break;
        default:
          this.setSelectPage = 0;
          this.getUser();
          break;
      }
    },

    async getUser() {
      try {
        const result = await this.$axios.get('/api/v1/users/mypage', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status === 200) this.user = result.data;
        if (!this.user.birth) this.user.birth = '2000-01-01T00:00:00+09:00';
      } catch (err) {
        if (err.response.status == 401) {
          this.$router.push({ path: '/' });
          return;
        }
      }
    },

    async getBookmarks() {
      try {
        const result = await this.$axios.get('/api/v1/bookmarks', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status === 200) this.bookmarks = result.data;
        this.loadingByBookmark = false;
      } catch (err) {
        this.loadingByBookmark = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async deleteBookmark(bookmarkId) {
      try {
        const result = await this.$axios.delete(
          '/api/v1/bookmarks/' + bookmarkId,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 204) {
          let newBookmarks = this.bookmarks.filter(
            value => value.id !== bookmarkId
          );
          this.bookmarks = newBookmarks;
          this.$emit(
            'showSnackbar',
            'success',
            'ブックマークが削除されました。'
          );
        }
        this.resetModel();
      } catch (err) {
        this.resetModel();
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit(
            'showSnackbar',
            'error',
            '指定したブックマークは既に削除されています。'
          );
          this.getBookmarks(); // 最新の状態にするために取得し直し
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async getEntries() {
      try {
        const result = await this.$axios.get('/api/v1/entries', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status === 200) this.entries = result.data;
        this.loadingByEntry = false;
      } catch (err) {
        this.loadingByEntry = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async entryCancel(entryId) {
      try {
        const result = await this.$axios.get(
          'api/v1/entries/' + entryId + '/cancel',
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          let newEntries = [];
          this.entries.forEach(element => {
            if (element.id === entryId) {
              let cancelEntry = {
                ...element,
                cancelable_flag: result.data.cancelable_flag,
                status: result.data.status,
              };
              newEntries.push(cancelEntry);
            } else {
              newEntries.push(element);
            }
          });
          this.entries = newEntries;
          this.$emit('showSnackbar', 'success', '応募をキャンセルしました。');
        }
        this.resetModel();
      } catch (err) {
        this.resetModel();
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit('showSnackbar', 'error', '指定した応募は見つかりません。');
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async getSkillSheet() {
      try {
        const result = await this.$axios.get('/api/v1/skill_sheets/detail', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status === 200) this.skillSheet = result.data;
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async download() {
      try {
        const result = await this.$axios.get('/api/v1/skill_sheets/download', {
          headers: session.apiAuthHeaders(),
          responseType: 'blob',
        });
        if (result.status === 200 && result.data) {
          // Generate response data.
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.download = this.skillSheet.filename;
          a.href = url;
          a.click();
          // Remove a tag only use downloading.
          a.remove();
          URL.revokeObjectURL(url);
        }
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              'スキルシートがアップロードされていません。'
            );
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              'スキルシートのダウンロードに失敗しました。'
            );
            break;
        }
      }
    },

    async templateDownload() {
      this.loadingByTemplateDownload = true;
      try {
        const result = await this.$axios.get(
          '/api/v1/skill_sheets/template_download',
          {
            headers: session.apiAuthHeaders(),
            responseType: 'blob',
          }
        );
        if (result.status === 200 && result.data) {
          // Generate response data.
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.download = 'skill_sheet_template.xlsx';
          a.href = url;
          a.click();
          // Remove a tag only use downloading.
          a.remove();
          URL.revokeObjectURL(url);
        }
        this.loadingByTemplateDownload = false;
      } catch (err) {
        this.loadingByTemplateDownload = false;
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async upload(formData, btnDisable) {
      if (btnDisable) {
        this.$emit('showSnackbar', 'error', 'ファイルを選択してください');
        return;
      }
      try {
        this.loadingByUpload = true;
        this.uploadProgress = 0;

        const config = {
          headers: session.fileApiAuthHeaders(),
          onUploadProgress: progressEvent => {
            const progressRate = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.uploadProgress = progressRate;
          },
        };

        const result = await this.$axios.post(
          '/api/v1/skill_sheets',
          formData,
          config
        );

        this.skillSheet = result.data;
        this.$emit('showSnackbar', 'success', 'アップロードに成功しました。');
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      } finally {
        this.loadingByUpload = false;
      }
    },

    async deleteSkillSheet() {
      try {
        const result = await this.$axios.delete(
          '/api/v1/skill_sheets/' + this.user.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 204) {
          this.skillSheet = null;
          this.$emit(
            'showSnackbar',
            'success',
            'スキルシートが削除されました。'
          );
        }
        this.resetModel();
      } catch (err) {
        this.resetModel();
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              'スキルシートアップロードされていません。'
            );
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    currentSkills() {
      if (this.user.user_skills.length === 0) return [];
      const v = this.user.user_skills.map(skill => {
        return {
          required_skill_id: skill.required_skill_id,
          experience_year: skill.experience_year,
        };
      });
      return v;
    },

    update(isInvalid) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      switch (this.selectedPage) {
        case 0:
          this.updateUserInfo(this.currentSkills());
          break;
        case 2:
          this.updateEmail();
          break;
        case 3:
          this.updatePassword();
          break;
        default:
          this.$emit('showSnackbar', 'error', '更新に失敗しました。');
      }
    },

    createUserSkills(skillValues) {
      if (skillValues.length === 0) {
        this.$emit('showSnackbar', 'error', 'スキルを選択してください。');
        return;
      }
      const currentSkills = this.user.user_skills.map(skill => {
        return {
          required_skill_id: skill.required_skill_id,
          experience_year: skill.experience_year,
        };
      });
      this.loadingByCreateSkill = true;
      this.updateUserInfo(currentSkills.concat(skillValues)).then(() => {
        this.loadingByCreateSkill = false;
        this.$refs.childMypage.setModal(false, null);
      });
    },

    updateUserSkills(type, skillValues) {
      this.setSkillLoadingStatus(type, true);
      this.updateUserInfo(skillValues, type === 'delete').then(() => {
        this.setSkillLoadingStatus(type, false);
        this.$refs.childMypage.setModal(false, null);
      });
    },

    updateOtherSkill(type, otherSkillValue) {
      // NOTE:
      // type = 'create' || 'edit'
      // otherSkillValue = { target: 空文字(create) or 変更する対象(update), newValue: 更新する値 }
      if (otherSkillValue.newValue === '') {
        this.$emit('showSnackbar', 'error', 'スキル名を入力してください。');
        return;
      }

      if (
        type == 'edit' &&
        otherSkillValue.target == otherSkillValue.newValue
      ) {
        this.$emit('showSnackbar', 'success', '更新に成功しました。');
        this.$refs.childMypage.setOtherSkillModal(false, type);
        return;
      } else {
        if (
          this.user.other_skills &&
          this.user.other_skills.includes(otherSkillValue.newValue)
        ) {
          this.$emit('showSnackbar', 'error', '既にスキルが登録されています。');
          return;
        }
      }

      if (type === 'create') {
        // 登録
        if (this.user.other_skills) {
          this.user.other_skills.push(otherSkillValue.newValue);
        } else {
          // other_skillsがnilの場合に下記で対応
          this.user.other_skills = [otherSkillValue.newValue];
        }
      } else {
        // 更新
        let removedOtherSkill = this.user.other_skills.filter(skill => {
          return skill !== otherSkillValue.target;
        });
        removedOtherSkill.push(otherSkillValue.newValue);
        this.user.other_skills = removedOtherSkill;
      }
      this.updateUserInfo(this.currentSkills()).then(() => {
        this.$refs.childMypage.setOtherSkillModal(false, type);
      });
    },

    deleteOtherSkill(deleteOtherSkill) {
      let removedOtherSkill = this.user.other_skills.filter(skill => {
        return skill !== deleteOtherSkill;
      });
      this.user.other_skills = removedOtherSkill;
      this.updateUserInfo(this.currentSkills(), true).then(() => {
        this.$refs.childMypage.setOtherSkillModal(false, 'delete');
      });
    },

    setSkillLoadingStatus(type, value) {
      if (type === 'update') {
        this.loadingByUpdateSkill = value;
      } else {
        this.loadingByDeleteSkill = value;
      }
    },

    async updateEmail() {
      if (this.user.email === this.user.unconfirmed_email) {
        this.$emit(
          'showSnackbar',
          'error',
          '現在のメールアドレスとは別のメールアドレスを入力してください。'
        );
        return;
      }
      try {
        this.loadingByEmail = true;
        const result = await this.$axios.post(
          '/api/v1/users/change_email',
          { user: { email: this.user.unconfirmed_email } },
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          this.user.unconfirmed_email = null;
          this.$emit(
            'showSnackbar',
            'success',
            'メールアドレス変更の認証メールを送信しました。'
          );
        }
        this.$refs.childMypage.resetValidate();
        this.loadingByEmail = false;
      } catch (err) {
        this.loadingByEmail = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async updateUserInfo(updateSkills, skillDeleteFlag) {
      if (this.user.id == null) {
        this.$router.push({ path: '/' });
        return;
      }
      try {
        this.loadingByAccountInfo = true;
        const result = await this.$axios.patch(
          '/api/v1/users/' + this.user.id,
          { user: this.user, user_skills: updateSkills },
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          this.user = result.data;
          this.setSkills();
          session.setUidToItemKeys(result.headers.uid);
          const message = skillDeleteFlag ? '削除' : '更新';
          this.$emit('showSnackbar', 'success', `${message}に成功しました。`);
        }
        if (this.selectedPage === 1) {
          this.$refs.childMypage.resetCreateSkillValue();
        }
        this.loadingByAccountInfo = false;
      } catch (err) {
        this.loadingByAccountInfo = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async updatePassword() {
      const hasNoMatchedPassword = !(
        this.user.password === this.user.password_confirmation
      );
      if (hasNoMatchedPassword) {
        this.$emit(
          'showSnackbar',
          'error',
          'パスワードとパスワード確認が一致していません。'
        );
        return;
      }
      const hasMatchedNewPassword =
        this.user.password === this.user.current_password;
      if (hasMatchedNewPassword) {
        this.$emit('showSnackbar', 'error', 'パスワードが変更されていません。');
        return;
      }
      try {
        this.loadingByPassword = true;
        const result = await this.$axios.patch(
          '/api/v1/auth/password',
          {
            user: {
              password: this.user.password,
              password_confirmation: this.user.password_confirmation,
              current_password: this.user.current_password,
            },
            change_password: true,
          },
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          this.$emit('showSnackbar', 'success', '更新に成功しました。');
          const resetData = {
            ...this.user,
            password: null,
            password_confirmation: null,
            current_password: null,
          };
          this.user = resetData;
          this.$refs.childMypage.resetValidate();
        }
        this.loadingByPassword = false;
        if (result.status === 204) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
        }
      } catch (err) {
        this.loadingByPassword = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }

        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : '入力内容をご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async setSkills() {
      this.userSkills = {
        languages: [],
        frameworks: [],
        others: [],
      };
      this.user.user_skills.forEach(skill => {
        const targetValue = this.skills.find(targetSkill => {
          return skill.required_skill_id === targetSkill.id;
        });
        if (targetValue) {
          switch (targetValue.category) {
            case 0:
              this.userSkills.languages.push({
                ...targetValue,
                experience_year: skill.experience_year,
              });
              break;
            case 1:
              this.userSkills.frameworks.push({
                ...targetValue,
                experience_year: skill.experience_year,
              });
              break;
            case 2:
              this.userSkills.others.push({
                ...targetValue,
                experience_year: skill.experience_year,
              });
              break;
            default:
              break;
          }
        }
      });
    },

    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/required_skills', {
          headers: { 'Content-Type': 'application/json' },
        });
        if (result.status == 200) this.skills = result.data;
      } catch (err) {
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
