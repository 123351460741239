<template>
  <v-dialog
    :value="dialog"
    persistent
    :width="$vuetify.breakpoint.mdAndUp ? '355' : '327'"
    @click:outside="close"
  >
    <v-card class="px-2">
      <v-card-title class="pl-2 font-weight-bold">
        <v-row>
          <v-col>
            <div v-text="title" />
          </v-col>
          <v-col cols="auto" class="d-flex align-center px-0" align="right">
            <v-btn elevation="0" icon @click="close">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="mb-2" />
      <v-card-text class="px-0 pb-0">
        <v-container class="other-skill-form-container px-1">
          <v-form v-model="inValid">
            <v-row style="width: 100%">
              <v-col cols="12" class="pb-0 pr-0">
                <v-text-field
                  dense
                  outlined
                  class="pl-3"
                  color="primary"
                  hideDetails="auto"
                  :value="value"
                  :rules="rules.otherSkills"
                  :counter="15"
                  @input="updateOtherSkillValue($event)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-4 pb-4">
        <v-container>
          <v-row justify="space-between">
            <v-col cols="6" class="px-0 pb-0" align="left">
              <v-btn
                v-if="type === 'create'"
                class="font-weight-bold"
                elevation="0"
                outlined
                width="120"
                color="grey"
                @click="close"
              >
                閉じる
              </v-btn>
              <v-btn
                v-else
                class="font-weight-bold"
                elevation="0"
                outlined
                width="120"
                color="accent"
                @click="deleteOtherSkill"
              >
                <v-icon v-text="'mdi-delete'" />
                <span style="padding-top: 1px" v-text="'削除する'" />
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-0 pb-0" align="right">
              <v-btn
                class="font-weight-bold"
                elevation="0"
                width="120"
                color="primary"
                :loading="loadingByCreateOtherSkill"
                :disabled="btnDisable"
                @click="updateOtherSkill"
              >
                {{ updateBtnLabel }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.v-slider__tick-label {
  font-size: 8px;
}
.slider-label {
  font-size: 4px;
}
.annotation-label {
  font-size: 8px;
}
</style>
<script>
import validate from '../../plugins/validate';
export default {
  name: 'OtherSkillModal',
  props: ['dialog', 'skill', 'type', 'loadingByCreateOtherSkill'],
  data() {
    return {
      otherSkillValue: '',
      inValid: false,
      rules: {
        otherSkills: [value => validate.userOtherSkills(value)],
      },
    };
  },
  watch: {
    skill() {
      this.otherSkillValue = this.skill;
    },

    dialog() {
      if (!this.dialog) {
        this.otherSkillValue = '';
      }
    },
  },
  computed: {
    isDisplayPc() {
      return this.$vuetify.breakpoint.mdAndUp;
    },

    title() {
      return this.type === 'create'
        ? 'その他のスキル(自由入力)'
        : `${this.skill} の詳細`;
    },

    updateBtnLabel() {
      return this.type === 'create' ? '登録する' : '更新する';
    },

    value() {
      return this.otherSkillValue;
    },

    btnDisable() {
      return this.otherSkillValue == '' || !this.inValid;
    },
  },
  methods: {
    deleteOtherSkill() {
      this.$emit('deleteOtherSkill', this.skill);
    },

    updateOtherSkillValue(event) {
      this.otherSkillValue = event;
    },

    updateOtherSkill() {
      this.$emit('updateOtherSkill', this.type, {
        target: this.skill,
        newValue: this.otherSkillValue,
      });
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
