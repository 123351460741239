<template>
  <v-card outlined elevation="0" width="270" class="pb-3">
    <v-row class="pa-2">
      <v-col cols="12" align="left">
        <h4>フリーワード</h4>
      </v-col>
    </v-row>
    <v-divider />
    <v-text-field
      class="pt-5 px-2"
      outlined
      dense
      placeholder="Ruby リモート"
      color="primary"
      :value="outputFreeWord"
      @input="inputFreeWord"
    />
    <v-divider />
    <v-row class="pa-2">
      <v-col cols="12" align="left">
        <h4>基本条件</h4>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="px-2 pb-0 pt-2">
      <v-col cols="12" class="pb-0" align="left">
        <h5>勤務形態</h5>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-checkbox
          v-for="(item, index) in workLocations"
          :key="index"
          v-model="selectWorkLocations"
          @change="changeWorkLocations"
          :value="item.id"
          :label="item.value"
          class="py-0"
          hide-details="auto"
          dense
          color="primary"
        />
      </v-col>
    </v-row>
    <v-form :value="btnDisable" @input="changeBtnDisable">
      <!-- 検索：単価 -->
      <v-row class="px-2 pb-0 pt-2">
        <v-col cols="12" class="pb-0" align="left">
          <h5 class="">単価</h5>
        </v-col>
        <v-col cols="5" class="py-0">
          <v-text-field
            dense
            placeholder="50"
            suffix="万円"
            color="primary"
            :value="minCostQuery"
            @input="updateMinCostQuery"
            :rules="rules.minCost"
          />
        </v-col>
        <v-col cols="2" class="py-0">
          <h5 class="pt-3">〜</h5>
        </v-col>
        <v-col cols="5" class="py-0">
          <v-text-field
            dense
            placeholder="70"
            suffix="万円"
            color="primary"
            :value="maxCostQuery"
            @input="updateMaxCostQuery"
            :rules="rules.maxCost"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row class="my-0">
      <!-- 検索：スキル -->
      <v-col cols="6" class="py-0 my-0" align="left">
        <h5 class="px-2 pb-1 pt-2">スキル</h5>
      </v-col>
      <v-col cols="2" class="py-0 my-0">
        <v-btn
          elevation="0"
          text
          color="primary"
          class="font-weight-bold"
          @click.stop="skillsDialog = true"
          v-text="'選択する'"
        />
      </v-col>
      <v-col cols="12" class="pl-6 pr-0" v-if="selectedSkills.length > 0">
        <v-chip
          label
          outlined
          class="mr-2 mb-1"
          v-for="(skill, index) in skillList"
          :key="index"
          close
          @click:close="removeSelectChip('skills', skill.number)"
        >
          {{ skill.name }}
        </v-chip>
      </v-col>
      <v-dialog v-model="skillsDialog" max-width="1000">
        <SkillsDialog
          :skills="skills"
          :selectedSkills="selectedSkills"
          @close="skillsDialog = false"
          @changeSkills="changeSkills"
        />
      </v-dialog>
    </v-row>
    <v-row class="my-0">
      <!-- 検索：勤務地 -->
      <v-col cols="6" class="py-0 my-0" align="left">
        <h5 class="px-2 pb-1 pt-2">勤務地</h5>
      </v-col>
      <v-col cols="2" class="py-0 my-0">
        <v-btn
          elevation="0"
          text
          color="primary"
          class="font-weight-bold"
          @click.stop="prefectureDialog = true"
          v-text="'選択する'"
        />
      </v-col>
      <v-col
        cols="12"
        class="pl-6 pr-0"
        v-if="selectedMunicipalities.length > 0"
      >
        <v-chip
          label
          outlined
          class="mr-5 mb-1"
          v-for="(municipality, index) in municipalityList"
          :key="index"
          close
          @click:close="removeSelectChip('municipalities', municipality.id)"
        >
          {{ municipality.value }}
        </v-chip>
      </v-col>
      <v-dialog v-model="prefectureDialog" max-width="1000">
        <MunicipalitiesDialog
          :selectedMunicipalities="selectedMunicipalities"
          @close="prefectureDialog = false"
          @changeMunicipalities="changeMunicipalities"
        />
      </v-dialog>
    </v-row>
    <v-divider class="mt-2" />
    <div class="d-flex justify-center pt-3">
      <v-btn
        elevation="0"
        width="180"
        color="primary white--text"
        class="font-weight-bold"
        @click="search"
        :disabled="isInvalid"
        v-text="'検索する'"
      />
    </div>
  </v-card>
</template>

<script>
import mixinWorkLocation from '../../../const/project/workLocation';
import mixinMunicipality from '../../../const/project/municipality';
import SkillsDialog from './SkillsDialog.vue';
import MunicipalitiesDialog from './MunicipalitiesDialog.vue';
import validate from '../../../plugins/validate';

export default {
  name: 'ProjectSearch',
  components: {
    SkillsDialog,
    MunicipalitiesDialog,
  },
  props: [
    'outputFreeWord',
    'maxCostQuery',
    'minCostQuery',
    'selectedMunicipalities',
    'btnDisable',
    'selectedWorkLocations',
    'skills',
    'selectedSkills',
  ],
  mixins: [mixinWorkLocation, mixinMunicipality],
  data() {
    return {
      skillsDialog: false,
      prefectureDialog: false,
      selectWorkLocations: [],
      rules: {
        maxCost: [value => validate.projectCost(value)],
        minCost: [value => validate.projectCost(value)],
      },
    };
  },
  created() {
    this.selectWorkLocations = this.selectedWorkLocations;
  },
  watch: {
    // selectedWorkLocations(親のdata)を監視して
    // 変更時にselectWorkLocations(子のdata)に代入
    selectedWorkLocations() {
      this.selectWorkLocations = this.selectedWorkLocations;
    },
  },
  computed: {
    isInvalid() {
      if (!this.btnDisable) return true;
      // どちらか一方だけが入力されている場合はtrueを返す
      return !this.minCostQuery != !this.maxCostQuery;
    },

    skillList() {
      if (this.selectedSkills.length === 0) return [];
      let selectSkills = [];
      this.selectedSkills.forEach(targetSkillNumber => {
        const targetSkill = this.skills.find(skill => {
          return skill.number === targetSkillNumber;
        });
        if (targetSkill) return selectSkills.push(targetSkill);
      });
      return selectSkills;
    },

    municipalityList() {
      if (this.selectedMunicipalities.length === 0) return [];
      let selectMunicipalities = [];
      this.selectedMunicipalities.forEach(municipalityId => {
        const targetValue = this.municipalities.find(
          element => element.id == municipalityId
        );
        selectMunicipalities.push(targetValue);
      });
      return selectMunicipalities;
    },
  },
  methods: {
    removeSelectChip(type, value) {
      this.$emit('removeSelectChip', {
        type: type,
        value: value,
      });
    },

    inputFreeWord(e) {
      this.$emit('inputFreeWord', e);
    },

    updateMaxCostQuery(e) {
      this.$emit('updateMaxCostQuery', e);
    },

    updateMinCostQuery(e) {
      this.$emit('updateMinCostQuery', e);
    },

    search() {
      this.$emit('search');
    },

    // v-checkboxの値の受け渡し
    changeSkills(value) {
      this.$emit('changeSkills', value);
    },

    // v-checkboxの値の受け渡し
    changeMunicipalities(value) {
      this.$emit('changeMunicipalities', value);
    },

    // v-checkboxの値の受け渡し
    changeWorkLocations() {
      this.$emit('changeWorkLocations', this.selectWorkLocations);
    },

    changeBtnDisable(boolean) {
      this.$emit('changeBtnDisable', boolean);
    },
  },
};
</script>
