<template>
  <v-dialog
    :value="dialog"
    persistent
    :width="$vuetify.breakpoint.mdAndUp ? '340' : '327'"
    @click:outside="close"
  >
    <v-card class="px-2" v-if="skill">
      <v-card-title class="pl-2 font-weight-bold">
        <v-row>
          <v-col>
            <h4 v-text="`${skill.name} の詳細`" />
          </v-col>
          <v-col cols="2" class="px-0" align="right">
            <v-btn elevation="0" icon @click="close">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="mb-2" />
      <v-card-text class="px-0">
        <v-container class="px-0">
          <h4 class="pl-3">経験年数</h4>
          <v-slider
            class="pt-10 px-4"
            v-model="experienceValue"
            hide-details="auto"
            :max="5"
            step="1"
            ticks="always"
            color="primary"
            thumb-size="34"
            :thumb-label="$vuetify.breakpoint.mdAndUp ? '' : 'always'"
          >
            <template v-slot:thumb-label="{ value }">
              <div class="font-weight-bold" style="font-size: 10px">
                {{ labelList[value].label }}
              </div>
            </template>
          </v-slider>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="slider-label font-weight-bold"
          >
            <span
              v-for="(item, index) in labelList"
              :key="index"
              :class="item.class"
              :style="`padding-left: ${item.paddingLeft}`"
              v-text="index !== 5 ? item.label : item.label + '以上'"
            />
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-7">
        <v-container>
          <v-row>
            <v-col cols="6" class="px-0 pb-0" align="left">
              <v-btn
                class="font-weight-bold"
                elevation="0"
                outlined
                width="120"
                color="accent"
                :loading="loadingByDeleteSkill"
                @click="deleteSkill(skill)"
              >
                <v-icon v-text="'mdi-delete'" />
                <span style="padding-top: 1px" v-text="'削除する'" />
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-0 pb-0" align="right">
              <v-btn
                class="font-weight-bold"
                elevation="0"
                width="120"
                color="primary"
                :loading="loadingByUpdateSkill"
                @click="updateSkill(skill)"
              >
                更新する
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.v-slider__tick-label {
  font-size: 8px;
}
.slider-label {
  font-size: 4px;
}
</style>
<script>
export default {
  name: 'SkillDetailModal',
  props: ['dialog', 'skill', 'loadingByUpdateSkill', 'loadingByDeleteSkill'],
  data() {
    return {
      experienceValue: 0,
    };
  },
  watch: {
    dialog() {
      if (this.skill) {
        // モーダル表示させるタイミングでvalueをセット
        this.experienceValue = this.skill.experience_year;
      }
    },
  },
  computed: {
    isDisplayPc() {
      return this.$vuetify.breakpoint.mdAndUp;
    },

    labelList() {
      return [
        {
          label: '未選択',
          paddingLeft: this.isDisplayPc ? '10px' : '10px',
          class: 'primary--text',
        },
        {
          label: '1年',
          paddingLeft: this.isDisplayPc ? '30px' : '29px',
          class: this.experienceValue >= 1 ? 'primary--text' : '',
        },
        {
          label: '2年',
          paddingLeft: this.isDisplayPc ? '41px' : '37px',
          class: this.experienceValue >= 2 ? 'primary--text' : '',
        },
        {
          label: '3年',
          paddingLeft: this.isDisplayPc ? '40px' : '36px',
          class: this.experienceValue >= 3 ? 'primary--text' : '',
        },
        {
          label: '4年',
          paddingLeft: this.isDisplayPc ? '39px' : '35px',
          class: this.experienceValue >= 4 ? 'primary--text' : '',
        },
        {
          label: '5年',
          paddingLeft: this.isDisplayPc ? '28px' : '28px',
          class: this.experienceValue >= 5 ? 'primary--text' : '',
        },
      ];
    },
  },
  methods: {
    deleteSkill(skill) {
      this.$emit('updateSkillDetail', 'delete', {
        id: skill.id,
        experience_year: skill.experience_year,
      });
    },

    updateSkill(skill) {
      this.$emit('updateSkillDetail', 'update', {
        id: skill.id,
        experience_year: this.experienceValue,
      });
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
