<template>
  <v-card outlined elevation="0" class="pt-3 pb-5">
    <Loading v-if="loadingByUserSkill" />
    <v-container v-else>
      <v-row justify="space-between">
        <v-col cols="auto" class="pb-0 pr-0">
          <h4>【新規登録】</h4>
        </v-col>
        <v-col cols="auto" class="pb-0 pl-0 pr-5" align="right">
          <div class="pt-1 font-weight-bold" style="font-size: 11px">
            候補にないスキルを登録する場合は
            <span
              class="text-decoration-underline"
              style="cursor: pointer"
              @click="setOtherSkillModal(true, 'create')"
            >
              こちら
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-5">
          <div class="d-flex">
            <v-autocomplete
              dense
              chips
              outlined
              multiple
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
              id="skill-autocomplete"
              class="pr-2"
              item-color="primary"
              item-text="name"
              item-value="id"
              :search-input.sync="searchInputValue"
              prepend-inner-icon="mdi-database-search"
              placeholder="例: Ruby"
              no-data-text="該当するスキルが見つかりません。"
              :items="selectableSkills"
              :value="createSkillValue"
              @change="changeSelectSkill($event)"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  label
                  outlined
                  @click="data.select"
                  @click:close="removeSelectSkill(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
            <v-btn
              width="30"
              height="38"
              color="primary"
              elevation="0"
              class="font-weight-bold"
              :loading="loadingByCreateSkill"
              @click="createSkills"
            >
              登録
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="!skillNotExists" class="mt-5 mb-4" />
      <h4 v-if="!skillNotExists" class="pb-6">【登録スキル】</h4>
      <v-row v-if="!skillNotExists" class="px-5">
        <v-col cols="12">
          <v-row>
            <v-col
              v-for="(item, index) in skillTypes"
              :key="index"
              cols="12"
              :class="userSkills[item.type].length !== 0 ? 'py-1' : 'pa-0'"
            >
              <div v-if="userSkills[item.type].length !== 0">
                <h5 class="pb-2">{{ item.label }}</h5>
                <v-tooltip
                  v-for="(skill, index) in userSkills[item.type]"
                  :key="index"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      close
                      color="primary"
                      class="mr-2 mb-2 font-weight-bold"
                      :outlined="true"
                      @click="setModal(true, skill)"
                      @click:close="
                        updateSkillDetail('delete', {
                          id: skill.id,
                          experience_year: skill.experience_year,
                        })
                      "
                    >
                      <div v-bind="attrs" v-on="on">
                        {{ skillChipLabel(skill) }}
                      </div>
                    </v-chip>
                  </template>
                  <span class="font-weight-bold tooltip-label">
                    {{ skill.name }} の詳細を開く
                  </span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="12" class="py-1" v-if="value.other_skills">
              <h5 class="pb-2">その他(自由入力)</h5>
              <v-tooltip top v-for="(skill, index) in otherSkills" :key="index">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :outlined="true"
                    label
                    close
                    class="mr-2 mb-2 font-weight-bold"
                    color="primary"
                    @click="setOtherSkillModal(true, 'edit', skill)"
                    @click:close="deleteOtherSkill(skill)"
                  >
                    <div v-bind="attrs" v-on="on" v-text="skill" />
                  </v-chip>
                </template>
                <span class="font-weight-bold tooltip-label">
                  {{ skill }} の詳細を開く
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <SkillDetailModal
      :dialog="modal.show"
      :skill="modal.skill"
      :loadingByUpdateSkill="loadingByUpdateSkill"
      :loadingByDeleteSkill="loadingByDeleteSkill"
      @updateSkillDetail="updateSkillDetail"
      @close="setModal(false, null)"
    />
    <OtherSkillFormModal
      ref="otherSkillModalComponent"
      :dialog="otherSkillModal.show"
      :skill="otherSkillModal.skill"
      :type="otherSkillModalType"
      @close="setOtherSkillModal(false, otherSkillModalType)"
    />
  </v-card>
</template>
<style>
.v-input__append-outer {
  margin-top: 0 !important;
}
/* 非フォーカス時にプレースホルダが表示させる */
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit !important;
}
/* v-autocomplete: リスト内のチェックボックスを非表示 */
.v-list-item__action {
  display: none;
}
.tooltip-label {
  font-size: 8px;
}
</style>
<script>
import SkillDetailModal from '../../common/SkillDetailModal.vue';
import OtherSkillFormModal from '../../common/OtherSkillFormModal.vue';
import Loading from '../../common/Loading.vue';
import mixinSkillExperienceYears from '../../../const/user/skillExperienceYears';
export default {
  name: 'SkillMypageSp',
  props: [
    'value',
    'userSkills',
    'skills',
    'loadingByUserSkill',
    'loadingByCreateSkill',
    'loadingByUpdateSkill',
    'loadingByDeleteSkill',
  ],
  mixins: [mixinSkillExperienceYears],
  components: {
    SkillDetailModal,
    OtherSkillFormModal,
    Loading,
  },
  data() {
    return {
      btnDisable: false,
      modal: {
        show: false,
        skill: null,
      },
      otherSkillModal: {
        show: false,
        skill: '',
      },
      otherSkillModalType: 'create',
      createSkillValue: [],
      skillTypes: [
        {
          label: '言語',
          type: 'languages',
        },
        {
          label: 'フレームワーク',
          type: 'frameworks',
        },
        {
          label: 'その他',
          type: 'others',
        },
      ],
      searchInputValue: '',
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'Skill');
  },
  computed: {
    skillNotExists() {
      return (
        this.userSkills?.languages.length === 0 &&
        this.userSkills?.frameworks.length === 0 &&
        this.userSkills?.others.length === 0 &&
        !this.value?.other_skills
      );
    },

    selectableSkills() {
      const currentSkillIds = this.value.user_skills.map(skill => {
        return skill.required_skill_id;
      });
      const selectableSkillList = this.skills.filter(skill => {
        if (!currentSkillIds?.includes(skill.id)) return true;
      });
      return selectableSkillList;
    },

    otherSkills() {
      return this.value?.other_skills ? this.value.other_skills : [];
    },
  },
  methods: {
    setModal(show, skill) {
      this.modal.show = show;
      this.modal.skill = skill;
    },

    setOtherSkillModal(show, type, skill) {
      this.otherSkillModalType = type;
      this.otherSkillModal.show = show;
      if (skill) {
        this.otherSkillModal.skill = skill;
      } else {
        this.otherSkillModal.skill = '';
      }
      if (!show) this.otherSkillModal.skill = '';
    },

    createSkills() {
      const createSkillValues = this.createSkillValue.map(skillId => {
        return { required_skill_id: skillId, experience_year: 0 };
      });
      this.$emit('createUserSkills', createSkillValues);
    },

    resetCreateSkillValue() {
      this.createSkillValue = [];
    },

    updateSkillDetail(type, updateSkill) {
      const withoutChangeSkills = this.value.user_skills.filter(skill => {
        return skill.required_skill_id !== updateSkill.id;
      });
      if (type === 'update') {
        // 更新
        const updateSkillValue = {
          required_skill_id: updateSkill.id,
          experience_year: updateSkill.experience_year,
        };
        this.$emit('updateUserSkills', type, [
          ...withoutChangeSkills,
          updateSkillValue,
        ]);
      } else {
        // 削除
        this.$emit('updateUserSkills', type, withoutChangeSkills);
      }
    },

    updateOtherSkill(type, otherSkillValue) {
      this.$emit('updateOtherSkill', type, otherSkillValue);
    },

    deleteOtherSkill(otherSkill) {
      this.$emit('deleteOtherSkill', otherSkill);
    },

    experienceYear(value) {
      const val = this.skillExperienceYears.find(target => {
        return target.id == value && value !== 0;
      });
      return val ? val.value : '';
    },

    changeSelectSkill(event) {
      this.searchInputValue = '';
      this.createSkillValue = event;
    },

    removeSelectSkill(removeSkill) {
      const newValue = this.createSkillValue.filter(skillId => {
        return removeSkill.id !== skillId;
      });
      this.createSkillValue = newValue;
    },

    skillChipLabel(skill) {
      return skill.name + ' ' + this.experienceYear(skill.experience_year);
    },
  },
};
</script>
