export default {
  data() {
    return {
      entryEvaluation: [
        { id: 0, value: '未評価', color: 'grey white--text' },
        { id: 1, value: '低', color: 'orange white--text' },
        { id: 2, value: '中', color: 'primary white--text' },
        { id: 3, value: '高', color: 'red white--text' },
      ],
    };
  },
};
