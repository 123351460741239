<template>
  <v-card outlined elevation="0" max-width="630" class="pt-5">
    <v-container>
      <div class="d-flex justify-center">
        <div>
          <v-img width="140" src="@/assets/img/logo.png" />
        </div>
      </div>
      <v-card-title class="justify-center font-weight-bold mt-3">
        パスワードの再設定
      </v-card-title>
      <v-card-text
        class="mt-2 mb-2 text-center"
        data-cy="reset-password-form-complete-text"
      >
        <div>
          パスワード再設定用のメールを
          {{ user.email }}
          宛てにお送りしました。<br />
          お送りしたメールの内容に従って1時間以内にパスワードを再設定してください。<br />
        </div>
        <div class="pt-6">
          なお、しばらく経ってもメールが届かない場合は、迷惑フォルダに仕分けされているか、<br />
          入力したメールアドレスに誤りがある可能性がございます。<br />
        </div>
        <div class="pt-3">お手数ですが再度ご入力をお願いいたします。</div>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn
          class="font-weight-bold"
          color="primary"
          elevation="0"
          text
          @click="back"
          v-text="'メールが届かない場合はこちら'"
          data-cy="reset-password-form-second-undelivered-btn"
        />
        <v-btn
          class="font-weight-bold"
          color="primary"
          elevation="0"
          text
          @click="$router.push({ path: '/login' })"
          v-text="'ログインへ'"
          data-cy="reset-password-form-second-to-login-btn"
        />
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'ResetPasswordCompletePc',
  props: ['user'],
  methods: {
    back() {
      this.$emit('back');
    },
  },
};
</script>
