export default {
  data() {
    return {
      currentStatuses: [
        { id: 0, value: 'フリーランス' },
        { id: 1, value: '正社員' },
        { id: 2, value: '契約・派遣社員' },
        { id: 3, value: 'アルバイト' },
        { id: 4, value: 'その他' },
      ],
    };
  },
};
