export default {
  data() {
    return {
      workLocations: [
        { id: 0, value: '在宅' },
        { id: 1, value: '客先常駐' },
        { id: 2, value: '在宅、客先常駐' },
      ],
    };
  },
};
