<template>
  <v-card-text>
    <v-row>
      <!-- 基本情報 -->
      <v-col cols="8" class="pl-1 pt-1 pb-0">
        <div
          class="title-text font-weight-bold"
          v-text="'基本情報'"
          data-cy="entry-step1-section-title"
        />
      </v-col>
      <v-col cols="4" class="pa-0" align="right">
        <v-btn
          depressed
          text
          class="pl-0 pr-2"
          @click="openAccountDialog"
          data-cy="entry-step1-open-dialog-btn"
        >
          <span>
            <v-icon small color="primary" v-text="'mdi-pencil'" />
          </span>
          <div class="primary--text" v-text="'編集'" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!-- 姓・名 -->
      <v-col cols="6" class="px-0 pb-1 pt-0" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-name-last"
        >
          【姓】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col
        cols="6"
        class="px-0 pb-1 pt-0"
        align="left"
        data-cy="entry-step1-col-name-first"
      >
        <div class="font-weight-bold label-text">
          【名】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="lastNameInitialValue"
          data-cy="entry-step1-data-name-last"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="firstNameInitialValue"
          data-cy="entry-step1-data-name-first"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 姓カナ・名カナ -->
      <v-col cols="6" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-kana-last"
        >
          【姓カナ】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col
        cols="6"
        class="px-0 py-1"
        align="left"
        data-cy="entry-step1-col-kana-first"
      >
        <div class="font-weight-bold label-text">
          【名カナ】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="lastNameKanaInitialValue"
          data-cy="entry-step1-data-kana-last"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="firstNameKanaInitialValue"
          data-cy="entry-step1-data-kana-first"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- メールアドレス -->
      <v-col cols="12" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-email"
        >
          【メールアドレス】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="12" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="emailInitialValue"
          data-cy="entry-step1-data-email"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 電話番号 -->
      <v-col cols="12" class="px-0 py-1" align="left">
        <div class="font-weight-bold label-text" data-cy="entry-step1-col-tel">
          【電話番号】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="12" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="telInitialValue"
          data-cy="entry-step1-data-tel"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 生年月日・女性 -->
      <v-col cols="6" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-birth"
        >
          【生年月日】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col
        cols="6"
        class="px-0 py-1"
        align="left"
        data-cy="entry-step1-col-gender"
      >
        <div class="font-weight-bold label-text">
          【性別】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="birthInitialValue"
          data-cy="entry-step1-data-birth"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="genderInitialValue"
          data-cy="entry-step1-data-gender"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 現在の状況・住所（市区町村） -->
      <v-col cols="6" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-status"
        >
          【現在の状況】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="px-0 py-1" align="left">
        <div class="font-weight-bold label-text" data-cy="entry-step1-col-area">
          【住所（市区町村）】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="currentStatusInitialValue"
          data-cy="entry-step1-data-status"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="liveAreaInitialValue"
          data-cy="entry-step1-data-area"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 最寄り駅・国籍 -->
      <v-col cols="6" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-station"
        >
          【最寄り駅】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-country"
        >
          【国籍】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="closestStationInitialValue"
          data-cy="entry-step1-data-station"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0" align="left">
        <div
          class="font-weight-bold text"
          v-text="nationalityInitialValue"
          data-cy="entry-step1-data-country"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- スキルシート -->
      <v-col cols="12" class="px-0 py-1" align="left">
        <div
          class="font-weight-bold label-text"
          data-cy="entry-step1-col-skillsheet"
        >
          【スキルシート】<span style="color: red">※</span>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0 pr-0"
        align="left"
        data-cy="entry-step1-data-skillsheet"
      >
        <div
          class="font-weight-bold text"
          v-text="skillSheetFileNameInitialValue"
          v-if="skillSheet != null && skillSheet.filename != undefined"
        />
        <h4 v-else v-text="'アップロードされていません。'" />
      </v-col>
    </v-row>
    <v-row>
      <!-- 特記事項 -->
      <v-col cols="12" class="px-0 py-1" align="left">
        <div class="font-weight-bold label-text" data-cy="entry-step1-col-note">
          【特記事項】（任意）
        </div>
      </v-col>
      <v-col cols="12" class="py-0 pr-0" align="left">
        <h4
          style="white-space: pre-line"
          v-text="noteInitialValue"
          data-cy="entry-step1-data-note"
        />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import mixinCurrentStatuses from '../../../const/user/currentStatuses';
import mixinGenders from '../../../const/user/genders';
import dayjs from 'dayjs';

export default {
  name: 'EntryUserDetailsSp',
  props: ['currentUserData', 'skillSheet', 'project'],
  mixins: [mixinCurrentStatuses, mixinGenders],
  computed: {
    firstNameInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.first_name != null
        ? this.currentUserData.first_name
        : '未登録';
    },

    firstNameKanaInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.first_name_kana != null
        ? this.currentUserData.first_name_kana
        : '未登録';
    },

    lastNameInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.last_name != null
        ? this.currentUserData.last_name
        : '未登録';
    },

    lastNameKanaInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.last_name_kana != null
        ? this.currentUserData.last_name_kana
        : '未登録';
    },

    emailInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.email != null
        ? this.currentUserData.email
        : '未登録';
    },

    telInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.tel != null && this.currentUserData.tel != ''
        ? this.currentUserData.tel
        : '未登録';
    },

    genderInitialValue() {
      if (this.currentUserData != null) {
        const target = this.genders.find(
          element => element.id === this.currentUserData.gender
        );
        return target != null ? target.value : '未登録';
      } else {
        return '未登録';
      }
    },

    birthInitialValue() {
      if (this.currentUserData && this.currentUserData.birth) {
        return dayjs(this.currentUserData.birth).format('YYYY-MM-DD');
      } else {
        return '未登録';
      }
    },

    liveAreaInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.live_area != null &&
        this.currentUserData.live_area != ''
        ? this.currentUserData.live_area
        : '未登録';
    },

    currentStatusInitialValue() {
      if (this.currentUserData != null) {
        const target = this.currentStatuses.find(
          element => element.id === this.currentUserData.current_status
        );
        return target != null ? target.value : '未登録';
      } else {
        return '未登録';
      }
    },

    closestStationInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.closest_station != null &&
        this.currentUserData.closest_station != ''
        ? `${this.currentUserData.closest_station}駅`
        : '未登録';
    },

    nationalityInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.nationality != null &&
        this.currentUserData.nationality != ''
        ? this.currentUserData.nationality
        : '未登録';
    },

    noteInitialValue() {
      if (this.currentUserData == null) return '未登録';
      return this.currentUserData.note != null &&
        this.currentUserData.note != ''
        ? this.currentUserData.note
        : '未登録';
    },

    skillSheetFileNameInitialValue() {
      if (this.skillSheet == null || this.skillSheet.filename == undefined)
        return null;
      const fileName = this.skillSheet.filename;
      const createdAt = dayjs(this.skillSheet.created_at).format(
        '(YYYY-MM-DD HH:mm:ss)'
      );
      return fileName + ' ' + createdAt;
    },
  },
  methods: {
    openAccountDialog() {
      this.$emit('openAccountDialog');
    },

    closeAccountDialog() {
      this.$emit('closeAccountDialog');
    },

    nextPage() {
      this.$emit('nextPage');
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  font-size: 13px;
}
.label-text {
  font-size: 12px;
}
.title-text {
  font-size: 15px;
  display: flex;
  align-items: center;
}
</style>
