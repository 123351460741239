<template>
  <v-card class="mb-4 pl-5 pr-10" flat>
    <v-card-title class="py-4">
      <h2 data-cy="entry-step2-data-name">{{ project.name }}</h2>
    </v-card-title>
    <v-divider class="mb-1" />
    <v-card-text>
      <v-row class="my-1">
        <v-col cols="12" class="pb-0 pt-2">
          <div class="d-flex" data-cy="entry-step2-data-payment">
            <div>
              <v-icon v-text="'mdi-cash-multiple'" />
            </div>
            <div class="pl-2 pt-1">
              <h2 class="accent--text" v-text="costPrice" />
            </div>
            <div class="pt-1">
              <h3>／月</h3>
            </div>
            <div class="pt-1 pl-1">
              <h3 v-text="paymentSpan" />
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="pb-0">
          <h4 class="pt-1 text" data-cy="entry-step2-data-municipality">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-search-outline'" />
            </span>
            {{ municipalityName }}
          </h4>
        </v-col>
        <v-col cols="4" class="pb-0">
          <h4 class="pt-1 text" data-cy="entry-step2-data-station">
            <span>
              <v-icon class="pb-1" v-text="'mdi-map-marker-radius-outline'" />
            </span>
            {{ project.station }}
          </h4>
        </v-col>
        <v-col cols="4" class="pb-0">
          <h4 class="pt-1 text" data-cy="entry-step2-data-work-location">
            <span>
              <v-icon class="pb-1" v-text="'mdi-account-outline'" />
            </span>
            {{ workLocation }}
          </h4>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <v-row class="my-1">
        <v-col cols="2">
          <h3 data-cy="entry-step2-col-description">業務内容</h3>
        </v-col>
        <v-col cols="10" class="pb-0 pt-1">
          <h4
            style="white-space: pre-line"
            v-text="project.description"
            class="pt-2"
            data-cy="entry-step2-data-description"
          />
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <v-row class="mt-4 mb-1">
        <v-col cols="2">
          <h3 data-cy="entry-step2-col-project-skill">開発環境</h3>
        </v-col>
        <v-col cols="10" class="pb-0 pt-1">
          <div
            v-if="
              projectSkills.languages.length === 0 &&
              projectSkills.frameworks.length === 0 &&
              projectSkills.others.length === 0
            "
            v-text="'スキルが登録されていません。'"
          />
          <v-row v-else>
            <v-col
              cols="12"
              class="py-1"
              v-if="projectSkills.languages.length !== 0"
            >
              <h5 class="pb-2">言語</h5>
              <v-chip
                v-for="(skill, index) in projectSkills.languages"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill.name"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-1"
              v-if="projectSkills.frameworks.length !== 0"
            >
              <h5 class="pb-2">フレームワーク</h5>
              <v-chip
                v-for="(skill, index) in projectSkills.frameworks"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill.name"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-1"
              v-if="projectSkills.others.length !== 0"
            >
              <h5 class="pb-2">その他</h5>
              <v-chip
                v-for="(skill, index) in projectSkills.others"
                :key="index"
                label
                small
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill.name"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <v-row class="mt-4 mb-1">
        <v-col cols="2">
          <h3 data-cy="entry-step2-col-require-skill">求められるスキル</h3>
        </v-col>
        <v-col cols="10" class="pb-0 pt-1">
          <h4
            style="white-space: pre-line"
            v-text="project.require_skill"
            class="pt-2"
            data-cy="entry-step2-data-require-skill"
          />
        </v-col>
      </v-row>
      <div v-if="project.note !== '' && project.note">
        <v-divider class="my-3" />
        <v-row class="mt-4 mb-1">
          <v-col cols="2">
            <h3 data-cy="entry-step2-col-note">備考</h3>
          </v-col>
          <v-col cols="10" class="pb-0 pt-1">
            <h4
              style="white-space: pre-line"
              v-text="project.note"
              class="pt-2"
              data-cy="entry-step2-data-note"
            />
          </v-col>
        </v-row>
      </div>
      <v-divider class="my-3" />
      <v-row class="mt-4 pb-4">
        <v-col cols="2" class="pb-0">
          <h3 data-cy="entry-step2-col-other">その他募集要項</h3>
        </v-col>
        <v-col cols="10">
          <v-row>
            <!-- TODO: QA-480 募集終了を復活させる場合はコメントアウトを外す -->
            <!-- <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div
                  class="other-title-text"
                  data-cy="entry-step2-col-other-start"
                  v-text="'【作業開始日】'"
                />
                <div
                  class="font-weight-bold"
                  data-cy="entry-step2-data-other-start"
                  v-text="startWorkAt"
                />
              </div>
            </v-col> -->
            <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div
                  class="other-title-text"
                  data-cy="entry-step2-col-other-people"
                  v-text="'【募集人数】'"
                />
                <div
                  class="font-weight-bold"
                  v-text="`${project.people}人`"
                  data-cy="entry-step2-data-other-people"
                />
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div
                  class="other-title-text"
                  data-cy="entry-step2-col-interview-times"
                  v-text="'【面談回数】'"
                />
                <div
                  class="font-weight-bold"
                  data-cy="entry-step2-interview-times"
                  v-text="project.interview_second_time_format ? '2回' : '1回'"
                />
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <div class="d-flex">
                <div
                  class="other-title-text"
                  data-cy="entry-step2-col-interview-format"
                  v-text="'【面談形式】'"
                />
                <div
                  class="font-weight-bold"
                  data-cy="entry-step2-interview-first-times-format"
                  v-text="interviewFirstTimeFormat"
                />
                <div class="px-3" />
                <div
                  class="font-weight-bold"
                  data-cy="entry-step2-interview-second-times-format"
                  v-text="interviewSecondTimeFormat"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-space-between mt-3 mb-5">
      <v-btn
        outlined
        width="300"
        height="50"
        class="font-weight-bold"
        color="primary"
        @click="backPage"
        data-cy="entry-step2-back-btn"
      >
        戻る
      </v-btn>
      <v-btn
        elevation="0"
        width="300"
        height="50"
        color="primary"
        class="white--text font-weight-bold"
        :loading="entryLoading"
        @click="createEntry"
        data-cy="entry-step2-entry-btn"
      >
        {{ userProjectInfo.entered ? '再応募する' : '応募する' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.other-title-text {
  /* 下記のwidthでその他募集要項のインデントを統一させる */
  width: 110px;
  font-weight: bold;
}

/* 各スキルのホバー表示を消す */
.skills.v-chip {
  pointer-events: none;
}
</style>

<script>
import mixinMunicipalities from '../../../const/project/municipality';
import mixinWorkLocation from '../../../const/project/workLocation';
import dayjs from 'dayjs';

export default {
  name: 'EntryFormPc',
  props: [
    'project',
    'user',
    'skillSheet',
    'entryLoading',
    'projectSkills',
    'userProjectInfo',
  ],
  mixins: [mixinMunicipalities, mixinWorkLocation],
  computed: {
    costPrice() {
      return this.project.cost != null ? `${this.project.cost}万円` : null;
    },

    startWorkAt() {
      if (this.project.start_work_at == null) return null;
      const setDate = dayjs(this.project.start_work_at);
      return setDate.format('YYYY/MM/DD');
    },

    paymentSpan() {
      return this.project != null
        ? `（ 精算幅: ${this.project.work_time_start}-${this.project.work_time_finish}h、 支払いサイト: 30日 ）`
        : null;
    },

    municipalityName() {
      return this.municipalities.find(
        element => element.id === this.project.municipality
      ).value;
    },

    workLocation() {
      return this.workLocations.find(
        element => element.id === this.project.work_location
      ).value;
    },

    interviewFirstTimeFormat() {
      const prefix = this.project.interview_second_time_format ? '1回目：' : '';
      return this.project != null
        ? prefix + this.project.interview_first_time_format
        : '';
    },

    interviewSecondTimeFormat() {
      if (this.project == null) return '';
      return this.project.interview_second_time_format != null
        ? `2回目：${this.project.interview_second_time_format}`
        : '';
    },
  },
  methods: {
    createEntry() {
      if (this.userProjectInfo.entered) {
        this.$emit('reEntry');
      } else {
        this.$emit('createEntry');
      }
    },

    backPage() {
      this.$emit('backPage');
    },
  },
};
</script>
