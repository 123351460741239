<template>
  <v-card elevation="0" outlined>
    <v-card-title>
      <v-row>
        <v-col cols="8" class="pt-1 pb-1">
          <div class="font-weight-bold" style="font-size: 12px">
            適用した条件：
            <span v-if="searchWordsArray.length == 0" class="pl-1">すべて</span>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex justify-end pb-1 pt-1">
          <v-btn
            text
            elevation="0"
            color="primary"
            class="font-weight-bold"
            style="font-size: 12px"
            @click="resetSearch"
          >
            <h5 class="pb-1">条件をクリア</h5>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="searchWordsArray.length != 0">
      <v-row>
        <v-col cols="12" class="py-0">
          <v-chip
            v-for="(item, index) in searchWordsArray"
            :key="index"
            outlined
            label
            small
            class="mr-2 mb-2 words"
            v-text="item"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
/* 各スキルのホバー表示を消す */
.words.v-chip {
  pointer-events: none;
}
</style>

<script>
export default {
  name: 'TermsAppliedSearch',
  props: ['searchWordsArray'],
  methods: {
    resetSearch() {
      this.$emit('resetSearch');
    },
  },
};
</script>
