<template>
  <v-dialog :value="dialog" persistent width="340" @click:outside="close">
    <v-card class="pt-5 px-2" height="280">
      <div class="d-flex justify-center">
        <div>
          <v-img width="160" src="@/assets/img/logo.png" />
        </div>
      </div>
      <v-card-title class="py-7 font-weight-bold justify-center">
        {{ '登録後に利用可能' }}
      </v-card-title>
      <v-card-actions class="pt-0">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center px-0 pb-0">
              <v-btn
                class="font-weight-bold"
                elevation="0"
                color="primary white--text"
                width="80%"
                @click="commit('register')"
              >
                {{ '新規登録へ' }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-center px-0 pb-0">
              <v-btn
                class="font-weight-bold"
                elevation="0"
                outlined
                width="80%"
                color="primary"
                @click="commit('login')"
              >
                {{ 'ログイン' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EncourageLoginModal',
  props: {
    dialog: Boolean,
  },
  methods: {
    commit(type) {
      switch (type) {
        case 'login':
          this.$router.push({ name: 'Login' });
          break;
        case 'register':
          this.$router.push({ name: 'RegisterUser' });
          break;
        default:
          break;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
