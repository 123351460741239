<template>
  <v-card class="pr-12 pl-4" flat>
    <v-card-title class="mb-2 pt-1 pl-0">
      <h4>退会理由確認</h4>
    </v-card-title>
    <v-card-subtitle class="font-weight-bold pl-0">
      以下の内容で退会理由を送信します。お間違えなければ「退会する」ボタンを押してください。
    </v-card-subtitle>
    <v-card-text class="pl-0">
      <v-row class="py-2 pl-4">
        <ul class="font-weight-bold">
          <li
            class="pb-1"
            v-for="(item, index) in selectedWithdrawalReasons"
            :key="index"
            v-text="item.text"
          />
        </ul>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <h4 class="pl-0 pb-1">【その他の理由】</h4>
          <v-card
            flat
            class="px-2 pt-1 ml-2 font-weight-bold"
            v-text="otherReason"
            style="white-space: pre-line; color: rgba(0, 0, 0, 0.6)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="font-weight-bold annotation pl-3" style="font-size: 13px">
            退会手続きを完了しますと、<br />
            これまで入力いただいた内容や応募履歴などは確認できなくなります。
          </div>
          <div
            class="font-weight-bold annotation pl-3 pt-1"
            style="font-size: 13px"
          >
            次回の営業活動の際に再度入力を行いたくない場合は、退会されずに登録情報を残しておくことをおすすめ致します。
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <v-btn
        text
        width="38%"
        height="40px"
        class="font-weight-bold ml-2"
        color="primary"
        @click="backPage"
        v-text="'退会理由の入力へ戻る'"
        style="font-size: 13px"
      />
      <v-btn
        elevation="0"
        width="38%"
        height="40px"
        color="primary"
        class="white--text font-weight-bold mr-2"
        @click="withdrawal"
        style="font-size: 13px"
        :loading="withdrawalLoading"
        >退会する</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'WithdrawalConfirmPc',
  props: ['selectedWithdrawalReasons', 'otherReason', 'withdrawalLoading'],
  methods: {
    backPage() {
      this.$emit('backPage');
    },

    withdrawal() {
      this.$emit('withdrawal');
    },
  },
};
</script>

<style scoped lang="scss">
.annotation::before {
  content: '※';
  display: inline-block;
  margin-left: -1.2em;
  width: 0.8em;
}
</style>
