<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-container>
        <v-row>
          <v-col cols="12" class="pb-1">
            <template v-if="skillSheet === null"> </template>
            <template v-else>
              <h5>アップロード済</h5>
            </template>
            <div class="d-flex">
              <template v-if="skillSheet === null">
                <div class="pt-1" data-cy="skill-sheet-not-uploaded">
                  アップロードされていません。
                </div>
              </template>
              <template v-else>
                <div class="pt-2" data-cy="skill-sheet-uploaded">
                  {{ skillSheetName }}
                  <span>
                    <v-icon
                      data-cy="skill-sheet-download-icon"
                      color="#1976D2"
                      @click="download"
                      v-text="'mdi-download'"
                    />
                    <v-icon
                      data-cy="skill-sheet-delete-icon"
                      color="error"
                      @click="deleteSkillSheet"
                      v-text="'mdi-delete'"
                    />
                  </span>
                </div>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <h5>新規アップロード</h5>
          </v-col>
          <v-col cols="12" class="py-0" align="left">
            <h5 class="font-weight-thin pt-1">
              テンプレートのダウンロードは
              <span
                data-cy="download-template-skill-sheet"
                class="pa-0 primary--text text-decoration-underline"
                :style="`cursor: ${
                  loadingByTemplateDownload ? 'wait' : 'pointer'
                };`"
                @click="templateDownload"
                v-text="'こちら'"
              />
            </h5>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-file-input
              data-cy="file-input"
              v-model="fileInput"
              label="スキルシートをアップロードしてください。"
              @change="change"
              :accept="supportedExtension"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-row class="mt-2">
      <v-col cols="12" align="right">
        <v-btn
          data-cy="upload-file-btn"
          color="primary white--text"
          class="font-weight-bold"
          small
          elevation="1"
          @click="upload"
          width="130"
          :loading="loadingByUpload"
          :disabled="loadingByUpload"
        >
          アップロード
          <template v-slot:loader>
            <v-progress-circular
              :rotate="-90"
              color="primary"
              size="22"
              width="3"
              :value="uploadProgress"
            />
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixinDate from '../../../const/date';
import mixinSkillSheetExtension from '../../../const/file/skillSheet';

export default {
  name: 'SkillSheetMypagePc',
  props: [
    'skillSheet',
    'loadingByUpload',
    'uploadProgress',
    'loadingByTemplateDownload',
  ],
  mixins: [mixinDate, mixinSkillSheetExtension],
  data() {
    return {
      fileData: null,
      fileInput: null,
      btnDisable: true,
    };
  },
  mounted() {
    this.$emit('setSelectPage', 'SkillSheet');
  },
  computed: {
    skillSheetName() {
      let maxLength = 35;
      const fileName =
        this.skillSheet.filename.length >= maxLength
          ? this.skillSheet.filename.substring(0, maxLength) + '...'
          : this.skillSheet.filename;
      return (
        fileName +
        ` ( ${this.formatDatetimeWithSec(this.skillSheet.created_at)} )`
      );
    },
  },
  methods: {
    download() {
      this.$emit('download');
    },

    templateDownload() {
      this.$emit('templateDownload');
    },

    upload() {
      let formData = new FormData();
      if (this.fileData !== null) {
        formData.append(
          'sheet',
          new Blob([this.fileData.data], { type: this.fileData.type }),
          this.fileData.fileName
        );
      } else {
        formData = null;
      }
      if (formData == null) this.btnDisable = true;
      this.$emit('upload', formData, this.btnDisable);
      this.fileData = null;
      this.fileInput = null;
    },

    change(selectedFile) {
      // ファイルを一度選択してから再度選択を試みてキャンセルした際にundefinedになるため。
      if (selectedFile !== null && selectedFile !== undefined) {
        this.fileData = {
          type: selectedFile.type,
          fileName: selectedFile.name,
          data: selectedFile,
        };
        this.btnDisable = false;
        return;
      } else {
        this.fileData = null;
        this.btnDisable = true;
        return;
      }
    },

    deleteSkillSheet() {
      this.$emit('setModal', 'deleteSkillSheet');
    },
  },
};
</script>
