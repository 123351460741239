<template>
  <v-card flat class="pa-10 mx-4 text-center">
    <div class="d-flex justify-center">
      <div>
        <v-img width="160" src="@/assets/img/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center font-weight-bold mb-3">
      パスワードの再設定
    </v-card-title>
    <v-card-text class="px-0">
      <v-form v-model="btnDisable">
        <v-row class="pt-0">
          <v-col cols="12" align="left" class="py-0">
            <h5 class="black--text">メールアドレス</h5>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              color="primary"
              dense
              placeholder="メールアドレス"
              hide-details="auto"
              outlined
              :value="emailInitialValue"
              @input="updateEmail"
              :rules="rules.email"
              data-cy="reset-password-form-email-form"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-4 pa-0">
      <v-row class="px-1">
        <v-col cols="12" class="px-2" align="center">
          <v-btn
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            block
            @click="resetPassword"
            data-cy="reset-password-form-submit-btn"
            :loading="loading"
          >
            <v-icon
              class="mail-icon-position"
              dark
              size="25"
              v-text="'mdi-email-outline'"
            />
            再設定メールを送信
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions class="mt-7 pa-0 justify-center">
      <v-btn
        text
        class="font-weight-bold"
        color="primary"
        @click="$router.push({ path: '/login' })"
        v-text="'ログインへ戻る'"
        data-cy="reset-password-form-first-to-login-btn"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'ResetPasswordFormSp',
  props: ['value', 'loading'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.userEmail(value)],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },
  },
  methods: {
    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },

    resetPassword() {
      this.$emit('resetPassword', !this.btnDisable);
    },

    routerPush(path) {
      const url = path;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
