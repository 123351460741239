<template>
  <v-card-text class="pt-6">
    <v-row>
      <v-col cols="9">
        <h2 v-text="'スキル情報'" data-cy="entry-step1-section-title" />
      </v-col>
      <v-col cols="3" class="pt-1" align="right">
        <v-btn depressed text @click="setSkillInfoDialog(true)">
          <span>
            <v-icon color="primary" v-text="'mdi-pencil'" />
          </span>
          <div class="primary--text" v-text="'編集'" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-col cols="12" class="py-0">
          <div class="pb-2">
            <h5 data-cy="entry-step1-col-skillsheet">
              【スキルシート】
              <span style="color: red">※</span>
            </h5>
          </div>
        </v-col>
        <v-col cols="12" class="pt-0 pl-5">
          <template
            v-if="skillSheet == null || skillSheet.filename == undefined"
          >
            <div class="font-weight-bold">アップロードされていません。</div>
          </template>
          <template v-else>
            <h3 v-text="skillSheetFileNameInitialValue" />
          </template>
        </v-col>
      </v-col>
    </v-row>
    <Loading v-if="loadingByUserSkill" />
    <div v-else>
      <v-row v-if="!skillNotExists" class="px-5">
        <h5 class="pl-1">【登録スキル】</h5>
        <v-col cols="12">
          <v-row>
            <v-col
              v-for="(item, index) in skillTypes"
              :key="index"
              cols="12"
              :class="userSkills[item.type].length !== 0 ? 'py-1' : 'pa-0'"
            >
              <div v-if="userSkills[item.type].length !== 0">
                <h5 class="pb-2">{{ item.label }}</h5>
                <v-chip
                  v-for="(skill, index) in userSkills[item.type]"
                  :key="index"
                  label
                  color="rgba(0, 0, 0, 0.6)"
                  class="mr-2 mb-2 font-weight-bold"
                  outlined
                >
                  {{ skillChipLabel(skill) }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="12" class="py-1" v-if="currentUserData.other_skills">
              <h5 class="pb-2">その他(自由入力)</h5>
              <v-chip
                v-for="(skill, index) in otherSkills"
                :key="index"
                outlined
                label
                class="mr-2 mb-2 font-weight-bold"
                color="rgba(0, 0, 0, 0.6)"
              >
                <div v-text="skill" />
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card-text>
</template>

<script>
import mixinDate from '../../../const/date';
import mixinSkillExperienceYears from '../../../const/user/skillExperienceYears';
import Loading from '../../common/Loading.vue';
export default {
  name: 'EntryUserSkillsPc',
  props: ['currentUserData', 'skillSheet', 'userSkills', 'loadingByUserSkill'],
  mixins: [mixinDate, mixinSkillExperienceYears],
  components: {
    Loading,
  },
  data() {
    return {
      skillTypes: [
        {
          label: '言語',
          type: 'languages',
        },
        {
          label: 'フレームワーク',
          type: 'frameworks',
        },
        {
          label: 'その他',
          type: 'others',
        },
      ],
    };
  },
  computed: {
    skillSheetFileNameInitialValue() {
      if (this.skillSheet) {
        return (
          this.skillSheet.filename +
          ` ( ${this.formatDatetimeWithSec(this.skillSheet.created_at)} )`
        );
      } else {
        return null;
      }
    },

    skillNotExists() {
      return (
        this.userSkills?.languages.length === 0 &&
        this.userSkills?.frameworks.length === 0 &&
        this.userSkills?.others.length === 0 &&
        !this.currentUserData?.other_skills
      );
    },

    otherSkills() {
      return this.currentUserData.other_skills
        ? this.currentUserData.other_skills
        : [];
    },
  },
  methods: {
    setSkillInfoDialog(value) {
      this.$emit('setSkillInfoDialog', value);
    },

    experienceYear(value) {
      const val = this.skillExperienceYears.find(target => {
        return target.id == value && value !== 0;
      });
      return val ? val.value : '';
    },

    skillChipLabel(skill) {
      return skill.name + ' ' + this.experienceYear(skill.experience_year);
    },
  },
};
</script>
