<template>
  <v-card outlined elevation="0" max-width="700" class="pt-5">
    <v-container>
      <div class="d-flex justify-center">
        <div>
          <v-img width="140" src="@/assets/img/logo.png" />
        </div>
      </div>
      <v-card-title
        data-cy="register-complete-text"
        class="justify-center font-weight-bold mt-3"
        style="font-size: 20px"
      >
        登録手続きメールの送信完了
      </v-card-title>
      <v-card-text class="mt-2 mb-2">
        <div>
          入力されたメールアドレスに「新規会員登録のお知らせ」のメールを送信しました。<br />
          現時点では会員登録手続きは完了しておりません。<br />
          1時間以内にメール内に記載されているURLにアクセスしていただくことで登録が完了となります。<br />
        </div>
        <div class="pt-3">
          しばらく経ってもメールが届かない場合は、<br />
          お手数ですが、メールアドレスをご確認の上、最初から新規登録をお試しください。
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          class="font-weight-bold"
          color="primary"
          elevation="0"
          text
          @click="routerPush('/login')"
          v-text="'ログインへ'"
        />
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'RegisterCompletePc',
  methods: {
    routerPush(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>
