<template>
  <v-responsive>
    <v-row class="mb-2">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="pb-1">
            <h6>フリーワード検索</h6>
            <v-text-field
              outlined
              dense
              hide-details="auto"
              placeholder="Ruby リモート"
              append-icon="mdi-magnify"
              color="primary"
              @click:append="search"
              :value="outputFreeWord"
              @input="inputFreeWord"
            />
          </v-col>
          <v-col cols="6" class="pb-0 pt-2" align="left">
            <v-btn
              class="px-0 font-weight-bold"
              text
              color="primary"
              small
              style="font-size: 12px"
              @click="setDialog(true)"
            >
              <span>
                <v-icon small v-text="'mdi-filter'" />
              </span>
              条件を絞り込む
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-0" align="right">
            <v-select
              dens
              hide-details="auto"
              class="pa-0 select"
              color="primary"
              item-color="primary"
              :items="sortSelectItems"
              :value="sortInitialValue"
              item-text="value"
              item-value="id"
              menu-props="auto"
              @change="changeSort"
            />
          </v-col>
          <ProjectsSearchDialog
            :searchDialog="searchDialog"
            :searchWordsArray="searchWordsArray"
            :outputFreeWord="outputFreeWord"
            :selectedMunicipalities="selectedMunicipalities"
            :btnDisable="btnDisable"
            :selectedWorkLocations="selectedWorkLocations"
            :skills="skills"
            :selectedSkills="selectedSkills"
            :minCostQuery="minCostQuery"
            :maxCostQuery="maxCostQuery"
            @search="search"
            @setDialog="setDialog"
            @changeBtnDisable="changeBtnDisable"
            @updateMinCostQuery="updateMinCostQuery"
            @updateMaxCostQuery="updateMaxCostQuery"
            @inputFreeWord="inputFreeWord"
            @changeWorkLocations="changeWorkLocations"
            @changeMunicipalities="changeMunicipalities"
            @changeSkills="changeSkills"
            @resetSearch="resetSearch"
          />
        </v-row>
        <div class="mt-4">
          <TermsAppliedSearch
            :searchWordsArray="searchWordsArray"
            @resetSearch="resetSearch"
          />
        </div>
      </v-col>
      <v-col cols="8" class="pr-0 pl-4 py-0">
        <h2 class="pt-2">案件検索</h2>
      </v-col>
      <v-col cols="12" class="py-0" align="right">
        <h6
          class="pl-3 mb-2 pt-2"
          style="white-space: pre-line"
          v-text="displayCount"
        />
      </v-col>
    </v-row>
  </v-responsive>
</template>

<style scoped lang="scss">
.select {
  width: 115px;
  font-size: 13px;
}
</style>

<script>
import ProjectsSearchDialog from './ProjectsSearchDialog.vue';
import TermsAppliedSearch from './TermsAppliedSearch.vue';

export default {
  name: 'ProjectsHeaderSp',
  props: [
    'displayCount',
    'searchWordsArray',
    'sortSelectItems',
    'sortInitialValue',
    'outputFreeWord',
    'minCostQuery',
    'maxCostQuery',
    'selectedMunicipalities',
    'btnDisable',
    'selectedWorkLocations',
    'skills',
    'selectedSkills',
  ],
  components: {
    ProjectsSearchDialog,
    TermsAppliedSearch,
  },
  data() {
    return {
      searchDialog: false,
    };
  },
  methods: {
    resetSearch() {
      this.searchDialog = false;
      this.$emit('resetSearch');
    },

    changeSort(e) {
      this.$emit('changeSort', e);
    },

    updateMaxCostQuery(e) {
      this.$emit('updateMaxCostQuery', e);
    },

    updateMinCostQuery(e) {
      this.$emit('updateMinCostQuery', e);
    },

    search() {
      this.setDialog(false);
      this.$emit('search');
    },

    setDialog(type) {
      this.searchDialog = type;
    },

    changeBtnDisable(boolean) {
      this.$emit('changeBtnDisable', boolean);
    },

    inputFreeWord(e) {
      this.$emit('inputFreeWord', e);
    },

    changeWorkLocations(value) {
      this.$emit('changeWorkLocations', value);
    },

    changeMunicipalities(value) {
      this.$emit('changeMunicipalities', value);
    },

    changeSkills(value) {
      this.$emit('changeSkills', value);
    },
  },
};
</script>
